import { Button, Card, Col, Drawer, Row, Form, Input, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react'
import GradeService from '../../services/GradeService';
import { DeleteOutlined } from '@ant-design/icons'
import TextArea from 'antd/es/input/TextArea';
import { openNotification } from '../../utils';

interface GradesItemCardProps {
    grade: any;
    getTrigger: any;
}

const { Item } = Form;

const GradesItemCard: React.FC<GradesItemCardProps> = ({ grade, getTrigger }) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (open) {
            getService();
        }
    }, [open])

    const getService = () => {
        setLoading(true)
        GradeService.get(grade?.id)
            .then((response: any) => {
                const data = response.data
                form.setFieldsValue({
                    name: data?.name,
                    description: data?.description,
                });
                setLoading(false)
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const update = (values: any) => {
        let postObj = {
            "name": values.name,
            "description": values.description,
        }

        GradeService.update(grade?.id, postObj)
            .then((response: any) => {
                getTrigger();
                openNotification('success', 'Başarılı', 'Sınıf Başarıyla Kaydedildi.')
            }).catch((e: Error) => {
                openNotification('error', 'Başarısız', 'Sınıf Kaydedilemedi.')
            });
    }

    const removeGrade = (values: any) => {
        GradeService.remove(grade?.id)
            .then((response: any) => {
                getTrigger();
                openNotification('success', 'Başarılı', 'Sınıf Başarıyla Silindi')

            }).catch((e: Error) => {
                openNotification('error', 'Başarısız', 'Sınıf Silinemedi.')
            });
    }

    return (
        <>
            <Card>
                <Row align={'middle'} justify={'space-around'}>
                    <Col>
                        <p><b>ID:</b></p>
                        {grade.id}
                    </Col>
                    <Col>
                        <p><b> Sınıf:</b></p>
                        {grade.name}
                    </Col>
                    <Col>
                        <p><b>Açıklama:</b></p>
                        {grade.description}
                    </Col>
                    <Col>
                        <Button onClick={showDrawer}>
                            Detay
                        </Button>
                    </Col>
                    <Col>
                        <Popconfirm
                            title="Sınıf silinsin mi?"
                            onConfirm={() => removeGrade(grade.id)}
                            okText="Evet"
                            cancelText="Hayır"
                        >
                            <DeleteOutlined
                                className='cursor-pointer' />
                        </Popconfirm>
                    </Col>
                </Row>
            </Card>
            <Drawer title="Sınıf Detay" placement="right" onClose={onClose} open={open} extra={<b>#{grade?.id}</b>}>
                <Card loading={loading}>
                    <Form form={form} onFinish={update} layout="vertical">
                        <Item
                            name="name"
                            label="Sınıf"
                            rules={[
                                {
                                    required: true,
                                    message: 'Sınıf adı zorunlu',
                                }]}
                        >
                            <Input />
                        </Item>
                        <Item
                            name="description"
                            label="Açıklama"
                        >
                            <TextArea />
                        </Item>
                        <Item>
                            <Row justify={'end'}>
                                <Button className='btn btn-hover-dark bg-cloud font-color-onyx btn-text mr-16' onClick={onClose}>
                                    Vazgeç
                                </Button>
                                <Button  className='btn btn-hover-dark bg-slate-blue font-color-cloud btn-text' htmlType="submit">
                                    Kaydet
                                </Button>
                            </Row>
                        </Item>
                    </Form>
                </Card>
            </Drawer>
        </>
    )
}

export default GradesItemCard