import { openNotification } from "../utils";
import DocumentService from "./DocumentService";
import ExplanationService from "./ExplanationService";
import GradeService from "./GradeService";
import LessonService from "./LessonService"; // Bu import eksikti
import QuestionService from "./QuestionService";
import QuizService from "./QuizService";
import SubjectService from "./SubjectService";
import UnitService from "./UnitService";
import VideoService from "./VideoService";

interface Service {
    remove: (id: number) => Promise<any>;
    removeToGrade?: (lessonId: number, gradeId: number) => Promise<any>;
}

const serviceMap: Record<string, { service: Service, successMessage: string, errorMessage: string }> = {
    grade: {
        service: GradeService,
        successMessage: 'Sınıf Başarıyla Silindi',
        errorMessage: 'İçinde Ders Olan Sınıflar Silinemez.'
    },
    quiz: {
        service: QuizService,
        successMessage: 'Quiz Başarıyla Silindi',
        errorMessage: 'Quiz Silinemedi.'
    },
    subject: {
        service: SubjectService,
        successMessage: 'Konu Başarıyla Silindi',
        errorMessage: 'Konu Silinemedi.'
    },
    unit: {
        service: UnitService,
        successMessage: 'Ünite Başarıyla Silindi',
        errorMessage: 'İçinde Konu Olan Ünite Silinemez.'
    },
    video: {
        service: VideoService,
        successMessage: 'Video Başarıyla Silindi',
        errorMessage: 'Video Silinemedi.'
    },
    explanation: {
        service: ExplanationService,
        successMessage: 'Konu Anlatımı Başarıyla Silindi',
        errorMessage: 'Konu Anlatımı Silinemedi.'
    },
    document: {
        service: DocumentService,
        successMessage: 'Doküman Başarıyla Silindi',
        errorMessage: 'Doküman Silinemedi.'
    },
    question: {
        service: QuestionService,
        successMessage: 'Soru Başarıyla Silindi',
        errorMessage: 'Soru Silinemedi.'
    },
    lesson: {
        service: LessonService,
        successMessage: 'Ders Başarıyla Silindi',
        errorMessage: 'İçinde Ünite Olan Ders Silinemez.'
    }
};

export const removeItem = (params: { id: number; data: any; action: string; type: string; gradeId?: number }): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
        const { id, type, gradeId } = params;
        const serviceData = serviceMap[type];

        if (!serviceData) {
            console.error(`Unknown type: ${type}`);
            reject(`Unknown type: ${type}`);
            return;
        }

        const { service, successMessage, errorMessage } = serviceData;
        let removePromise;

        if (gradeId !== undefined && service.removeToGrade) {
            removePromise = service.removeToGrade(id, gradeId);
        } else {
            removePromise = service.remove(id);
        }

        removePromise
            .then((response: any) => {
                openNotification('success', 'Başarılı', successMessage);
                resolve();
            })
            .catch((e: Error) => {
                openNotification('error', 'Başarısız', errorMessage);
                reject(e);
            });
    });
};

