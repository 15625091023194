import { Col, Row, Image } from 'antd';
import { Outlet } from 'react-router-dom';
import AuthLayoutImg from './assets/AuthLayout1.png';

const AuthLayout = () => {
  return (
    <>
      <Row className='auth-layout'>
        <Col md={12} xs={24}>
          <Outlet />
        </Col>
        <Col className='right-column' md={12} xs={24}>
        </Col>
      </Row>
    </>
  )
}

export default AuthLayout