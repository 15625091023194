import { Breadcrumb, Button, Card, Col, Popconfirm, Row, TableColumnsType } from 'antd';
import React, { useEffect, useState } from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons';
import GradeCreate from './SubjetDetail/Create/GradeCreate';
import GradeUpdate from './SubjetDetail/Update/GradeUpdate';
import LessonCreate from './SubjetDetail/Create/LessonCreate';
import { removeItem } from '../services/RemoveService';
import GradeService from '../services/GradeService';
import SortableTable, { DragHandle } from '../components/Common/SortableTable';
import { formatDate } from '../utils';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { DeleteOutlined } from '@ant-design/icons'

interface GradeDetailProps {
    grades: any[];
    getTrigger: any;
}

interface DataType {
    id: number;
    title: string;
    sortOrder: number;
}

const GradeList: React.FC<GradeDetailProps> = ({ grades, getTrigger }) => {
    const [actionNode, setActionNode] = useState<any>();
    const [dataSource, setDataSource] = useState<DataType[]>([]);
    const [loading, setLoading] = useState(false);

    const columns: TableColumnsType<DataType> = [
        { key: 'sort', align: 'center', width: 80, render: () => <DragHandle /> },
        { title: 'Sınıf', dataIndex: 'name', width: 200 },
        {
            title: 'Kayıt Tarihi',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 80,
            render: (createdAt) => (
                <>
                    {formatDate(createdAt)}
                </>
            ),
        },
    ];

    useEffect(() => {
        if (actionNode?.action === 'delete') {
            removeItem(actionNode).then(() => {
                getTrigger();
            })
                .catch((error) => {
                    console.error('Silme işlemi başarısız:', error);
                });
        }
    }, [actionNode])

    useEffect(() => {
        console.log('grades', grades)
        setDataSource(grades);
    }, [grades])



    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {
            setDataSource((prevState) => {
                const activeIndex = prevState.findIndex((record) => record.id === active?.id);
                const overIndex = prevState.findIndex((record) => record.id === over?.id);
                const newState = arrayMove(prevState, activeIndex, overIndex);

                const updatedSortOrder = newState.map((item, index) => ({
                    id: item.id,
                    sortOrder: index + 1
                }));

                updateSortOrders(updatedSortOrder);

                return newState;
            });
        }
    };

    const updateSortOrders = (data: any) => {
        GradeService.updateSortOrders(data)
            .then((response: any) => {
                getTrigger();
            })
            .catch((e: Error) => {
                console.log(e);
                setLoading(false);
            });
    };

    return (
        <Card
            title="Sınıf Listesi"
            bodyStyle={{ paddingTop: '12px' }}
        >
            <SortableTable<DataType>
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                onDragEnd={onDragEnd}
                defaultSortField="sortOrder"
            />
        </Card>
    )
}

export default GradeList