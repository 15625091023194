import { Button, Col, Divider, Drawer, Form, Input, Modal, Row, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useState } from 'react'
import UnitService from '../../../services/UnitService';
import { openNotification } from '../../../utils';
import LessonService from '../../../services/LessonService';

interface UnitCreateProps {
    getTrigger: any;
    lessonId: number;
    gradeId: any;
}

const { Item } = Form;

const UnitCreate: React.FC<UnitCreateProps> = ({ getTrigger, lessonId, gradeId }) => {
    const [lessons, setLessons] = useState<any>([]);
    const [form] = Form.useForm();
    const [showDrawer, setShowDrawer] = useState(false);

    useEffect(() => {
        if (showDrawer) {
            getLessons()
        }
    }, [showDrawer]);

    const handleUnitCreateCancel = () => {
        setShowDrawer(false);
    };

    useEffect(() => {
        form.resetFields();
    }, [showDrawer])

    const getLessons = () => {
        LessonService.getAll()
            .then((response: any) => {
                const data = response.data
                const options = data.map((lesson: { id: number; name: string; }) => ({
                    label: lesson.name, // Kullanıcıya gösterilecek metin
                    value: lesson.id, // Form üzerinden gönderilecek değer
                }));
                setLessons(options);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const create = (values: any) => {
        let postObj = {
            "name": values.name,
            "description": values.description,
            "lessonId": lessonId,
            "gradeId": gradeId,
        };

        console.log('postObj', postObj)

        UnitService.create(postObj)
            .then((response: any) => {
                openNotification('success', 'Başarılı', 'Ünite Başarıyla Oluşturuldu.')
                setShowDrawer(false)
                getTrigger();
            }).catch((e: Error) => {
                openNotification('error', 'Başarısız', 'Ünite oluşturulamadı.')
                console.log(e)
            });
    }

    return (
        <>
            <Button onClick={() => setShowDrawer(true)}>
                Ünite Ekle
            </Button>
            <Drawer title="Ünite Ekle" placement="right" onClose={() => setShowDrawer(false)} open={showDrawer}>
                <Form form={form} onFinish={create} layout="vertical">
                    <Item
                        name="name"
                        label="Ünite"
                        rules={[
                            {
                                required: true,
                                message: 'Ünite adı zorunlu',
                            }]}
                    >
                        <Input />
                    </Item>
                    <Item
                        name="description"
                        label="Açıklama"
                    >
                        <TextArea />
                    </Item>
                    <Divider></Divider>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Button onClick={handleUnitCreateCancel} className='w-full height-40'>
                                İptal
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Item>
                                <Button htmlType="submit" className='w-full height-40 btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text'>
                                    Kaydet
                                </Button>
                            </Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    )
}

export default UnitCreate