import { Button, Card, Col, Drawer, Row, Form, Input, Popconfirm, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import LessonService from '../../services/LessonService';
import { DeleteOutlined } from '@ant-design/icons'
import TextArea from 'antd/es/input/TextArea';
import { openNotification } from '../../utils';

interface LessonsItemCardProps {
    lesson: any;
    getTrigger: any;
    grades: any;
}

const { Item } = Form;

const LessonsItemCard: React.FC<LessonsItemCardProps> = ({ lesson, getTrigger, grades }) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (open) {
            getService();
        }
    }, [open])

    const getService = () => {
        setLoading(true)
        LessonService.get(lesson?.id)
            .then((response: any) => {
                const data = response.data
                form.setFieldsValue({
                    name: data?.name,
                    description: data?.description,
                    gradeId: data?.gradeId,
                });
                setLoading(false)
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const update = (values: any) => {
        let postObj = {
            "name": values.name,
            "description": values.description,
            "gradeIds": values.gradeId,
        }

        LessonService.update(lesson?.id, postObj)
            .then((response: any) => {
                getTrigger();
                openNotification('success', 'Başarılı', 'Ders Başarıyla Kaydedildi.')
            }).catch((e: Error) => {
                openNotification('error', 'Başarısız', 'Ders kaydedilemedi.')
            });
    }

    const removeLesson = (values: any) => {
        LessonService.remove(lesson?.id)
            .then((response: any) => {
                getTrigger();
                openNotification('success', 'Başarılı', 'Ders Başarıyla Silindi.')
            }).catch((e: Error) => {
                openNotification('error', 'Başarısız', 'Ders silinemedi.')
            });
    }

    const findGradeName = (gradeId: number) => {
        const grade = grades.find((grade: any) => grade.value === gradeId);
        return grade ? grade.label : ' ';
    }

    return (
        <>
            <Card>
                <Row align={'middle'} justify={'space-around'}>
                    <Col>
                        <p><b>ID:</b></p>
                        {lesson.id}
                    </Col>
                    <Col>
                        <p><b> Ders:</b></p>
                        {lesson.name}
                    </Col>
                    <Col>
                        <p><b>Açıklama:</b></p>
                        {lesson.description}
                    </Col>
                    <Col>
                        <p><b>Sınıf:</b></p>
                        {findGradeName(lesson.gradeId)}
                    </Col>
                    <Col>
                        <Button onClick={showDrawer}>
                            Detay
                        </Button>
                    </Col>
                    <Col>
                        <Popconfirm
                            title="Ders silinsin mi?"
                            onConfirm={() => removeLesson(lesson.id)}
                            okText="Evet"
                            cancelText="Hayır"
                        >
                            <DeleteOutlined
                                className='cursor-pointer' />
                        </Popconfirm>
                    </Col>
                </Row>
            </Card>
            <Drawer title="Ders Detay" placement="right" onClose={onClose} open={open} extra={<b>#{lesson?.id}</b>}>
                <Card loading={loading}>
                    <Form form={form} onFinish={update} layout="vertical">
                        <Item
                            name="name"
                            label="Ders"
                            rules={[
                                {
                                    required: true,
                                    message: 'Ders adı zorunlu',
                                }]}
                        >
                            <Input />
                        </Item>
                        <Item
                            name="description"
                            label="Açıklama"
                        >
                            <TextArea />
                        </Item>
                        <Item
                            name="gradeId"
                            label="Sınıf"
                            rules={[
                                {
                                  required: true,
                                  message: 'Lütfen bir Sınıf seçin', // Kullanıcı alanı boş bıraktığında gösterilecek mesaj
                                },
                              ]}
                        >
                            <Select options={grades} />
                        </Item>
                        <Item>
                            <Row justify={'end'}>
                                <Button className='btn btn-hover-dark bg-cloud font-color-onyx btn-text mr-16' onClick={onClose}>
                                    Vazgeç
                                </Button>
                                <Button  className='btn btn-hover-dark bg-slate-blue font-color-cloud btn-text' htmlType="submit">
                                    Kaydet
                                </Button>
                            </Row>
                        </Item>
                    </Form>
                </Card>
            </Drawer>
        </>
    )
}

export default LessonsItemCard