import { http } from "../http-common";
import { IPermissionResponseData } from "../types/Permission";
import { isAuthorized, openNotification } from "../utils";

const getAll = () => {
  return http.get<Array<IPermissionResponseData>>("/permissions");
};

const PermissionService = {
  getAll,
};

export default PermissionService;