import { http } from "../http-common";
import { ILessonRequest, ILessonResponse } from "../types/Lessons";
import { isAuthorized, openNotification } from "../utils";


const getAll = () => {
    if (isAuthorized('lesson.list')) {
        return http.get<ILessonResponse>(`/lesson`);
    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const create = (data: ILessonRequest) => {
    if (isAuthorized('lesson.create')) {
        return http.post<ILessonRequest>(`/lesson`, data);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const addToGrade = (lessonId: number, gradeId: number) => {
    return http.post<any>(`/lesson/${lessonId}/grade/${gradeId}`);
};

const removeToGrade = (lessonId: number, gradeId: number) => {
    return http.delete<any>(`/lesson/${lessonId}/grade/${gradeId}`);
};

const get = (id: number) => {
    if (isAuthorized('lesson.show')) {
        return http.get<ILessonResponse>(`/lesson/${id}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const update = (id: number, data: ILessonRequest) => {
    if (isAuthorized('lesson.update')) {
        return http.patch<ILessonRequest>(`/lesson/${id}`, data);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const remove = (id: number) => {
    if (isAuthorized('lesson.delete')) {
        return http.delete<ILessonRequest>(`/lesson/${id}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const LessonService = {
    create,
    getAll,
    get,
    update,
    remove,
    addToGrade,
    removeToGrade
}

export default LessonService;