import { Button, Col, Drawer, Form, Input, Row, Switch, UploadFile } from 'antd';
import { openNotification } from '../../../utils';
import { useState } from 'react';
import QuizService from '../../../services/QuizService';

const { Item } = Form;

interface QuizCreateProps {
  getData: any;
  subjectId: number;
  lessonId: number;
}

const QuizCreate: React.FC<QuizCreateProps> = ({ subjectId,lessonId, getData }) => {
  const [form] = Form.useForm();
  const [isFile, setIsFile] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [loading, setLoading] = useState(false);

  const create = (values: any) => {
    setLoading(true);
    let postObj = {
      title: values.title,
      description: values.description,
      subjectId: subjectId,
      questionLessonPairs: [{questionId:133, lessonId:lessonId}],
      duration: 50
    };

    QuizService.create(postObj)
      .then((response: any) => {
        getData();
        openNotification('success', 'Başarılı', 'Sınav Oluşturuldu.');
        setShowDrawer(false);
        form.resetFields();
      })
      .catch((e: Error) => {
        openNotification('error', 'Başarısız', 'Sınav oluşturulamadı.');
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button className='btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text' onClick={() => setShowDrawer(true)}>
        Sınav Ekle
      </Button>
      <Drawer title="Yeni Sınav Oluştur" placement="right" onClose={() => setShowDrawer(false)} open={showDrawer} >
        <Row justify={'center'}>
          <Col xl={24} xs={24}>
            <Form form={form} onFinish={create} layout="vertical">
              <Item
                name="title"
                label="Başlık"
                rules={[
                  {
                    required: true,
                    message: 'Konu Anlatımı adı zorunlu',
                  }]}
              >
                <Input />
              </Item>
              <Item
                name="description"
                label="Açıklama"
                rules={[
                  {
                    required: true,
                    message: 'Açıklama adı zorunlu',
                  }]}
              >
                <Input />
              </Item>
              <Item>
                <Row justify={'end'}>
                  <Button className='btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text' htmlType="submit" loading={loading}>
                    Kaydet
                  </Button>
                </Row>
              </Item>
            </Form>
          </Col>
        </Row>
      </Drawer>
    </>
  )
}

export default QuizCreate;
