import { useEffect, useState } from 'react'
import StudentService from '../services/StudentService';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Descriptions, Row } from 'antd';
import { formatDate } from '../utils';
import type { DescriptionsProps } from 'antd';
import TimelineActivity from '../components/StudentDetail/TimelineActivity';
import VideoHistory from '../components/StudentDetail/VideoHistory';
import CountCard from '../components/StudentDetail/CountCard';

const StudentDetail = () => {
    const { id }: any = useParams();
    let navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [student, setStudent] = useState<any>();

    const [liveLessonCount, setLiveLessonCount] = useState<any>(0)
    const [videoWatchedCount, setVideoWatchedCount] = useState<any>(0)
    const [questionStatistics, setQuestionStatistics] = useState<any>({ answeredQuestionCount: 0, questionCount: 0 })
    const [assignmentStatistics, setAssignmentStatistics] = useState<any>({ assignmentIsDoneCount: 0, assignmentCount: 0 })

    useEffect(() => {
        getStudent();
        getAssignmentStatistics();
        getStudentQuestionStatistics();
        getVideoHistoryStatistics();
    }, [])

    const getAssignmentStatistics = () => {
        StudentService.getAssignmentStatistics(id)
            .then((response: any) => {
                const data = response.data
                setAssignmentStatistics(data)
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const getStudentQuestionStatistics = () => {
        StudentService.getStudentQuestionStatistics(id)
            .then((response: any) => {
                const data = response.data
                setQuestionStatistics(data)
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const getVideoHistoryStatistics = () => {
        StudentService.getVideoHistoryStatistics(id)
            .then((response: any) => {
                const data = response.data
                setVideoWatchedCount(data.watchedCount)
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const items: DescriptionsProps['items'] = [
        {
            label: 'E-posta',
            children: student?.email,
        },
        {
            label: 'Telefon',
            children: student?.phoneNumber,
            span: { xl: 6, xxl: 6 },
        },
        {
            label: 'Güncelleme Tarihi',
            children: formatDate(student?.updatedAt),
        },
        {
            label: 'Kayıt Tarihi',
            children: formatDate(student?.createdAt),
            span: { xl: 12, xxl: 12 },
        },
    ];

    const getStudent = () => {
        setLoading(true);
        StudentService.get(id)
            .then((response: any) => {
                const data = response.data;
                setStudent(data);
                setLoading(false);
            })
            .catch((e: Error) => {
                console.log(e);
                setLoading(false);
            });
    };

    return (
        <div>
            <Card title={`${student?.firstName} ${student?.lastName}`} extra={<Button onClick={() => navigate(-1)} className=''>
                Geri
            </Button>} className='border-radius-md'>
                <Descriptions
                    bordered
                    column={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4 }}
                    items={items}
                />
                <Row gutter={[24, 24]} className='mt-24' justify={"space-between"}>
                    {/* <Col span={6}>
                        <CountCard text={'Canlı Ders'} count={liveLessonCount} iconType={'lesson'} />
                    </Col> */}
                    <Col span={8}>
                        <CountCard text={'Sorular'} count={`${questionStatistics?.answeredQuestionCount} / ${questionStatistics?.questionCount}`} iconType={'question'} />
                    </Col>
                    <Col span={8}>
                        <CountCard text={'Ödev'} count={`${assignmentStatistics?.assignmentIsDoneCount} / ${assignmentStatistics?.assignmentCount}`} iconType={'lesson'} />
                    </Col>
                    <Col span={8}>
                        <CountCard text={'Video İzleme Sayısı'} count={videoWatchedCount} iconType={'question'} />
                    </Col>
                </Row>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <VideoHistory></VideoHistory>
                    </Col>
                    <Col span={12}>
                        <TimelineActivity></TimelineActivity>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export default StudentDetail