import { Card, Col, Progress, Row } from 'antd'
import React from 'react'

const PopularCourses = () => {
    return (
            <Card className='border-radius-md h-full' >
                {/* <h3 className='fw-700 fs-24 mt-0'>Popüler Kurslar</h3>
                <Row>
                    <Col span={8}>
                        <p className='fs-12 font-color-gray'>Kurs Adı</p>
                    </Col>
                    <Col span={8}>
                        <Row justify={'center'}>
                            <p className='fs-12 font-color-gray'>Öğrenci Sayısı</p>
                        </Row>
                    </Col>
                    <Col span={8}>
                        <Row justify={'center'}>
                            <p className='fs-12 font-color-gray'>Tamamlanma Oranı</p>
                        </Row>
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={8}>
                        <p className='fs-12 fw-700 font-color-dark-blue'>AYT Matematik</p>
                    </Col>
                    <Col span={8}>
                        <Row justify={'center'}>
                            <p className='fs-14 font-color-light-gray fw-500'>87</p>
                        </Row>
                    </Col>
                    <Col className='px-24' span={8}>
                        <Progress className='progress-primary' percent={80} showInfo={false} />
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={8}>
                        <p className='fs-12 fw-700 font-color-dark-blue'>AYT Türkçe</p>
                    </Col>
                    <Col span={8}>
                        <Row justify={'center'}>
                            <p className='fs-14 font-color-light-gray fw-500'>76</p>
                        </Row>
                    </Col>
                    <Col className='px-24' span={8}>
                        <Progress className='progress-primary' percent={75} showInfo={false} />
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={8}>
                        <p className='fs-12 fw-700 font-color-dark-blue'>AYT Biyoloji</p>
                    </Col>
                    <Col span={8}>
                        <Row justify={'center'}>
                            <p className='fs-14 font-color-light-gray fw-500'>65</p>
                        </Row>
                    </Col>
                    <Col className='px-24' span={8}>
                        <Progress className='progress-primary' percent={66} showInfo={false} />
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={8}>
                        <p className='fs-12 fw-700 font-color-dark-blue'>12.Sınıf Matematik</p>
                    </Col>
                    <Col span={8}>
                        <Row justify={'center'}>
                            <p className='fs-14 font-color-light-gray fw-500'>62</p>
                        </Row>
                    </Col>
                    <Col className='px-24' span={8}>
                        <Progress className='progress-primary' percent={62} showInfo={false} />
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={8}>
                        <p className='fs-12 fw-700 font-color-dark-blue'>12.Sınıf Türkçe</p>
                    </Col>
                    <Col span={8}>
                        <Row justify={'center'}>
                            <p className='fs-14 font-color-light-gray fw-500'>55</p>
                        </Row>
                    </Col>
                    <Col className='px-24' span={8}>
                        <Progress className='progress-primary' percent={43} showInfo={false} />
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={8}>
                        <p className='fs-12 fw-700 font-color-dark-blue'>KPSS</p>
                    </Col>
                    <Col span={8}>
                        <Row justify={'center'}>
                            <p className='fs-14 font-color-light-gray fw-500'>47</p>
                        </Row>
                    </Col>
                    <Col className='px-24' span={8}>
                        <Progress className='progress-primary' percent={33} showInfo={false} />
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={8}>
                        <p className='fs-12 fw-700 font-color-dark-blue'>AYT</p>
                    </Col>
                    <Col span={8}>
                        <Row justify={'center'}>
                            <p className='fs-14 font-color-light-gray fw-500'>43</p>
                        </Row>
                    </Col>
                    <Col className='px-24' span={8}>
                        <Progress className='progress-primary' percent={55} showInfo={false} />
                    </Col>
                </Row>
                <Row align={'middle'}>
                    <Col span={8}>
                        <p className='fs-12 fw-700 font-color-dark-blue'>12.Sınıf Matematik</p>
                    </Col>
                    <Col span={8}>
                        <Row justify={'center'}>
                            <p className='fs-14 font-color-light-gray fw-500'>62</p>
                        </Row>
                    </Col>
                    <Col className='px-24' span={8}>
                        <Progress className='progress-primary' percent={62} showInfo={false} />
                    </Col>
                </Row> */}
            </Card>
    )
}

export default PopularCourses