import { Button, Card, Col, Form, Input, InputNumber, Row, Select, Tree } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import PackageService from '../../services/PackagesService'
import { openNotification } from '../../utils'
import { CarryOutOutlined, DownOutlined, FolderOpenOutlined, FileSearchOutlined, QuestionCircleTwoTone, FilePdfTwoTone, VideoCameraFilled, QuestionCircleOutlined, FileTextTwoTone } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import GradeService from '../../services/GradeService';
import { NavLink, useNavigate } from 'react-router-dom';
import { IPackageRequest } from '../../types/Package';


import { ReactComponent as GradeIcon } from '../../assets/Subject/grade-icon.svg'
import { ReactComponent as LessonIcon } from '../../assets/Subject/lesson-icon.svg'
import { ReactComponent as UnitIcon } from '../../assets/Subject/unit-icon.svg'
import { ReactComponent as SubjectIcon } from '../../assets/Subject/subject-icon.svg'
import CurriculumService from '../../services/CurriculumService';

const { Item } = Form;

const formatDataForTree = (data: any) => {
    return data.map((grade: any) => ({
        title: grade.name,
        key: `grade-${grade.id}`,
        icon: <GradeIcon />,
        disabled: (grade.lessons.length > 0) ? false : true,
        children: grade.lessons.map((lesson: any) => ({
            title: lesson.name,
            key: `grade-${grade.id}-lesson-${lesson.id}`,
            icon: <LessonIcon />,
            disabled: (lesson.units.length > 0) ? false : true,
            children: lesson.units
                .filter((unit: any) => unit.gradeId === grade.id) // Filter units by grade id
                .map((unit: any) => ({
                    title: unit.name,
                    key: `grade-${grade.id}-lesson-${lesson.id}-unit-${unit.id}`,
                    icon: <UnitIcon />,
                    children: unit.subjects.map((subject: any) => ({
                        title: subject.title,
                        key: `subject-${subject.id}`,
                        icon: <SubjectIcon className='wh-20' />,
                    })),
                })),
        })),
    }));
};

const PackagesCreate = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [grades, setGrades] = useState([]);
    const [selectedSubjects, setSelectedSubjects] = useState<number[]>([]);
    const [curriculums, setCurriculums] = useState([])

    const navigate = useNavigate()

    useEffect(() => {
        getAll();
    }, []);

    const getAll = () => {
        setLoading(true);
        CurriculumService.getAll().then((response: any) => {
            const options = response.data.map((role: { id: number; name: string; }) => ({
                label: role.name,
                value: role.id,
            }));
            setCurriculums(options);
            setLoading(false);
        }).catch((e: Error) => {
            setLoading(false);
            console.log(e)
        });
    }

    const onCheck = (checkedKeys: any) => {
        // "subject-" ile başlayan key'leri filtrele
        const subjectKeys = checkedKeys.filter((key: string) => key.startsWith("subject-"));
        // "subject-" prefix'ini kaldırarak sadece ID'leri al
        const subjectIds = subjectKeys.map((key: string) => key.replace("subject-", ""));
        setSelectedSubjects(subjectIds); // Filtrelenmiş ve dönüştürülmüş ID'leri kaydet
    };

    const update = (values: any) => {
        let postObj: IPackageRequest = {
            "name": values.name,
            "description": values.description,
            "price": values.price,
            "commissionRate": values.commissionRate,
            "thumbnail": "",
            "tags": values.tags.join(','),
            "subjectIds": selectedSubjects
        };

        PackageService.create(postObj)
            .then((response: any) => {
                openNotification('success', 'Başarılı', 'Paket Başarıyla Oluşturuldu.')
                navigate('/packages');
            }).catch((e: Error) => {
                openNotification('error', 'Başarısız', 'Paket oluşturulamadı.')
                console.log(e)
            });
    }


    const getGrades = (id: number) => {
        setGrades([]);
        CurriculumService.getGradesByCurriculumId(id)
            .then((response: any) => {
                const formattedData = formatDataForTree(response.data);
                setGrades(formattedData);
                setLoading(false);
            })
            .catch((e: Error) => {
                console.log(e);
                setLoading(false);
            });
    };

    return (
        <>
            <Card loading={loading} className='border-radius-md'>
                <Row align={'middle'} justify={'space-between'} className='mb-24'>
                    <Col>
                        <h3 className='fs-24 m-0'>Paket Ekleme</h3>
                    </Col>
                    <Col>
                        <Button onClick={() => navigate(-1)} className='fw-700 btn bg-cloud btn-hover-dark'>
                            Geri
                        </Button>
                    </Col>
                </Row>
                <Form form={form} onFinish={update} layout="vertical">
                    <Item
                        name="name"
                        label="Paket"
                        rules={[
                            {
                                required: true,
                                message: 'Ders adı zorunlu',
                            }]}
                    >
                        <Input />
                    </Item>
                    <Item
                        name="description"
                        label="Açıklama"
                    >
                        <TextArea />
                    </Item>
                    <Item
                        name="tags"
                        label="Etiket"
                        rules={[
                            {
                                required: true,
                                message: 'Etiket zorunlu',
                            }]}
                    >
                        <Select
                            mode="tags"
                            style={{ width: '100%' }}
                            placeholder="Etiket"
                        />
                    </Item>
                    <Item
                        name="price"
                        label="Ücret"
                        rules={[
                            {
                                required: true,
                                message: 'Ücret zorunlu',
                            }]}
                    >
                        <InputNumber className='w-full' />
                    </Item>
                    <Item
                        name="commissionRate"
                        label="Komisyon"
                        rules={[
                            {
                                required: true,
                                message: 'Komisyon alanı zorunlu',
                            }]}
                    >
                        <InputNumber className='w-full' max={100} min={0} />
                    </Item>
                    <Item
                        name="curriculumId"
                        label="Müfredat"
                        rules={[
                            {
                                required: true,
                                message: 'Müfredat bir ders seçin', // Kullanıcı alanı boş bıraktığında gösterilecek mesaj
                            },
                        ]}
                    >
                        <Select options={curriculums} onChange={getGrades} />
                    </Item>
                    <Row>
                        <Col span={24}>
                            <h3 className='fs-24'>Konuları Seçin</h3>
                            <Item
                                name="subjectIds"
                                label="Konular"
                            >
                                <Tree
                                    checkable
                                    showLine={true}
                                    showIcon={true}
                                    defaultExpandAll={true}
                                    defaultExpandedKeys={['0-0-0']}
                                    treeData={grades}
                                    onCheck={onCheck}
                                    switcherIcon={<DownOutlined />}
                                />
                            </Item>
                        </Col>
                    </Row>
                    <Row gutter={8} justify={"end"}>
                        <Col>
                            <Item>
                                <Button htmlType="submit" className='w-full height-40 btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text'>
                                    Kaydet
                                </Button>
                            </Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </>
    )
}

export default PackagesCreate