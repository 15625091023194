import { http } from "../http-common";
import { IQuestionRequest, IQuestionResponse } from "../types/Question";
import { isAuthorized, openNotification } from "../utils";

const getAll = () => {
    if (isAuthorized('question.list')) {
        return http.get<IQuestionResponse>(`/question`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const create = (data: any, file: any) => {

    const formData = new FormData();
    formData.append("file", file);
    formData.append("correctAnswer", data.correctAnswer);
    formData.append("answerType", data.answerType);
    if (data?.vimeoId) {
        formData.append("vimeoId", data?.vimeoId);
    }
    formData.append("subjectId", data.subjectId.toString());

    const config = {
        headers: {
            maxBodyLength: Infinity,
            'Content-Type': 'multipart/form-data',
        },
    };

    if (isAuthorized('question.create')) {
        return http.post<IQuestionRequest>(`/question`, formData, config);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const get = (id: any) => {
    if (isAuthorized('question.show')) {
        return http.get<any>(`/question/${id}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const getFile = (fileName: any) => {
    if (isAuthorized('question.download')) {
        return http.get<any>(`/question/download?file=${fileName}`, { responseType: 'arraybuffer' });

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const update = (id: number, data: IQuestionRequest) => {
    if (isAuthorized('question.update')) {
        return http.patch<IQuestionRequest>(`/question/${id}`, data);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const remove = (id: number) => {
    if (isAuthorized('question.delete')) {
        return http.delete<IQuestionRequest>(`/question/${id}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const updateSortOrders = (data: any) => {
    if (isAuthorized('question.update')) {
        return http.put<any>(`/question/update-sort-orders`, data);
    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const QuestionService = {
    create,
    getAll,
    get,
    getFile,
    update,
    remove,
    updateSortOrders
}

export default QuestionService;