import { Breadcrumb, Button, Card, Col, Popconfirm, Row, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { VideoCameraAddOutlined, FilePdfOutlined, QuestionCircleOutlined, FileTextOutlined, DeleteOutlined } from '@ant-design/icons';
import VideoList from './SubjetDetail/VideoList';
import DocumentList from './SubjetDetail/DocumentList';
import QuestionList from './SubjetDetail/QuestionList';
import ExplanationList from './SubjetDetail/ExplanationList';
import VideoCreate from './SubjetDetail/Create/VideoCreate';
import DocumentCreate from './SubjetDetail/Create/DocumentCreate';
import QuestionCreate from './SubjetDetail/Create/QuestionCreate';
import ExplanationCreate from './SubjetDetail/Create/ExplanationCreate';
import SubjectUpdate from './SubjetDetail/Update/SubjectUpdate';
import { removeItem } from '../services/RemoveService';
import QuizList from './SubjetDetail/QuizList';
import QuizCreate from './SubjetDetail/Create/QuizCreate';

interface SubjectDetailProps {
    subject: any;
    getTrigger: any;
}

const SubjectDetail: React.FC<SubjectDetailProps> = ({ subject, getTrigger }) => {
    const [activeKey, setActiveKey] = useState<string>('video');
    const [refreshKey, setRefreshKey] = useState<number>(0);
    const [actionNode, setActionNode] = useState<any>();

    useEffect(() => {
        console.log('subjectsubjectsubject', )
        if (actionNode?.action === 'delete') {
            removeItem(actionNode).then(() => {
                getTrigger();
            })
                .catch((error) => {
                    console.error('Silme işlemi başarısız:', error);
                });
        }
    }, [actionNode])

    const getData = () => {
        setRefreshKey(prevKey => prevKey + 1);
    };

    const renderCreateComponent = () => {
        switch (activeKey) {
            case 'video':
                return <VideoCreate getData={getData} subjectId={subject.id} />;
            case 'document':
                return <DocumentCreate getData={getData} subjectId={subject.id} />;
            case 'question':
                return <QuestionCreate getData={getData} subjectId={subject.id} />;
            case 'explanation':
                return <ExplanationCreate getData={getData} subjectId={subject.id} />;
            case 'quiz':
                return <QuizCreate getData={getData} lessonId={subject?.lessonId} subjectId={subject.id} />;
            default:
                return null;
        }
    };

    const handleTabChange = (key: string) => {
        setActiveKey(key);
    };

    return (
        <Card
            bodyStyle={{ paddingTop: '12px' }}
            title={subject?.title}
            extra={
                <Row align={"middle"} gutter={[16, 16]} >
                    <Col>
                        <Breadcrumb separator=">">
                            {subject.breadcrumb.slice(0, -1).map((item: any, index: number) => (
                                <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
                            ))}
                        </Breadcrumb>
                    </Col>
                    <Col>
                        <SubjectUpdate subjectId={subject.id} getTrigger={() => { getData() }}></SubjectUpdate>
                    </Col>
                    <Col>
                        <Popconfirm
                            title="Konuyu Sil"
                            description="Konuyu silmek istediğinize emin misiniz?"
                            okText="Evet"
                            cancelText="Hayır"
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            onConfirm={() => setActionNode({ id: subject.id, data: null, action: 'delete', type: 'subject' })}
                        >
                            <Button danger onClick={e => e.stopPropagation()}><DeleteOutlined /></Button>
                        </Popconfirm>
                    </Col>
                </Row>

            }>
            <Tabs
                defaultActiveKey="video"
                tabBarExtraContent={renderCreateComponent()}
                size="large"
                onChange={handleTabChange}
                items={[
                    {
                        key: 'video',
                        label: (
                            <>
                                <VideoCameraAddOutlined className='mr-8' /> Video
                            </>
                        ),
                        children: <VideoList refresh={refreshKey} subjectId={subject?.id} />,
                    },
                    {
                        key: 'document',
                        label: (
                            <>
                                <FilePdfOutlined className='mr-8' /> Doküman
                            </>
                        ),
                        children: <DocumentList refresh={refreshKey} subjectId={subject?.id} />,
                    },
                    {
                        key: 'question',
                        label: (
                            <>
                                <QuestionCircleOutlined className='mr-8' /> Soru
                            </>
                        ),
                        children: <QuestionList refresh={refreshKey} subjectId={subject?.id} />,
                    },
                    {
                        key: 'explanation',
                        label: (
                            <>
                                <FileTextOutlined className='mr-8' /> Konu Anlatımı
                            </>
                        ),
                        children: <ExplanationList refresh={refreshKey} subjectId={subject?.id} />,
                    },
                    {
                        key: 'quiz',
                        label: (
                            <>
                                <QuestionCircleOutlined className='mr-8' /> Sınav
                            </>
                        ),
                        children: <QuizList refresh={refreshKey} subjectId={subject?.id} />,
                    },
                ]}
            />
        </Card>
    );
};

export default SubjectDetail;
