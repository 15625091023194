import { Col, Row } from 'antd'
import WelcomeCard from '../components/Dashboard/WelcomeCard'
import TotalStudent from '../components/Dashboard/TotalStudent'
import LiveLessonGraph from '../components/Dashboard/LiveLessonGraph'
import PopularCourses from '../components/Dashboard/PopularCourses'
import TotalPackages from '../components/Dashboard/TotalPackages'
import TotalSubject from '../components/Dashboard/TotalSubject'
import TotalLiveLessons from '../components/Dashboard/TotalLiveLessons'
import { useEffect, useState } from 'react'
import DashboardService from '../services/DashboardService'

const Dashboard = () => {
  const [data, setData] = useState<any>()
  const getData = () => {
    DashboardService.stats()
      .then((response: any) => {
        const data = response.data
        setData(data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }

  useEffect(() => {
    getData();
  }, [])


  return (
    <>
      <Row gutter={[24, 24]}>
        <Col xl={16}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <WelcomeCard />
            </Col>
            <Col span={24}>
              <LiveLessonGraph />
            </Col>
          </Row>
        </Col>
        <Col xl={8}>
          <PopularCourses />
        </Col>
      </Row>
      <Row className='mt-24' gutter={[24, 24]}>
        <Col span={6}>
          <TotalStudent data={data?.students}/>
        </Col>
        <Col xl={6}>
          <TotalPackages data={data?.packages}/>
        </Col>
        <Col xl={6}>
          <TotalSubject data={data?.subjects}/>
        </Col>
        <Col xl={6}>
          <TotalLiveLessons data={data?.liveLessons}/>
        </Col>
      </Row>
    </>
  )
}

export default Dashboard