import { Button, Col, Form, Input, Row } from 'antd'
import { LockOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import AuthService from '../services/AuthService';
import { openNotification, removeLocal } from '../utils';
import { useNavigate } from 'react-router-dom';
import UserService from '../services/UserService';
import { useEffect, useState } from 'react';
import { MailOutlined } from '@ant-design/icons';

const LoginPage = () => {
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState<boolean>(false);

    useEffect(() => {
        removeLocal()
    }, [])

    const userGet = () => {
        UserService.getMe()
            .then((response: any) => {
                console.log(response)
                let role = response.data?.roles[0]?.name
                localStorage.setItem('firstName', response.data.firstName)
                localStorage.setItem('lastName', response.data.lastName)
                localStorage.setItem('email', response.data.email)
                localStorage.setItem('id', response.data.id)
                localStorage.setItem('role', response.data?.roles[0]?.name)
                localStorage.setItem('permissions', JSON.stringify(response.data?.roles[0]?.permissions))
                if (role === 'ADMIN') {
                    navigate('/')
                }
                else if ((role === 'TEACHER')) {
                    navigate('/')
                } else {
                    openNotification('error', 'Başarısız', 'Admin panele giriş yetkiniz yok')
                    removeLocal()
                }
            }).catch((e: Error) => {
                console.log(e)
                removeLocal()
                openNotification('error', 'Başarısız', 'Admin panele giriş yetkiniz yok')
            });
    }

    const onFinish = (values: { email: string; password: string }) => {

        let postObj = {
            email: values.email,
            password: values.password
        }

        AuthService.login(postObj)
            .then((response: any) => {
                console.log(response)
                localStorage.setItem('accessToken', response?.data?.accessToken);
                localStorage.setItem('accessTokenExpiredAt', response?.data?.accessTokenExpiredAt);
                localStorage.setItem('refreshToken', response?.data?.refreshToken);
                localStorage.setItem('refreshTokenExpiredAt', response?.data?.refreshTokenExpiredAt);
                userGet();
            }).catch((e: Error) => {
                console.log(e)
                openNotification('error', 'Başarısız', 'Giriş Yapılamadı.')
            });
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <Row style={{ height: "100vh" }} justify={'center'} align={'middle'}>
                <Col xxl={8} lg={12} md={16} sm={22}>
                    <Form
                        name="login_form"
                        className="login-form"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                    >
                        <Row justify={'center'}>
                            <Col>
                                <h2 className='section-title'>Giriş Yap</h2>
                            </Col>
                        </Row>
                        <Row justify={'center'}>
                            <Col>
                                <p className='section-subtitle'>Hesabınıza giriş yapın, Hemen başlayın!</p>
                            </Col>
                        </Row>
                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: 'Lütfen E-posta giriniz!' }]}
                        >
                            <Input
                                prefix={<MailOutlined />}
                                className='form-input'
                                placeholder="E-Posta"
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Lütfen şifrenizi girin!' }]}
                        >
                            <Input.Password
                                size='large'
                                prefix={<LockOutlined />}
                                placeholder="Şifre"
                                iconRender={visible => (visible ? <EyeTwoTone onClick={togglePasswordVisibility} /> : <EyeInvisibleOutlined onClick={togglePasswordVisibility} />)}
                            />
                        </Form.Item>
                        <Row align={'middle'} justify={'end'} style={{ paddingBottom: "1rem" }}>
                            <Col>
                                <a href='/forgot-password' className='forgot-password'>Şifreni mi unuttun?</a>
                            </Col>
                        </Row>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-btn">
                                Giriş Yap
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </>
    )
}

export default LoginPage