import {http} from "../http-common";

const get = (id: any) => {
        return http.get<any>(`/students/${id}/history`);
};

const AccountHistoryService = {
    get,
}

export default AccountHistoryService;