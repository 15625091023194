import { Button, Modal, Form, Select, Row } from 'antd';
import React, { useState } from 'react';
import filterIcon from '../../assets/Layout/filter.svg';


const { Item } = Form;

interface LearnerFilterProps {
    onChangeFilter: (values: any) => void;
    students: any;
    teachers: any;
    lessons: any;
}


const Filter: React.FC<LearnerFilterProps> = ({ onChangeFilter, students, teachers, lessons }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();

    const showModal = () => {
        setIsModalOpen(true);
    };

    const onFinish = (values: any) => {
        onChangeFilter(values);
        setIsModalOpen(false);
    };


    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        form.resetFields();
        setIsModalOpen(false);
    };

    const clearForm = () => {
        form.resetFields();
    };

    const onValuesChange = (changedValues: any, allValues: any) => {
        const { startProductCount, endProductCount } = allValues;
        if (startProductCount && (endProductCount < startProductCount || !endProductCount)) {
            form.setFieldsValue({ endProductCount: startProductCount });
        }
    };



    return (
        <>
            <Modal title="Canlı Ders Filtre" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[]}>
                <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} onValuesChange={onValuesChange}>
                    Öğrenci
                    <div>
                        <Item name="student">
                            <Select placeholder={"Seç"}>
                                <Select.Option key={0} value={null}>
                                    Seç
                                </Select.Option>
                                {students.map((student: any) => (
                                    <Select.Option key={student.id} value={student.id}>
                                        {student.firstName} {student.lastName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Item>
                    </div>
                    Öğretmen
                    <div>
                        <Item name="teacher">
                            <Select placeholder={"Seç"}>
                                <Select.Option key={0} value={null}>
                                    Seç
                                </Select.Option>
                                {teachers.map((teacher: any) => (
                                    <Select.Option key={teacher.id} value={teacher.id}>
                                        {teacher.firstName} {teacher.lastName}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Item>
                    </div>
                    Ders
                    <div>
                        <Item name="lesson">
                            <Select placeholder={"Seç"}>
                                <Select.Option key={0} value={null}>
                                    Seç
                                </Select.Option>
                                {lessons.map((lesson: any) => (
                                    <Select.Option key={lesson.id} value={lesson.id}>
                                        {lesson.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Item>
                    </div>
                    Onaylananlar
                    <Row>
                        <Item name="isApprovel" valuePropName="checked" style={{ width: '100%' }}>
                            <Select placeholder={"Seç"}>
                                <Select.Option key={7} value={null}>
                                    Seç
                                </Select.Option>
                                <Select.Option key={0} value={'true'}>Onaylanmış</Select.Option>
                                <Select.Option key={1} value={'false'}>Onaylanmamış</Select.Option>
                            </Select>
                        </Item>
                    </Row>
                    Tamamlananlar
                    <Row>
                        <Item name="isComplete" valuePropName="checked" style={{ width: '100%' }}>
                            <Select placeholder={"Seç"}>
                                <Select.Option key={0} value={null}>
                                    Seç
                                </Select.Option>
                                <Select.Option key={1} value={'true'}>Tamamlanmış</Select.Option>
                                <Select.Option key={2} value={'false'}>Tamamlanmamış</Select.Option>
                            </Select>
                        </Item>
                    </Row>


                    <Item>
                        <Row justify="end">
                            <Button key="back" style={{ marginRight: "10px" }} onClick={clearForm}>
                                Temizle
                            </Button>
                            <Button htmlType='submit' className='bg-saha-purple  font-color-white' type='default'>
                                Filtre
                            </Button>
                        </Row>
                    </Item>

                </Form>
            </Modal>
            <Button className='mr-15' onClick={showModal} type="default">
                <Row>
                    <img src={filterIcon} alt='filterIcon' style={{ margin: 1 }} />
                </Row>
            </Button>
        </>
    );
}

export default Filter
