import { Button, Col, Divider, Form, Input, Modal, Row } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { useEffect, useState } from 'react'
import { openNotification } from '../../../utils';
import LessonService from '../../../services/LessonService';
import GradeService from '../../../services/GradeService';

interface CreateLessonModalProps {
  showModal: boolean;
  setShowModal: any;
  lessonId: number;
  getTrigger: any;
}

const { Item } = Form;

const EditLessonModal: React.FC<CreateLessonModalProps> = ({ showModal, setShowModal, lessonId, getTrigger }) => {
  const [grades, setGrades] = useState<any>([]);
  const [gradeId, setGradeId] = useState<any>([]);
  const [form] = Form.useForm();
  const [fetchedData, setFetchedData] = useState(false); // State to track whether data has been fetched

  useEffect(() => {
    if (showModal && !fetchedData) { // Check if modal is shown and data has not been fetched
      getService();
      setFetchedData(true); // Update state to indicate data has been fetched
    }
  }, [showModal]);

  const getGrades = () => {
    GradeService.getAll()
      .then((response: any) => {
        const data = response.data
        const options = data.map((grade: { id: number; name: string; }) => ({
          label: grade.name, // Kullanıcıya gösterilecek metin
          value: grade.id, // Form üzerinden gönderilecek değer
        }));
        setGrades(options);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getService = () => {
    LessonService.get(lessonId)
      .then((response: any) => {
        const data = response.data
        setGradeId(data?.gradeId)
        form.setFieldsValue({
          name: data?.name,
          description: data?.description,
        });
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const handleLessonCreateCancel = () => {
    setShowModal(false);
    setFetchedData(false)
  };

  useEffect(() => {
    form.resetFields();
  }, [showModal])

  const update = (values: any) => {
    let postObj = {
      "name": values.name,
      "description": values.description,
      "gradeIds": gradeId
    };

    LessonService.update(lessonId, postObj)
      .then((response: any) => {
        openNotification('success', 'Başarılı', 'Ders Başarıyla Düzenlendi.')
        setShowModal(false)
        setFetchedData(false)
        getTrigger();
      }).catch((e: Error) => {
        openNotification('error', 'Başarısız', 'Ders Düzenlenemedi.')
        console.log(e)
      });
  }

  const findGradeName = (gradeId: number) => {
    const grade = grades.find((grade: any) => grade.value === gradeId);
    return grade ? grade.label : '';
  }

  return (
    <>
      <Modal title={`Ders Düzenle - ${findGradeName(gradeId)}`} open={showModal} closeIcon={<div onClick={() => setShowModal(false) && setFetchedData(false)}>X</div>}
        footer={
          <></>
        }
      >
        <Divider></Divider>
        <Form form={form} onFinish={update} layout="vertical">
          <Item
            name="name"
            label="Ders"
            rules={[
              {
                required: true,
                message: 'Ders adı zorunlu',
              }]}
          >
            <Input />
          </Item>
          <Item
            name="description"
            label="Açıklama"
          >
            <TextArea />
          </Item>
          <Divider></Divider>
          <Row gutter={8}>
            <Col span={12}>
              <Button onClick={handleLessonCreateCancel} className='w-full height-40'>
                İptal
              </Button>
            </Col>
            <Col span={12}>
              <Item>
                <Button htmlType="submit" className='w-full height-40 btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text'>
                  Kaydet
                </Button>
              </Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default EditLessonModal