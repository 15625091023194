import { Avatar, Col, Dropdown, Row, MenuProps, Tooltip, Popconfirm } from 'antd'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { QuestionCircleOutlined, ReadOutlined, WechatWorkOutlined, FileSearchOutlined, LogoutOutlined } from '@ant-design/icons'
import { ReactComponent as DashboardIcon } from '../../assets/Layout/dashboard-active.svg'
import { ReactComponent as PackageIcon } from '../../assets/Layout/packages-icon.svg'
import { ReactComponent as SubjectIcon } from '../../assets/Layout/subjects-icon.svg'
import { ReactComponent as LiveLessonsIcon } from '../../assets/Layout/UserRectangle.svg'
import { ReactComponent as StudentIcon } from '../../assets/Layout/stundents-icon.svg'
import { ReactComponent as TeacherIcon } from '../../assets/Layout/teachers-icon.svg'
import { ReactComponent as CollapseIcon } from '../../assets/Layout/arrow-down.svg'

import { useEffect, useState } from 'react'
import { removeLocal } from '../../utils'

const Menu = () => {
    const navigate = useNavigate()
    const [firstName, setFirstName] = useState<any>()
    const [lastName, setLastName] = useState<any>()
    const firstLetter = firstName ? firstName.charAt(0).toUpperCase() : '';
    const [role, setRole] = useState<any>()

    useEffect(() => {
        let firstN = localStorage.getItem('firstName')
        let lastN = localStorage.getItem('lastName')
        let role = localStorage.getItem('role')
        setFirstName(firstN)
        setLastName(lastN)
        setRole(role)
    }, [])


    const logout = () => {
        removeLocal()
        navigate('/login')
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <Row className='mb-12' align={"middle"} justify={"space-evenly"} gutter={8}>
                        <Col>
                            <Link to={'/profile'} rel="noopener noreferrer" >
                                <Row>
                                    <Col>
                                        <Avatar className='mr-12' style={{ background: '#663690' }} >{firstLetter}</Avatar>
                                    </Col>
                                    <Col>
                                        <p className='fs-14 m-0 font-color-saha-dark'>
                                            {firstName} {lastName}
                                        </p>
                                        <p className='fs-10 m-0 font-color-saha-dark'>
                                            {role}
                                        </p>
                                    </Col>
                                </Row>
                            </Link>
                        </Col>
                        <Col>
                            <Tooltip title="Çıkış">
                                <Popconfirm
                                    title="Çıkmak istediğinize emin misiniz?"
                                    okText="Evet"
                                    cancelText="Hayır"
                                    onConfirm={logout}
                                >
                                    <LogoutOutlined className='fs-20'  />
                                </Popconfirm>
                            </Tooltip>
                        </Col>
                    </Row>
                </Col>
                <p className='font-color-gray upper-case fw-700 fs-12 ml-16'>Uygulama</p>
                <Col span={24}>
                    <div className='menu-wrapper w-full'>
                        <NavLink className={({ isActive }) =>
                            isActive ? 'font-color-dark fw-700' : ''
                        } to={'/'}>
                            <Row className='pl-16' gutter={16} justify={"start"} align={"middle"}>
                                <Col>
                                    <DashboardIcon className='mt-8' />
                                </Col>
                                <Col>
                                    <p className=' font-color-gray fs-14'>Genel Görünüm</p>
                                </Col>
                            </Row>
                        </NavLink>
                    </div>
                </Col>
                {/* <div className='menu-wrapper w-full'>
                    <NavLink className={({ isActive }) =>
                        isActive ? 'font-color-dark fw-700' : ''
                    } to={'/packages'}>
                        <Row className='pl-16' gutter={16} justify={"start"} align={"middle"}>
                            <Col>
                                <PackageIcon className='mt-8' />
                            </Col>
                            <Col>
                                <p className='font-color-gray fs-14'>Paketler</p>
                            </Col>
                        </Row>
                    </NavLink>
                </div> */}
                <div className='menu-wrapper w-full'>
                    <NavLink className={({ isActive }) =>
                        isActive ? 'font-color-dark fw-700' : ''
                    } to={'/course'}>
                        <Row className='pl-16' gutter={16} justify={"start"} align={"middle"}>
                            <Col>
                                <PackageIcon className='mt-8' />
                            </Col>
                            <Col>
                                <p className='font-color-gray fs-14'>Kurslar</p>
                            </Col>
                        </Row>
                    </NavLink>
                </div>
                <div className='menu-wrapper w-full'>
                    <NavLink className={({ isActive }) =>
                        isActive ? 'font-color-dark fw-700' : ''
                    } to={'/curriculums'}>
                        <Row className='pl-16' gutter={16} justify={"start"} align={"middle"}>
                            <Col>
                                <SubjectIcon className='mt-8' />
                            </Col>
                            <Col>
                                <p className='fs-14 font-color-gray'>Müfredat</p>
                            </Col>
                        </Row>
                    </NavLink>
                </div>
                <div className='menu-wrapper w-full'>
                    <NavLink className={({ isActive }) =>
                        isActive ? 'font-color-dark fw-700' : ''
                } to={'/quiz'}>
                        <Row className='pl-16' gutter={16} justify={"start"} align={"middle"}>
                            <Col>
                                <FileSearchOutlined className='fs-24 font-color-gray' />
                            </Col>
                            <Col>
                                <p className='fs-14 font-color-gray'>Sınavlar</p>
                            </Col>
                        </Row>
                    </NavLink>
                </div>
                <div className='menu-wrapper w-full'>
                    <NavLink className={({ isActive }) =>
                        isActive ? 'font-color-dark fw-700' : ''
                } to={'/live-lessons'}>
                        <Row className='pl-16' gutter={16} justify={"start"} align={"middle"}>
                            <Col>
                                <LiveLessonsIcon className='mt-8' />
                            </Col>
                            <Col>
                                <p className='font-color-gray'>Canlı Dersler</p>
                            </Col>
                        </Row>
                    </NavLink>
                </div>
                <div className='menu-wrapper w-full'>
                    <NavLink className={({ isActive }) =>
                        isActive ? 'font-color-dark fw-700' : ''
                } to={'/students'}>
                        <Row className='pl-16' gutter={16} justify={"start"} align={"middle"}>
                            <Col>
                                <StudentIcon className='mt-8' />
                            </Col>
                            <Col>
                                <p className='font-color-gray'>Öğrenciler</p>
                            </Col>
                        </Row>
                    </NavLink>
                </div>

                <div className='menu-wrapper w-full'>
                    <NavLink className={({ isActive }) =>
                        isActive ? 'font-color-dark fw-700' : ''
                } to={'/questions'}>
                        <Row className='pl-16' gutter={16} justify={"start"} align={"middle"}>
                            <Col>
                                <QuestionCircleOutlined className='fs-24 font-color-gray' />
                            </Col>
                            <Col>
                                <p className='font-color-gray'>Öğrenci Soruları</p>
                            </Col>
                        </Row>
                    </NavLink>
                </div>
                <div className='menu-wrapper w-full'>
                    <NavLink className={({ isActive }) =>
                        isActive ? 'font-color-dark fw-700' : ''
                } to={'/assignments'}>
                        <Row className='pl-16' gutter={16} justify={"start"} align={"middle"}>
                            <Col>
                                <ReadOutlined className='fs-24 font-color-gray' />
                            </Col>
                            <Col>
                                <p className='font-color-gray'>Ödevler</p>
                            </Col>
                        </Row>
                    </NavLink>
                </div>
            </Row >
        </>
    )
}

export default Menu