import { Card, Col, Row, Form, Button, Drawer, Input, Select } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import LessonService from '../services/LessonService';
import LessonsItemCard from '../components/Lessons/LessonsItemCard';
import GradeService from '../services/GradeService';
import TextArea from 'antd/es/input/TextArea';
import { openNotification } from '../utils';

const { Item } = Form;

const Lessons = () => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [lessons, setLessons] = useState([]);
  const [grades, setGrades] = useState<any>([]);

  let navigate = useNavigate();

  useEffect(() => {
    getLessons()
    getGrades();
  }, []);

  const getLessons = () => {
    setLoading(true);
    LessonService.getAll()
      .then((response: any) => {
        const data = response.data;
        setLessons(data);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
        setLoading(false);
      });
  };

  const getGrades = () => {
    GradeService.getAll()
      .then((response: any) => {
        const data = response.data
        const options = data.map((grade: { id: number; name: string; }) => ({
          label: grade.name, // Kullanıcıya gösterilecek metin
          value: grade.id, // Form üzerinden gönderilecek değer
        }));
        setGrades(options);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [open])

  const create = (values: any) => {

    let postObj = {
      "name": values.name,
      "description": values.description,
      "gradeIds": values.gradeId,
    };

    LessonService.create(postObj)
      .then((response: any) => {
        openNotification('success', 'Başarılı', 'Ders Başarıyla Eklendi.')
        getLessons();
        onClose();
      }).catch((e: Error) => {
        console.log(e)
        openNotification('error', 'Başarısız', 'Ders Eklenemedi.')
      });
  }

  return (
    <>
      <Row justify={'center'} className='m-24'>
        <Col span={16}>
          <Card loading={loading}>
            <Row align={'middle'} justify={'space-between'}>
              <Col>
                <h2 className='mr-16'>Dersler</h2>
                <Button onClick={showDrawer} className=''>
                  Yeni Ders Ekle
                </Button>
              </Col>
              <Col>
                <Button onClick={() => navigate(-1)} className=''>
                  Geri
                </Button>
              </Col>
            </Row>
            <Row gutter={[24, 24]} className='mt-24'>
              {lessons.map((lesson) => (
                <Col span={24}>
                  <LessonsItemCard grades={grades} lesson={lesson} getTrigger={getLessons} />
                </Col>
              ))}
            </Row>
          </Card>
          <Drawer title="Ders Ekle" placement="right" onClose={onClose} open={open}>
            <Form form={form} onFinish={create} layout="vertical">
              <Item
                name="name"
                label="Ders"
                rules={[
                  {
                    required: true,
                    message: 'Ders adı zorunlu',
                  }]}
              >
                <Input />
              </Item>
              <Item
                name="description"
                label="Açıklama"
              >
                <TextArea />
              </Item>
              <Item
                name="gradeId"
                label="Sınıf"
                rules={[
                  {
                    required: true,
                    message: 'Lütfen bir Sınıf seçin', // Kullanıcı alanı boş bıraktığında gösterilecek mesaj
                  },
                ]}
              >
                <Select options={grades} />
              </Item>
              <Item>
                <Row justify={'end'}>
                  <Button className='btn btn-hover-dark bg-cloud font-color-onyx btn-text mr-16' onClick={onClose}>
                    Vazgeç
                  </Button>
                  <Button className='btn btn-hover-dark bg-slate-blue font-color-cloud btn-text' htmlType="submit">
                    Kaydet
                  </Button>
                </Row>
              </Item>
            </Form>
          </Drawer>
        </Col>
      </Row>
    </>
  )
}

export default Lessons