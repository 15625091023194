import { http } from "../http-common";
import { ISubjectRequest, ISubjectResponse } from "../types/Subject";
import { isAuthorized, openNotification } from "../utils";

const getAll = () => {
    if (isAuthorized('subject.list')) {
        return http.get<ISubjectResponse>(`/subject`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const create = (data: ISubjectRequest) => {
    if (isAuthorized('subject.create')) {
        return http.post<ISubjectRequest>(`/subject`, data);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const get = (id: number) => {
    if (isAuthorized('subject.show')) {
        return http.get<ISubjectResponse>(`/subject/${id}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const getVideosBySubjectId = (id: number) => {
    if (isAuthorized('subject.show')) {
        return http.get<any>(`/subject/${id}/videos`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const getDocumentsBySubjectId = (id: number) => {
    if (isAuthorized('subject.show')) {
        return http.get<any>(`/subject/${id}/documents`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const getQuestionsBySubjectId = (id: number) => {
    if (isAuthorized('subject.show')) {
        return http.get<any>(`/subject/${id}/questions`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const getExplanationsBySubjectId = (id: number) => {
    if (isAuthorized('subject.show')) {
        return http.get<any>(`/subject/${id}/explanations`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const getQuizzesBySubjectId = (id: number) => {
    if (isAuthorized('subject.show')) {
        return http.get<any>(`/subject/${id}/quizzes`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const update = (id: number, data: ISubjectRequest) => {
    if (isAuthorized('subject.update')) {
        return http.patch<ISubjectRequest>(`/subject/${id}`, data);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const remove = (id: number) => {
    if (isAuthorized('subject.delete')) {
        return http.delete<ISubjectRequest>(`/subject/${id}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const updateSortOrders = (data: any) => {
    if (isAuthorized('subject.update')) {
        return http.put<any>(`/subject/update-sort-orders`, data);
    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};


const SubjectService = {
    create,
    getAll,
    get,
    update,
    remove,
    getVideosBySubjectId,
    getDocumentsBySubjectId,
    getQuestionsBySubjectId,
    getExplanationsBySubjectId,
    getQuizzesBySubjectId,
    updateSortOrders
}

export default SubjectService;