import { http } from "../http-common";
import { IExplanationRequest, IExplanationResponse } from "../types/Explanation";
import { isAuthorized, openNotification } from "../utils";

const getAll = () => {
    if (isAuthorized('explanation.list')) {
        return http.get<IExplanationResponse>(`/explanation`);
    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const create = (data: any, file: any) => {
    const formData = new FormData();
    // const dummyFile = new Blob([], { type: 'application/octet-stream' });
    formData.append("file", file ? file : null);
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("subjectId", data.subjectId.toString());
    formData.append("type", data.type);

    const config = {
        headers: {
            maxBodyLength: Infinity,
            'Content-Type': 'multipart/form-data',
        },
    };

    if (isAuthorized('explanation.create')) {
        return http.post<any>(`/explanation`, formData, config);
    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const get = (id: number) => {
    if (isAuthorized('explanation.show')) {
        return http.get<IExplanationResponse>(`/explanation/${id}`);
    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const getFile = (fileName: any) => {
    return http.get<any>(`/explanation/download?file=${fileName}`, { responseType: 'arraybuffer' });
};

const update = (id: number, data: IExplanationRequest) => {
    if (isAuthorized('explanation.update')) {
        return http.patch<IExplanationRequest>(`/explanation/${id}`, data);
    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }

};

const remove = (id: number) => {
    if (isAuthorized('explanation.delete')) {
        return http.delete<IExplanationRequest>(`/explanation/${id}`);
    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const updateSortOrders = (data: any) => {
    if (isAuthorized('explanation.update')) {
        return http.put<any>(`/explanation/update-sort-orders`, data);
    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const ExplanationService = {
    create,
    getAll,
    get,
    update,
    remove,
    getFile,
    updateSortOrders
}

export default ExplanationService;