import React from 'react';
import { Tree } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { ReactComponent as GradeIcon } from '../assets/Subject/grade-icon.svg';
import { ReactComponent as LessonIcon } from '../assets/Subject/lesson-icon.svg';
import { ReactComponent as UnitIcon } from '../assets/Subject/unit-icon.svg';
import { ReactComponent as SubjectIcon } from '../assets/Subject/subject-icon.svg';

const formatDataForTree = (data: any) => {
    return data.map((grade: any) => ({
        title: grade.name,
        key: `grade-${grade.id}`,
        icon: <GradeIcon />,
        disabled: (grade.lessons.length > 0) ? false : true,
        children: grade.lessons.map((lesson: any) => ({
            title: lesson.name,
            key: `grade-${grade.id}-lesson-${lesson.id}`,
            icon: <LessonIcon />,
            disabled: (lesson.units.length > 0) ? false : true,
            children: lesson.units
                .filter((unit: any) => unit.gradeId === grade.id) // Filter units by grade id
                .map((unit: any) => ({
                    title: unit.name,
                    key: `grade-${grade.id}-lesson-${lesson.id}-unit-${unit.id}`,
                    icon: <UnitIcon />,
                    children: unit.subjects.map((subject: any) => ({
                        title: subject.title,
                        key: `subject-${subject.id}`,
                        icon: <SubjectIcon className='wh-20' />,
                    })),
                })),
        })),
    }));
};

interface TreeComponentProps {
    grades: any[];
    checkedKeys: any[];
    onCheck: (checkedKeys: any) => void;
}

const SubjectTree: React.FC<TreeComponentProps> = ({ grades, checkedKeys, onCheck }) => {
    const formattedGrades = formatDataForTree(grades);
    return (
        <Tree
            checkable={true}
            showLine={true}
            showIcon={true}
            defaultExpandAll={true}
            defaultExpandedKeys={['0-0-0']}
            treeData={formattedGrades}
            onCheck={onCheck}
            checkedKeys={checkedKeys}
            switcherIcon={<DownOutlined />}
        />
    );
};

export default SubjectTree;
