import React, { useEffect, useState } from 'react'
import CurriculumService from '../services/CurriculumService';
import { Button, Card, Col, Drawer, Form, Input, Popconfirm, Row } from 'antd';
import { NavLink, useNavigate } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import { openNotification } from '../utils';
import { FileSearchOutlined, EditFilled, DeleteFilled } from '@ant-design/icons';

const { Item } = Form;

const Curriculum = () => {

    let navigate = useNavigate();
    const [form] = Form.useForm();
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [showAddDrawer, setShowAddDrawer] = useState(false)
    const [id, setId] = useState(null)

    useEffect(() => {
        getAll();
    }, []);

    useEffect(() => {
        if (!showAddDrawer) { setId(null) };
    }, [showAddDrawer])

    const getAll = () => {
        setLoading(true);
        CurriculumService.getAll().then((response: any) => {
            setData(response.data)
            setLoading(false);
        }).catch((e: Error) => {
            setLoading(false);
            console.log(e)
        });
    }

    const openEditModal = (item: any) => {
        setId(item.id)
        form.setFieldValue("name", item.name);
        form.setFieldValue("description", item.description);
        setShowAddDrawer(true);
    }

    const save = (values: any) => {

        let postObj = {
            "name": values.name,
            "description": values.description,
        };


        if (id) {
            CurriculumService.update(id, postObj)
                .then((response: any) => {
                    openNotification('success', 'Başarılı', 'Müfredat Başarıyla Güncellendi.')
                    getAll();
                    setShowAddDrawer(false)
                    form.resetFields();
                }).catch((e: Error) => {
                    openNotification('error', 'Başarısız', 'Müfredat Güncellenemedi.')
                    console.log(e)
                });
        } else {
            CurriculumService.create(postObj)
                .then((response: any) => {
                    openNotification('success', 'Başarılı', 'Müfredat Başarıyla Eklendi.')
                    getAll();
                    setShowAddDrawer(false)
                    form.resetFields();
                }).catch((e: Error) => {
                    openNotification('error', 'Başarısız', 'Müfredat Eklenemedi.')
                    console.log(e)
                });
        }
    }


    const remove = (id: number) => {
        CurriculumService.remove(id)
            .then((response: any) => {
                openNotification('success', 'Başarılı', 'Müfredat Başarıyla Silindi.')
                getAll();
                setShowAddDrawer(false)
                form.resetFields();
            }).catch((e: Error) => {
                openNotification('error', 'Başarısız', 'Müfredat Silinemedi.')
                console.log(e)
            });
    }

    return (
        <>
            <Drawer title="Müfredat" placement="right" onClose={() => { setShowAddDrawer(false) }} open={showAddDrawer}>
                <Form form={form} onFinish={save} layout="vertical">
                    <Item
                        name="name"
                        label="Müfredat"
                        rules={[
                            {
                                required: true,
                                message: 'Müfredat adı zorunlu',
                            }]}
                    >
                        <Input />
                    </Item>
                    <Item
                        name="description"
                        label="Açıklama"
                    >
                        <TextArea />
                    </Item>
                    <Item>
                        <Row justify={'end'}>
                            <Button className='btn btn-hover-dark bg-cloud font-color-onyx btn-text mr-16' onClick={() => { setShowAddDrawer(false) }}>
                                Vazgeç
                            </Button>
                            <Button className='btn btn-hover-white bg-saha-purple font-color-white fw-700 btn-text' htmlType="submit">
                                Kaydet
                            </Button>
                        </Row>
                    </Item>
                </Form>
            </Drawer>
            <Card title="Müfredat Listesi" loading={loading}
                extra={<Row align={'middle'} justify={'space-between'}>
                    <Col>
                        <Button onClick={() => { setShowAddDrawer(true) }} className='btn btn-hover-dark bg-saha-purple font-color-white btn-text mr-16'>
                            Yeni Müfredat Ekle
                        </Button>
                    </Col>
                    <Col>
                        <Button onClick={() => navigate(-1)} className='btn btn-hover-dark bg-cloud font-color-onyx  btn-text'>
                            Geri
                        </Button>
                    </Col>
                </Row>}>
                <Row gutter={[32, 32]}>
                    {data?.map((item: any, index: number) => (
                        <Col span={8} key={index}>
                            <Card actions={[
                                <NavLink to={`/curriculums/${item.id}`}>
                                   <FileSearchOutlined />
                                </NavLink>,
                                <EditFilled key="edit" onClick={() => openEditModal(item)} />,
                                <Popconfirm
                                    title="Müfredatı Sil"
                                    description="Müfredatı silmek istediğinize emin misiniz?"
                                    onConfirm={() => { remove(item.id) }}
                                    okText="Evet"
                                    cancelText="Hayır"
                                >
                                    <DeleteFilled />
                                </Popconfirm>,
                            ]}>
                                <h3>{item.name}</h3>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Card>
        </>
    )
}

export default Curriculum