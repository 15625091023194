import { Button, Card, Col, Form, Input, Row } from 'antd'
import VideoService from '../../../services/VideoService';
import { openNotification } from '../../../utils';
import { useEffect, useState } from 'react';
import { IVideoRequest } from '../../../types/Video';
import _ from 'lodash';
import VideoList from './VideoList';
import VimeoPlayer from './VimeoPlayer';
import { duration } from 'moment';

const { Item } = Form;

interface VideoViewProps {
  id?: any;
  subjectId: any;
  getTrigger: any;
  setCurrentVideoId: any;
}

const VideoView: React.FC<VideoViewProps> = ({ id, subjectId, getTrigger, setCurrentVideoId }) => {
  const [form] = Form.useForm();
  const [video, setVideo] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(false)

  const update = (postObj: any) => {
    VideoService.update(id, postObj)
      .then((response: any) => {
        openNotification('success', 'Başarılı', 'Video Başarıyla Düzenlendi')
        setCurrentVideoId(null)
        getTrigger()
      }).catch((e: Error) => {
        openNotification('error', 'Başarısız', 'Video Düzenlenemedi.')
        console.log(e)
      });
  }

  const save = (values: any) => {
    let postObj: IVideoRequest = {
      "title": values.title,
      "description": values.description,
      "link": values.link,
      "vimeoId": values.vimeoId,
      "subjectId": subjectId,
      "duration": values.duration,
      "cover": values.cover,
    };

    if (id) {
      update(postObj);
    } else {
      create(postObj);
    }
  }

  const create = (postObj: any) => {

    VideoService.create(postObj)
      .then((response: any) => {
        openNotification('success', 'Başarılı', 'Video Başarıyla Oluşturuldu.')
        setCurrentVideoId(null)
        getTrigger()
      }).catch((e: Error) => {
        openNotification('error', 'Başarısız', 'Video oluşturulamadı.')
        console.log(e)
      });
  }

  useEffect(() => {
    if (id) {
      getVideo();
    }
  }, [id]);

  const getVideo = () => {
    setLoading(true)
    VideoService.get(id)
      .then((response: any) => {
        const data = response.data;
        setVideo(data?.vimeoId);
        form.setFieldsValue({
          title: data?.title,
          description: data?.description,
          link: data?.link,
          vimeoId: data?.vimeoId,
          duration: data?.duration,
          cover: data?.cover
        });
        setLoading(false)
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const setVideoId = (video: any) => {
    console.log('video', video);
    let videoId = video.uri.replace('/videos/', '');
    setVideo(videoId);
    form.setFieldsValue({ title: video.name, vimeoId: video.uri.replace('/videos/', ''), duration: video?.duration, cover: video?.pictures?.sizes[video?.pictures.sizes.length-1]?.link_with_play_button });
  };

  return (
    <>
      <Card className='border-radius-md'>
        <Row align={'middle'} justify={'space-between'}>
          <Col>
            <h3 className='fs-24'>Video</h3>
          </Col>
          <Col>
            <VideoList setVideoId={setVideoId} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {(video) && <VimeoPlayer videoId={video}></VimeoPlayer>}
          </Col>
        </Row>
        <Row justify={'center'} className='mt-24'>
          <Col xl={24} xs={24}>
            <Form form={form} onFinish={save} layout="vertical">
              <Item
                name="title"
                label="Başlık"
                rules={[
                  {
                    required: true,
                    message: 'Video adı zorunlu',
                  }]}
              >
                <Input readOnly />
              </Item>
              <Item
                name="vimeoId"
                style={{ display: 'none' }}
              >
                <Input />
              </Item>
              <Item
               style={{ display: 'none' }}
                name="cover"
              >
                <Input />
              </Item>
              <Item
                label="Süre (Saniye)"
                name="duration"
              >
                <Input readOnly />
              </Item>
              <Item>
                <Row justify={'end'}>
                  <Button className='btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text' htmlType="submit">
                    Kaydet
                  </Button>
                </Row>
              </Item>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default VideoView