import { Button, Card, Image, Col, Row, Select, Checkbox, Divider, Input, InputNumber } from 'antd'
import React, { useEffect, useState } from 'react'
import GradeService from '../../services/GradeService';
import QuestionService from '../../services/QuestionService';
import QuizService from '../../services/QuizService';
import { openNotification } from '../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import PackageService from '../../services/PackagesService';
import CourseService from '../../services/CourseService';


const QuizDetail = () => {
    const { id } = useParams()
    const [loading, setLoading] = useState<any>()
    const [quiz, setQuiz] = useState<any>()
    const [grades, setGrades] = useState<any[]>([]);
    const [packages, setPackages] = useState<any[]>([]);
    const [selectedGrade, setSelectedGrade] = useState<number | null>(null);
    const [selectedLesson, setSelectedLesson] = useState<any>(null);
    const [selectedUnit, setSelectedUnit] = useState<number | null>(null);
    const [selectedSubject, setSelectedSubject] = useState<number | null>(null);
    const [pdfUrls, setPdfUrls] = useState<{ [key: number]: string }>({});
    const { Option } = Select;

    const navigate = useNavigate()

    const [selectedQuestionIds, setSelectedQuestionIds] = useState<{ lessonId: number; questionId: number }[]>([]);
    const [title, setTitle] = useState<string>('');
    const [duration, setDuration] = useState<number>();
    const [selectedPackageIds, setSelectedPackageIds] = useState<number[]>([]);
    const [courseList, setCourseList] = useState<any>([]);
    const [selectedCourseList, setSelectedCourseList] = useState<any>([]);


    useEffect(() => {
        getGrades()
        getQuiz()
        getPackages()
        getCourseList();
    }, [])

    const getFile = (fileName: string, questionId: number) => {
        return false;
        QuestionService.getFile(fileName).then((response: any) => {
            const imageBlob = new Blob([response.data], { type: 'image/png' }); // Adjust type if not png
            const url = URL.createObjectURL(imageBlob);
            setPdfUrls(prev => ({ ...prev, [questionId]: url }));
        }).catch((e: Error) => {
            console.error(e);
        });
    };

    const getGrades = () => {
        GradeService.getAll()
            .then((response: any) => {
                const data = response.data;
                setGrades(data);
                setLoading(false);
            })
            .catch((e: Error) => {
                console.log(e);
                setLoading(false);
            });
    };

    const getPackages = () => {
        PackageService.getAll()
            .then((response: any) => {
                const data = response.data;
                setPackages(data);
                setLoading(false);
            })
            .catch((e: Error) => {
                console.log(e);
                setLoading(false);
            });
    };

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
    };

    const handleDurationChange = (value: number | null) => {
        setDuration(value ?? 0);
    };

    const getCourseList = () => {
        CourseService.getAll()
            .then((response: any) => {
                const data = response.data;
                setCourseList(data);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const getQuiz = () => {
        setLoading(true);
        QuizService.get(id)
            .then((response: any) => {
                const data = response.data;
                setQuiz(data);
                setTitle(data.title);
                setDuration(data.duration);
                setSelectedQuestionIds(data.questions);
                setSelectedPackageIds(data.packages.map((pkg: any) => pkg.id)); // Burada selectedPackageIds state'ini ayarlıyoruz
                setSelectedCourseList(data.courses.map((pkg: any) => pkg.id)); // Burada selectedPackageIds state'ini ayarlıyoruz
                data.questions.forEach((pair: any) => {
                    // QuestionService.get(pair.questionId).then((questionDetails: any) => {
                    //     getFile(questionDetails?.data?.question, pair.questionId);
                    // });
                });
                setLoading(false);
            })
            .catch((e: Error) => {
                console.log(e);
                setLoading(false);
            });
    };

    const updateQuiz = () => {
        if (!title.trim() || selectedQuestionIds.length === 0) {
            openNotification('error', 'Hata', 'Lütfen sınav adını girin ve en az bir soru seçin.');
            return;
        }

        const postObj = {
            title: title,
            questionLessonPairs: selectedQuestionIds,
            packageIds: selectedPackageIds,
            duration: duration,
            courseIds: selectedCourseList,
            // subjectId: id
        };

        setLoading(true);
        QuizService.update(id, postObj)
            .then((response: any) => {
                openNotification('success', 'Başarılı', 'Sınav Başarıyla Kaydedildi.');
                setTitle(''); // Sınav başarıyla oluşturulduktan sonra başlık sıfırlanır
                setSelectedQuestionIds([]); // Soru seçimleri sıfırlanır
                setSelectedPackageIds([]);
                navigate('/quiz');
                setLoading(false);
            })
            .catch((e: Error) => {
                console.error(e);
                setLoading(false);
            });
    };

    const handleGradeChange = (value: number) => {
        setSelectedGrade(value);
        setSelectedLesson(null); // Reset selected lesson when grade changes
        setSelectedUnit(null); // Reset selected unit when grade changes
        setSelectedSubject(null); // Reset selected subject when grade changes
        // No need to reset pdfUrls here
    };

    const handleLessonChange = (value: number) => {
        setSelectedLesson(value);
        setSelectedUnit(null); // Reset selected unit when lesson changes
        setSelectedSubject(null); // Reset selected subject when lesson changes
        // No need to reset pdfUrls here
    };

    const handleUnitChange = (value: number) => {
        setSelectedUnit(value);
        setSelectedSubject(null); // Reset selected subject when unit changes
        // No need to reset pdfUrls here
    };

    const handleSubjectChange = (value: number) => {
        setSelectedSubject(value);
        // No need to reset pdfUrls here
    };

    useEffect(() => {
        if (selectedSubject) {
            const subject = selectedUnitSubjects.find((subject: any) => subject.id === selectedSubject);
            if (subject) {
                subject.questions.forEach((question: any) => {
                    if (!pdfUrls[question.id]) {
                        // getFile(question.question, question.id);
                    }
                });
            }
        }
    }, [selectedSubject]);

    const selectedGradeLessons = grades.find((grade) => grade.id === selectedGrade)?.lessons || [];
    const selectedLessonUnits = selectedGradeLessons.find((lesson: any) => lesson.id === selectedLesson)?.units || [];
    const selectedUnitSubjects = selectedLessonUnits.find((unit: any) => unit.id === selectedUnit)?.subjects || [];
    const selectedSubjectQuestions = selectedUnitSubjects.find((subject: any) => subject.id === selectedSubject)?.questions || [];

    const handleCheckboxChange = (lessonId: number, questionId: number) => {
        setSelectedQuestionIds(prevSelectedIds => {
            const exists = prevSelectedIds.find(pair => pair.questionId === questionId);
            if (exists) {
                return prevSelectedIds.filter(pair => pair.questionId !== questionId);
            } else {
                return [...prevSelectedIds, { lessonId, questionId }];
            }
        });
    };

    const handleSelectChange = (value: number[]) => {
        setSelectedPackageIds(value);
    };

    const handleSelectCourseList = (value: number[]) => {
        setSelectedCourseList(value);
    };

    const deleteQuestion = (questionId: number) => {
        setSelectedQuestionIds(prevSelectedIds => prevSelectedIds.filter(pair => pair.questionId !== questionId));
    };

    return (
        <>
            <Card className='border-radius-md' loading={loading}>
                <Row justify={'space-between'} align={'middle'}>
                    <Col>
                        <h3 className='fs-24'>Sınav Düzenle</h3>
                    </Col>
                    <Col>
                        <Button className='btn btn-hover-dark bg-saha-purple font-color-white fw-700' onClick={updateQuiz}>
                            Kaydet
                        </Button>
                    </Col>
                </Row>
                <Row gutter={24} className='mt-24'>
                    <Col xs={24} md={12} lg={8}>
                        <p className='fs-12 fw-700 m-0'>Sınav Adı</p>
                        <Input value={title} onChange={handleTitleChange} placeholder="Sınavın Adını Giriniz" />
                    </Col>
                </Row>
                <Row gutter={24} className='mt-24'>
                    <Col xs={24} md={12} lg={4}>
                        <p className='fs-12 fw-700 m-0'>Sınav Süresi - dakika</p>
                        <InputNumber className='w-full' value={duration} onChange={handleDurationChange} placeholder="Sınav Süresi Giriniz" />
                    </Col>
                </Row>
                <Row gutter={24} className='mt-24'>
                    <Col span={24}>
                    <Select
                        mode="multiple"
                        className='w-full'
                        placeholder="Kurs Seçin"
                        onChange={handleSelectCourseList}
                        loading={loading}
                        value={selectedCourseList}
                    >
                        {courseList.map((pkg:any) => (
                            <Option key={pkg.id} value={pkg.id}>
                                {pkg.name}
                            </Option>
                        ))}
                    </Select>
                    </Col>
                </Row>
                <Row gutter={24} className='mt-24'>
                    <Col span={24}>
                        <Select
                            mode="multiple"
                            className='w-full'
                            placeholder="Paket Seçin"
                            onChange={handleSelectChange}
                            value={selectedPackageIds} // selectedPackageIds state'ini kullanıyoruz
                        >
                            {packages.map((pkg) => (
                                <Option key={pkg.id} value={pkg.id}>
                                    {pkg.name}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                </Row>
                <Row gutter={[24, 24]} className='mt-24'>
                    <Col xs={24} md={12} lg={4}>
                        <p className='fs-12 fw-700 m-0'>Sınıf</p>
                        <Select className='w-full' onChange={handleGradeChange}>
                            {grades?.map((grade: any) => (
                                <Option key={grade.id} value={grade.id}>
                                    {grade.name}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                    <Col xs={24} md={12} lg={4}>
                        <p className='fs-12 fw-700 m-0'>Ders</p>
                        <Select
                            className='w-full'
                            onChange={handleLessonChange}
                            value={selectedLesson}
                            disabled={!selectedGrade}
                        >
                            {selectedGradeLessons?.map((lesson: any) => (
                                <Option key={lesson.id} value={lesson.id}>
                                    {lesson.name}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                    <Col xs={24} md={12} lg={4}>
                        <p className='fs-12 fw-700 m-0'>Ünite</p>
                        <Select
                            className='w-full'
                            onChange={handleUnitChange}
                            value={selectedUnit}
                            disabled={!selectedLesson}
                        >
                            {selectedLessonUnits?.map((unit: any) => (
                                <Option key={unit.id} value={unit.id}>
                                    {unit.name}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                    <Col xs={24} md={12} lg={4}>
                        <p className='fs-12 fw-700 m-0'>Konu</p>
                        <Select className='w-full' onChange={handleSubjectChange} disabled={!selectedUnit} value={selectedSubject}>
                            {selectedUnitSubjects?.map((subject: any) => (
                                <Option key={subject.id} value={subject.id}>
                                    {subject.title}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                </Row>
            </Card>
            <Card className='mt-24 border-radius-md'>
                <h3 className='fs-20 mt-0'>
                    Soru Listesi
                </h3>
                <Divider className='mt-0'></Divider>
                <Row>
                    {selectedSubjectQuestions?.map((question: any, index: number) => (
                        <Col key={index} lg={8} md={12} xs={24}>
                            <Checkbox
                                className='saha-checkbox'
                                checked={selectedQuestionIds.some(pair => pair.questionId === question.id)}
                                onChange={() => handleCheckboxChange(selectedLesson, question.id)}
                            >
                                Soru {index + 1}: {question.text}
                            </Checkbox>
                            {pdfUrls[question.id] && <Image src={pdfUrls[question.id]} width="100%" />}
                        </Col>
                    ))}
                </Row>
            </Card>
            <Card className='mt-24 border-radius-md'>
                <h3 className='fs-20 mt-0'>
                    Seçilmiş Sorular
                </h3>
                <Divider className='mt-0'></Divider>
                <Row gutter={24}>
                    {selectedQuestionIds?.map((pair: any, index: number) => {
                        const question = selectedSubjectQuestions.find((sq: any) => sq.id === pair.questionId);
                        return (
                            <Col key={index} lg={8} md={12} xs={24} className="question-item">
                                <Row justify={'space-between'} align={'middle'}>
                                    <Col>
                                        Soru {index + 1}: {question?.text}
                                    </Col>
                                    <Col className='pr-24'>
                                        <DeleteOutlined
                                            onClick={() => deleteQuestion(pair.questionId)}
                                            style={{ marginLeft: '10px', color: 'red', cursor: 'pointer' }}
                                        />
                                    </Col>
                                </Row>
                                {pdfUrls[pair.questionId] && <Image src={pdfUrls[pair.questionId]} width="100%" />}
                            </Col>
                        );
                    })}
                </Row>
            </Card>
        </>
    )
}

export default QuizDetail