import { Button, Col, Drawer, Form, Input, Row, Upload, UploadFile, UploadProps } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import DocumentService from '../../../services/DocumentService';
import { openNotification } from '../../../utils';
import { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { IDocumentRequest } from '../../../types/Documents';

const { Item } = Form;

interface DocumentCreateProps {
  subjectId?: number;
  videoId?: number;
  getData: any;
}

const DocumentCreate: React.FC<DocumentCreateProps> = ({ subjectId, videoId, getData }) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const create = (values: any) => {
    setLoading(true);
  
    let postObj: any = {
      title: values.name,
      description: values.description,
    };
  
    if (subjectId) {
      postObj.subjectId = subjectId;
    }
  
    if (videoId) {
      postObj.videoId = videoId;
    }
  
    DocumentService.create(postObj, fileList[0])
      .then((response: any) => {
        getData();
        openNotification('success', 'Başarılı', 'Doküman Başarıyla Oluşturuldu.');
        setShowDrawer(false);
        setFileList([]);
        setPreviewUrl(null);
        form.resetFields();
      })
      .catch((e: Error) => {
        openNotification('error', 'Başarısız', 'Doküman oluşturulamadı.');
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const props: UploadProps = {
    onRemove: (file) => {
      setFileList([]);
      setPreviewUrl(null);
    },
    beforeUpload: (file) => {
      if (fileList.length >= 1) {
        openNotification('warning', 'Uyarı', 'Sadece bir adet dosya yükleyebilirsiniz');
        return Upload.LIST_IGNORE;
      }
      setFileList([file]);
      setPreviewUrl(URL.createObjectURL(file));
      return false;
    },
    fileList,
    defaultFileList: fileList,
    accept: '.pdf',
    multiple: false,
  };

  return (
    <>
      <Button className='btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text' onClick={() => setShowDrawer(true)}>
        Doküman Ekle
      </Button>
      <Drawer title="Yeni Doküman Oluştur" placement="right" onClose={() => setShowDrawer(false)} open={showDrawer}>
        <Row justify={'center'}>
          <Col xl={24} xs={24}>
            <Form form={form} onFinish={create} layout="vertical" initialValues={{ description: '' }}>
              <Item
                name="name"
                label="Başlık"
                rules={[{ required: true, message: 'Doküman adı zorunlu' }]}
              >
                <Input />
              </Item>
              <Item
                name="description"
                label="Açıklama"
              >
                <TextArea defaultValue={''} />
              </Item>
              <Item
                name="url"
                label="Doküman"
              >
                <Upload {...props}>
                  <Button icon={<UploadOutlined />}>Yükle</Button>
                </Upload>
                {previewUrl && (
                  <object
                    data={previewUrl}
                    type="application/pdf"
                    width="100%"
                    height="500px"
                    style={{ marginTop: '16px' }}
                  >
                    <p>PDF önizlemeniz görüntülenemiyor. <a href={previewUrl}>PDF'yi indir</a></p>
                  </object>
                )}
              </Item>
              <Item>
                <Row justify={'end'}>
                  <Button className='btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text' htmlType="submit" loading={loading}>
                    Kaydet
                  </Button>
                </Row>
              </Item>
            </Form>
          </Col>
        </Row>
      </Drawer>
    </>
  );
}

export default DocumentCreate;
