import { Card, Col, Progress, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, NavLink, useParams } from 'react-router-dom';
import VideoHistoryService from '../../services/VideoHistoryService';

function VideoHistory() {

    const [loading, setLoading] = useState<any>(false)
    const [data, setData] = useState([])
    const { id }: any = useParams();

    useEffect(() => {
        getAllVideoHistoryMe();
    }, [])


    const getAllVideoHistoryMe = () => {
        setLoading(true);
        VideoHistoryService.get(id)
            .then((response: any) => {
                const data = response.data;
                setLoading(false);
                setData(data)
            })
            .catch((e: Error) => {
                console.log(e);
                setLoading(false);
            });
    };
    return (
        <Card loading={loading} className='mt-24 bg-cloud-light'>
            <h3 className='mt-0 font-color-saha-dark fs-18'>Öğrenmeye Devam Edin</h3>
            <Row gutter={[24, 24]}>
                {data.map((video: any, index: number) => (
                    (index < 3) && <Col span={8}>
                            <Card
                                bodyStyle={{ padding: 0 }}
                                cover={
                                    <img
                                        alt="example"
                                        src={video?.videoCover}
                                    />
                                }
                            >
                                <div style={{ position: 'absolute', bottom: 8, right: 8, color: 'white', background: 'rgba(0, 0, 0, 0.6)', padding: '2px 6px', borderRadius: 4 }}>
                                    {Math.floor(video?.videoDuration / 60)}:{(video?.videoDuration % 60).toString().padStart(2, '0')}
                                </div>
                                <Progress
                                    strokeColor={'#8655f7'}
                                    percent={Math.ceil((video?.duration / video?.videoDuration) * 100)}
                                    size="small"
                                    showInfo={false}
                                    style={{ marginTop: -4 }}
                                />
                            </Card>
                    </Col>
                ))}
            </Row>
        </Card>
    )
}

export default VideoHistory