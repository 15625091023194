import { http } from "../http-common";
import { ILiveLessonRequest, ILiveLessonResponse } from "../types/LiveLessons";
import { openNotification } from "../utils";
let permissions = localStorage.getItem('permissions')

function isAuthorized(requiredPermission: any) {
    return permissions?.includes(requiredPermission);
}

const getAll = (data: any) => {
     if (isAuthorized('livelesson.list')) {
        return http.get<ILiveLessonResponse>(`/live-lessons/all${data}`);
    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const getAllWithPagination = (data: any) => {
    if (isAuthorized('livelesson.list')) {
        return http.get<ILiveLessonResponse>(`/live-lessons${data}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

// const create = (data: ILiveLessonRequest) => {
//     return http.post<ILiveLessonRequest>(`/lesson`, data);
// };

const get = (id: number) => {
    if (isAuthorized('livelesson.show')) {
        return http.get<ILiveLessonResponse>(`/live-lessons/${id}`);
    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const update = (id: number, data: ILiveLessonRequest) => {
    if (isAuthorized('livelesson.update')) {
        return http.patch<ILiveLessonRequest>(`/live-lessons/${id}`, data);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const remove = (id: number) => {
    if (isAuthorized('livelesson.delete')) {
        return http.delete<ILiveLessonRequest>(`/live-lessons/${id}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const LessonService = {
    // create,
    getAll,
    get,
    update,
    remove
}

export default LessonService;