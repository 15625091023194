import React, { useEffect, useState } from 'react'
import ApplicationService from '../services/ApplicationService';
import ApplicationItemCard from '../components/Applications/ApplicationItemCard';
import { Button, Card, Col, Row, Table, Tag } from 'antd';
import { NavLink } from 'react-router-dom';
import { formatDate } from '../utils';

const Applications = () => {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState<boolean>()

  useEffect(() => {
    getApplications()
  }, []);

  const getApplications = () => {
    setLoading(true);
    ApplicationService.getAll()
      .then((response: any) => {
        const data = response.data;
        setApplications(data);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
        setLoading(false);
      });
  };

  const renderStatusType = (status: string) => {
    switch (status) {
      case 'PENDING':
        return <Tag color="orange">Beklemede</Tag>;
      case 'ACCEPT':
        return <Tag color="green">Kabul Edildi</Tag>;
      case 'REJECT':
        return <Tag color="red">Reddedildi</Tag>;
      default:
        return <Tag color="gray">Bilinmiyor</Tag>;
    }
  };

  const columns = [
    {
      title: 'İsim',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text: string, record: any) => `${record.firstName} ${record.lastName}`,
    },
    {
      title: 'Özet Bilgi',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Başvuru Tarihi',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: any) => (
        <>
          {formatDate(createdAt)}
        </>
      ),
    },
    {
      title: 'Başvuru Durumu',
      dataIndex: 'statusType',
      key: 'statusType',
      render: renderStatusType,
    },
    {
      title: '#',
      key: 'action',
      render: (text: any, record: any) => (
        <NavLink to={`/applications/${record.id}`}>
          <Button className='fw-700 font-color-white btn btn-hover-dark bg-saha-purple'>
            Detay
          </Button>
        </NavLink>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={applications}
      loading={loading}
      className='bg-cloud antd-card-body-p-0'
    />
  )
}

export default Applications