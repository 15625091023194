import { http } from "../http-common";


const liveLessonGraph = (data: any) => {
    return http.get<any>(`/live-lessons/status-count${data}`);
};
const stats = () => {
    return http.get<any>(`/dashboard/all-stats`);
};


const DashboardService = {
    liveLessonGraph,
    stats
};

export default DashboardService;