import { Card, Table, Form, Button, Drawer, Input, DatePicker, Popconfirm, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AssessmentExamService from '../services/AssessmentExamService';
import TextArea from 'antd/es/input/TextArea';
import { openNotification } from '../utils';
import moment from 'moment';
import { ICountdownRequest } from '../types/Countdown';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const { Item } = Form;

const AssessmentExamList = () => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false); // Yeni loading state
  const [exams, setExams] = useState([]);
  const [editingExam, setEditingExam] = useState<any>(null);
  let navigate = useNavigate();

  useEffect(() => {
    getExams();
  }, []);

  const getExams = () => {
    setLoading(true);
    AssessmentExamService.getAll()
      .then((response: any) => {
        const data = response.data;
        setExams(data);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
        setLoading(false);
      });
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setEditingExam(null);
    form.resetFields();
  };

  useEffect(() => {
    if (editingExam) {
      form.setFieldsValue({
        name: editingExam.name,
        description: editingExam.description,
        examDate: dayjs(editingExam.examDate), // dayjs nesnesi olarak ayarladık
      });
    }
  }, [editingExam, form]);

  const update = (values: any) => {
    setButtonLoading(true); // Loading state'i aktif et
    let postObj: ICountdownRequest = {
      name: values.name,
      description: values.description,
      examDate: values.examDate.format("YYYY-MM-DDTHH:mm:ss"), // Formatı güncelledik
    };

    if (editingExam) {
      // Update existing exam
      AssessmentExamService.update(editingExam.id, postObj)
        .then((response: any) => {
          openNotification('success', 'Başarılı', 'Sınav Başarıyla Güncellendi.');
          getExams();
          onClose();
        })
        .catch((e: Error) => {
          openNotification('error', 'Başarısız', 'Sınav Güncellenemedi.');
          console.log(e);
        })
        .finally(() => {
          setButtonLoading(false); // Loading state'i devre dışı bırak
        });
    } else {
      // Create new exam
      AssessmentExamService.create(postObj)
        .then((response: any) => {
          openNotification('success', 'Başarılı', 'Sınav Başarıyla Eklendi.');
          getExams();
          onClose();
        })
        .catch((e: Error) => {
          openNotification('error', 'Başarısız', 'Sınav Eklenemedi.');
          console.log(e);
        })
        .finally(() => {
          setButtonLoading(false); // Loading state'i devre dışı bırak
        });
    }
  };

  const editExam = (exam: any) => {
    setEditingExam(exam);
    showDrawer();
  };

  const deleteExam = (id: number) => {
    setLoading(true);
    AssessmentExamService.remove(id)
      .then((response: any) => {
        openNotification('success', 'Başarılı', 'Sınav Başarıyla Silindi.');
        getExams();
      })
      .catch((e: Error) => {
        openNotification('error', 'Başarısız', 'Sınav Silinemedi.');
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      title: 'Sınav Adı',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Açıklama',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Sınav Tarihi',
      dataIndex: 'examDate',
      key: 'examDate',
      render: (text: string) => dayjs(text).format('DD/MM/YYYY HH:mm'), // Yerel zaman formatında göster
    },
    {
      title: 'İşlemler',
      key: 'actions',
      render: (_: any, record: any) => (
        <>
          <Button type="link" onClick={() => editExam(record)}>
            <EditOutlined />
          </Button>
          <Popconfirm
            title="Sınavı silmek istediğinizden emin misiniz?"
            onConfirm={() => deleteExam(record.id)}
            okText="Evet"
            cancelText="Hayır"
          >
            <Button type="link" danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <Card
        loading={loading}
        title="Sınav Listesi"
        extra={
          <div>
            <Button type="primary" onClick={showDrawer}>
              Yeni Sınav Ekle
            </Button>
            <Button onClick={() => navigate(-1)} className="ml-12">
              Geri
            </Button>
          </div>
        }
      >
        <Table columns={columns} dataSource={exams} rowKey="id" />
      </Card>
      <Drawer title={editingExam ? "Sınavı Düzenle" : "Sınav Ekle"} placement="right" onClose={onClose} open={open}>
        <Form form={form} onFinish={update} layout="vertical">
          <Item
            name="name"
            label="Sınav"
            rules={[
              {
                required: true,
                message: 'Sınav adı zorunlu',
              },
            ]}
          >
            <Input />
          </Item>
          <Item name="description" label="Açıklama">
            <TextArea />
          </Item>
          <Item
            name="examDate"
            label="Sınav Tarihi"
            rules={[
              {
                required: true,
                message: 'Sınav Tarihi zorunlu',
              },
            ]}
          >
            <DatePicker
              className="w-full"
              format="DD/MM/YYYY HH:mm"
              showTime={{ defaultValue: dayjs('00:00', 'HH:mm'), minuteStep: 5 }}
            />
          </Item>
          <Item>
            <Row justify={"end"} align={"middle"}>
              <Button onClick={onClose} className='mr-8'>
                Vazgeç
              </Button>
              <Button type="primary" htmlType="submit" loading={buttonLoading}>
                {editingExam ? "Güncelle" : "Kaydet"}
              </Button>
            </Row>
          </Item>
        </Form>
      </Drawer>
    </>
  );
};

export default AssessmentExamList;
