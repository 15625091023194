import {http} from "../http-common";
import { IPackageRequest, IPackageResponse } from "../types/Package";
import { isAuthorized, openNotification } from "../utils";

const getPurchasedCourseList = () => {
        return http.get<any[]>(`/students/course/purchased`);
};

const getAll = () => {
        return http.get<IPackageResponse>(`/course`);
};

const create = (data: any) => {
        return http.post<any>(`/course`, data);
};

const get = (id: number) => {
        return http.get<IPackageResponse>(`/course/${id}`);
};

const update = (id: number, data: any) => {
    return http.patch<any>(`/course/${id}`, data);
};

const remove = (id: number) => {
        return http.delete<IPackageRequest>(`/course/${id}`);
};

const CourseService = {
    create,
    getAll,
    get,
    update,
    remove,
    getPurchasedCourseList
}

export default CourseService;