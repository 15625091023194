import {http} from "../http-common";
import { IVideoRequest, IVideoResponse } from "../types/Video";
import { isAuthorized, openNotification } from "../utils";

const getAll = () => {
    if (isAuthorized('video.list')) {
        return http.get<IVideoResponse>(`/video`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const create = (data: IVideoRequest) => {
    if (isAuthorized('video.create')) {
        return http.post<IVideoRequest>(`/video`, data);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const get = (id: number) => {
    if (isAuthorized('video.show')) {
        return http.get<IVideoResponse>(`/video/${id}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const getVimeoList = () => {
    if (isAuthorized('video.show')) {
        return http.get<IVideoResponse>(`/vimeo/videos`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const update = (id: number, data: IVideoRequest) => {
    if (isAuthorized('video.update')) {
        return http.patch<IVideoRequest>(`/video/${id}`, data);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const updateSortOrders = (data: any) => {
    if (isAuthorized('video.update')) {
        return http.put<any>(`/video/update-sort-orders`, data);
    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const remove = (id: number) => {
    if (isAuthorized('video.delete')) {
        return http.delete<IVideoRequest>(`/video/${id}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const VideoService = {
    create,
    getAll,
    get,
    getVimeoList,
    update,
    remove,
    updateSortOrders
}

export default VideoService;