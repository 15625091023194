import { useEffect, useState } from 'react'
import ApplicationService from '../services/ApplicationService';
import { useParams } from 'react-router-dom';
import { Card, Col, Row, Select } from 'antd';
import { formatDate, openNotification } from '../utils';
import AuthService from '../services/AuthService';
import { Descriptions } from 'antd';
import type { DescriptionsProps } from 'antd';

const { Option } = Select;

const ApplicationDetail = () => {
    const { id } = useParams()
    const [application, setApplication] = useState<any>();
    const [loading, setLoading] = useState<boolean>()
    const [pdfUrl, setPdfUrl] = useState<string>('');
    const [pdfUrlDiploma, setPdfUrlDiploma] = useState<string>('');

    useEffect(() => {
        getApplications()
    }, []);

    const getApplications = () => {
        setLoading(true);
        ApplicationService.get(id)
            .then((response: any) => {
                const data = response.data;
                setApplication(data);
                if (data.resume) {
                    getResume(data.resume);
                }
                if (data.diploma) {
                    getDiploma(data.diploma);
                }
                setLoading(false);
            })
            .catch((e: Error) => {
                console.log(e);
                setLoading(false);
            });
    };


    const getResume = (fileName: string) => {
        ApplicationService.getResume(fileName)
            .then((response: any) => {
                // API'den gelen veriyi kullanarak Blob nesnesini oluşturun
                const blob = new Blob([response.data], { type: response.headers['content-type'] });

                // PDF URL'sini ayarlayın
                const url = URL.createObjectURL(blob);
                setPdfUrl(url);
            })
            .catch((e: Error) => {
                console.error(e);
            });
    };

    const getDiploma = (fileName: string) => {
        ApplicationService.getResume(fileName)
            .then((response: any) => {
                // API'den gelen veriyi kullanarak Blob nesnesini oluşturun
                const blob = new Blob([response.data], { type: response.headers['content-type'] });

                // PDF URL'sini ayarlayın
                const url = URL.createObjectURL(blob);
                setPdfUrlDiploma(url);
            })
            .catch((e: Error) => {
                console.error(e);
            });
    };

    const handleStatusChange = (value: string) => {

        let postObj = {
            statusType: value
        }

        ApplicationService.updateStatus(id, postObj)
            .then(() => {
                openNotification('success', 'Başarılı', 'Başvuru durumu başarıyla güncellendi.');
                setApplication({ ...application, statusType: value });
                if (value === 'ACCEPT') {
                    accountCreate();
                }
            })
            .catch((error: any) => {
                console.error(error);
                openNotification('error', 'Başarısız', 'Başvuru durumu güncellenirken bir hata oluştu.');
            });
    };

    const accountCreate = () => {

        let accountPostObj = {
            "email": application?.email,
            "password": "saha123!",
            "firstName": application?.firstName,
            "lastName": application?.lastName
        };

        AuthService.accountRegister(accountPostObj)
            .then((response: any) => {
                openNotification('success', 'Başarılı', 'Öğretmen eklendi');
                setRole(response.data);
            })
            .catch((error: any) => {
                openNotification('error', 'Başarısız', 'Başvuru durumu güncellenirken bir hata oluştu.');
            });
    };


    const setRole = (id: any) => {

        let postObj = {
            "roleId": 2
        };

        AuthService.setRoleUser(id, postObj)
            .then(() => {
                openNotification('success', 'Başarılı', 'Öğretmen eklendi');
            })
            .catch((error: any) => {
                openNotification('error', 'Başarısız', 'Başvuru durumu güncellenirken bir hata oluştu.');
            });
    };

    const items: DescriptionsProps['items'] = [
        {
            key: '3',
            label: 'E-Posta',
            children: application?.email,
        },
        {
            key: '1',
            label: 'Telefon',
            children: application?.phoneNumber,
        },
        {
            key: '2',
            label: 'Başvuru Tarihi',
            children: formatDate(application?.createdAt),
        },
        {
            key: '4',
            label: 'Açıklama',
            children: application?.description,
        },
    ];


    return (
        <>
            <Card loading={loading} className='border-radius-md'>
                <Row justify={"space-between"} gutter={[24, 24]}>
                    <Col xl={24}>
                        <Descriptions extra={<Select
                            placeholder="Başvuru Durumu"
                            value={application?.statusType}
                            onChange={handleStatusChange}
                            style={{ width: 200 }}
                        >
                            <Option value="PENDING">BEKLEMEDE</Option>
                            <Option value="ACCEPT">KABUL EDİLDİ</Option>
                            <Option value="REJECT">REDDEDİLDİ</Option>
                        </Select>} 
                        title={`${application?.firstName} ${application?.lastName}`} items={items} />
                    </Col>
                </Row>
                <Row gutter={[24, 24]}>
                    <Col lg={12}>
                        <h3 className='mb-0'>CV:</h3>
                        {pdfUrl && (
                            <iframe title='Resume' src={pdfUrl} width="100%" height="600px" />
                        )}
                    </Col>
                    <Col lg={12}>
                        <h3 className='mb-0'>Diploma:</h3>
                        {pdfUrl && (
                            <iframe title='Diploma' src={pdfUrlDiploma} width="100%" height="600px" />
                        )}
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default ApplicationDetail