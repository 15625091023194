import { Button, Card, Col, Form, Input, Row } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import QuizService from '../../../services/QuizService';
import { openNotification } from '../../../utils';
import { useEffect, useState } from 'react';

const { Item } = Form;

interface QuizViewProps {
  quizId: number;
  getTrigger: any;
  setCurrentQuizId: any;
}

const QuizEdit: React.FC<QuizViewProps> = ({ quizId, getTrigger, setCurrentQuizId }) => {
  const [subjectId, setSubjectId] = useState<number>()
  const [loading, setLoading] = useState<boolean>(false)
  const [link, setLink] = useState<any>("");
  const [form] = Form.useForm();

  useEffect(() => {
    if (quizId) {
      getService();
    }
  }, [quizId]);

  const getService = () => {
    setLoading(true)
    QuizService.get(quizId)
      .then((response: any) => {
        const data = response.data
        setSubjectId(data?.subjectId)
        form.setFieldsValue({
          name: data?.title,
          description: data?.description,
        });
        setLink(data?.link);
        setLoading(false)
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const update = (values: any) => {
    let postObj = {
      "title": values.name,
      "description": values.description,
      "subjectId": subjectId
    };

    QuizService.update(quizId, postObj)
      .then((response: any) => {
        openNotification('success', 'Başarılı', 'Quiz Başarıyla Düzenlendi')
        setCurrentQuizId(null)
        getTrigger()
      }).catch((e: Error) => {
        openNotification('error', 'Başarısız', 'Quiz Düzenlenemedi.')
        console.log(e)
      });
  }

  return (
    <>
      <Card className='border-radius-md'>
        <Row justify={'space-between'} align={'middle'}>
          <Col><h3 className='fs-32'>Quiz Düzenle</h3></Col>
          <Col><p className='fs-12'>Quiz Ayarları</p></Col>
        </Row>
        <Row justify={'center'}>
          <Col xl={24} xs={24}>
            <Form form={form} onFinish={update} layout="vertical">
              <Item
                name="name"
                label="Başlık"
                rules={[
                  {
                    required: true,
                    message: 'Quiz adı zorunlu',
                  }]}
              >
                <Input />
              </Item>
              <Item
                name="description"
                label="Açıklama"
              >
                <TextArea />
              </Item>
              <Item>
                <Row justify={'end'}>
                  <Button className='btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text' htmlType="submit">
                    Kaydet
                  </Button>
                </Row>
              </Item>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default QuizEdit