import { http } from "../http-common";
import { isAuthorized, openNotification } from "../utils";

const getAll = (data: any) => {

  if (isAuthorized('assignment.list')) {
    return http.get<any>(`/assignments${data}`);

  } else {
    openNotification('error', 'Başarısız', 'Yetkiniz yok.');
    return Promise.resolve(null);
  }
};

const get = (id: number) => {

  if (isAuthorized('assignment.show')) {
    return http.get<any>(`/assignments/${id}`);

  } else {
    openNotification('error', 'Başarısız', 'Yetkiniz yok.');
    return Promise.resolve(null);
  }
};

const create = (data: any, file: any) => {
  const formData = new FormData();
  formData.append('file', file);

  const config = {
    headers: {
    },
    params: {
      studentId: data.studentId,
      lessonId: data.lessonId,
      userId: data.userId,
      dueDate: data.dueDate,
      content: data.content
    }
  };


  if (isAuthorized('assignment.create')) {
    return http.post('/assignments', formData, config);

  } else {
    openNotification('error', 'Başarısız', 'Yetkiniz yok.');
    return Promise.resolve(null);
  }
};


const update = (id: number, data: any) => {
  const formData = new FormData();
  formData.append('isDone', data.isDone);

  if (isAuthorized('assignment.update')) {
    return http.patch<any>(`/assignments/${id}`, formData);

  } else {
    openNotification('error', 'Başarısız', 'Yetkiniz yok.');
    return Promise.resolve(null);
  }
};

const getFile = (fileName: any) => {
  return http.get<any>(`/assignments/download?file=${fileName}`, { responseType: 'arraybuffer' });
};

const AssignmentService = {
  create,
  getAll,
  update,
  get,
  getFile
}

export default AssignmentService;