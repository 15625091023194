import { Button, Col, Divider, Drawer, Form, Input, Modal, Row } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { useEffect, useState } from 'react'
import { openNotification } from '../../../utils';
import LessonService from '../../../services/LessonService';
import GradeService from '../../../services/GradeService';
import { EditOutlined } from '@ant-design/icons'

interface LessonUpdateProps {
    lessonId: number;
    getTrigger: any;
}

const { Item } = Form;

const LessonUpdate: React.FC<LessonUpdateProps> = ({ lessonId, getTrigger }) => {
    const [grades, setGrades] = useState<any>([]);
    const [gradeId, setGradeId] = useState<any>([]);
    const [form] = Form.useForm();
    const [showDrawer, setShowDrawer] = useState(false);


    useEffect(() => {
        if (showDrawer) { // Check if modal is shown and data has not been fetched
            getService();
        }
    }, [showDrawer]);

    const getGrades = () => {
        GradeService.getAll()
            .then((response: any) => {
                const data = response.data
                const options = data.map((grade: { id: number; name: string; }) => ({
                    label: grade.name, // Kullanıcıya gösterilecek metin
                    value: grade.id, // Form üzerinden gönderilecek değer
                }));
                setGrades(options);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const getService = () => {
        LessonService.get(lessonId)
            .then((response: any) => {
                const data = response.data
                setGradeId(data?.gradeId)
                form.setFieldsValue({
                    name: data?.name,
                    description: data?.description,
                });
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const handleLessonCreateCancel = () => {
        setShowDrawer(false);
    };

    useEffect(() => {
        form.resetFields();
    }, [showDrawer])

    const update = (values: any) => {
        let postObj = {
            "name": values.name,
            "description": values.description,
            "gradeIds": gradeId
        };

        LessonService.update(lessonId, postObj)
            .then((response: any) => {
                openNotification('success', 'Başarılı', 'Ders Başarıyla Düzenlendi.')
                setShowDrawer(false)
                getTrigger();
            }).catch((e: Error) => {
                openNotification('error', 'Başarısız', 'Ders Düzenlenemedi.')
                console.log(e)
            });
    }

    return (
        <>
            <Button onClick={() => setShowDrawer(true)}>
            <EditOutlined />
            </Button>
            <Drawer title="Dersi Düzenle" placement="right" onClose={() => setShowDrawer(false)} open={showDrawer}>
                <Form form={form} onFinish={update} layout="vertical">
                    <Item
                        name="name"
                        label="Ders"
                        rules={[
                            {
                                required: true,
                                message: 'Ders adı zorunlu',
                            }]}
                    >
                        <Input />
                    </Item>
                    <Item
                        name="description"
                        label="Açıklama"
                    >
                        <TextArea />
                    </Item>
                    <Divider></Divider>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Button onClick={handleLessonCreateCancel} className='w-full height-40'>
                                İptal
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Item>
                                <Button htmlType="submit" className='w-full height-40 btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text'>
                                    Kaydet
                                </Button>
                            </Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    )
}

export default LessonUpdate