import { http } from "../http-common";
import { isAuthorized, openNotification } from "../utils";

const getAll = () => {
  return http.get<any>(`/applications`);

  if (isAuthorized('applications.list')) {
    return http.get<any>(`/applications`);

  } else {
    openNotification('error', 'Başarısız', 'Yetkiniz yok.');
    return Promise.resolve(null);
  }
};

const get = (id: any) => {
  return http.get<any>(`/applications/${id}`);

  if (isAuthorized('applications.show')) {
    return http.get<any>(`/applications/${id}`);

  } else {
    openNotification('error', 'Başarısız', 'Yetkiniz yok.');
    return Promise.resolve(null);
  }
};

const updateStatus = (id: any, data: any) => {
  return http.put<any>(`/applications/${id}/status`, data);

};

const getResume = (fileName: any) => {
  return http.get<any>(`/applications/download?file=${fileName}`, { responseType: 'arraybuffer' });
};

const getDiploma = (fileName: any) => {
    return http.get<any>(`/applications/download?file=${fileName}`, { responseType: 'arraybuffer' });
  };

const ApplicationService = {
  getAll,
  updateStatus,
  get,
  getResume,
  getDiploma
}

export default ApplicationService;