import { Button, Card, Col, Form, Radio, Row, Select, Upload, Image, Input } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import QuestionService from '../../../services/QuestionService';
import { openNotification } from '../../../utils';
import { useState } from 'react';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import type { GetProp, UploadFile, UploadProps } from 'antd';
import { IQuestionRequest } from '../../../types/Question';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const { Item } = Form;

interface QuestionViewProps {
  subjectId: any;
  getTrigger: any;
  setCurrentQuestionId: any;
}

const QuestionCreate: React.FC<QuestionViewProps> = ({ subjectId, getTrigger, setCurrentQuestionId }) => {
  const [form] = Form.useForm();
  // const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileList1, setFileList1] = useState<UploadFile[]>([]);

  //

  const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });


  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Yükle</div>
    </button>
  );

  //

  const update = (values: any) => {

    let postObj: IQuestionRequest = {
      // "question": values.question,
      "correctAnswer": values.correctAnswer,
      "answerType": values.answerType,
      "vimeoId": values.vimeoId,
      "subjectId": subjectId, //Buraya Bak tekrar
    };

    QuestionService.create(postObj, fileList[0])
      .then((response: any) => {
        openNotification('success', 'Başarılı', 'Soru Başarıyla Oluşturuldu.')
        setCurrentQuestionId(null)
        getTrigger()
      }).catch((e: Error) => {
        openNotification('error', 'Başarısız', 'Soru oluşturulamadı.')
        console.log(e)
      });
  }

  const props: UploadProps = {
    onPreview: async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as FileType);
      }

      setPreviewImage(file.url || (file.preview as string));
      setPreviewOpen(true);
    },
    onRemove: (file) => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      if (fileList.length >= 1) {
        openNotification('warning', 'Uyarı', 'Sadece bir adet dosya yükleyebilirsiniz')
        // If there's already a file in the list, reject the new file
        return Upload.LIST_IGNORE;
      }
      // If not, add the new file to the list
      setFileList([file]);
      return false;
    },
    fileList,
    defaultFileList: fileList, // Show the initial file list
    accept: '.jpg,.jpeg,.png', 
    multiple: false, // Disable multiple file selection
  };

  return (
    <>
      <Card className='border-radius-md'>
        <Row justify={'space-between'} align={'middle'}>
          <Col><h3 className='fs-24'>Soru Oluştur</h3></Col>
        </Row>
        <Row justify={'center'}>
          <Col span={24}>
            <Form form={form} onFinish={update} layout="vertical">
              <Item
                name="file"
                label="Soru"
              >
                <Upload {...props} listType="picture-card"
                >
                  {fileList.length >= 1 ? null : uploadButton}
                </Upload>
                {previewImage && (
                  <Image
                    wrapperStyle={{ display: 'none' }}
                    preview={{
                      visible: previewOpen,
                      onVisibleChange: (visible) => setPreviewOpen(visible),
                      afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                  />
                )}
              </Item>
              <Item
                name="answerType"
                label="Soru Tipi"
                rules={[
                  {
                    required: true,
                    message: 'Soru Tipi zorunlu',
                  }]}
              >
                <Radio.Group >
                  <Radio value={'2'}>Doğru/Yanlış</Radio>
                  <Radio value={'4'}>4 Cevaplı</Radio>
                  <Radio value={'5'}>5 Cevaplı</Radio>
                </Radio.Group>
              </Item>
              <Item
                name="correctAnswer"
                label="Doğru Cevap"
                rules={[
                  {
                    required: true,
                    message: 'Doğru cevap zorunlu',
                  }]}
              >
                <Select options={
                  [
                    { value: "A", label: "Doğru - A" },
                    { value: "B", label: "Yanlış - B" },
                    { value: "C", label: "C" },
                    { value: "D", label: "D" },
                    { value: "E", label: "E" },
                  ]
                } />
              </Item>
              <Item
                name="videoUrl"
                label="Video linki"
                rules={[
                  {
                    required: true,
                    message: 'Video Linki zorunlu',
                  }]}
              >
                <Input />
              </Item>
              <Item>
                <Row justify={'end'}>
                  <Button className='btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text' htmlType="submit">
                    Kaydet
                  </Button>
                </Row>
              </Item>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default QuestionCreate