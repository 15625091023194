import { Card, Col, Row } from 'antd'
import { ReactComponent as PackagesIcon } from '../../assets/Dashboard/total-packages.svg'

const TotalPackages = (data:any) => {
    return (
        <div>
            <Card className='border-radius-md'>
                <Row gutter={24} align={'middle'}>
                    <Col >
                        <div className='d-flex p-16 bg-cloud border-radius-lg'>
                            <PackagesIcon />
                        </div>
                    </Col>
                    <Col span={18}>
                        <p className='m-0 font-color-gray fs-14 fw-500'>Toplam Paket</p>
                        <h3 className='m-0 font-color-dark-blue fs-24 fw-700'>{data?.data?.totalPackages ?? '0'}</h3>
                        <Row gutter={4}>
                            <Col>
                                <p className='m-0 font-color-success fs-12 fw-400'>+{data?.data?.last30days ?? '0'}</p>
                            </Col>
                            <Col>
                                <p className='m-0 font-color-gray fs-12 fw-400'>yeni paket eklendi</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export default TotalPackages