import { Button, Card, Col, Form, Input, Row, Upload, UploadFile, UploadProps } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import DocumentService from '../../../services/DocumentService';
import { openNotification } from '../../../utils';
import { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { IDocumentRequest } from '../../../types/Documents';

const { Item } = Form;

interface DocumentViewProps {
  subjectId: any;
  getTrigger: any;
  setCurrentDocumentId: any;
}

const DocumentCreate: React.FC<DocumentViewProps> = ({ subjectId, getTrigger, setCurrentDocumentId }) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const update = (values: any) => {

    let postObj: IDocumentRequest = {
      "title": values.name,
      "description": values.description,
      "subjectId": subjectId, //Buraya Bak tekrar
    };

    DocumentService.create(postObj, fileList[0])
      .then((response: any) => {
        openNotification('success', 'Başarılı', 'Doküman Başarıyla Oluşturuldu.')
        setCurrentDocumentId(null)
        getTrigger()
      }).catch((e: Error) => {
        openNotification('error', 'Başarısız', 'Doküman oluşturulamadı.')
        console.log(e)
      });
  }

  const props: UploadProps = {
    onRemove: (file) => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      if (fileList.length >= 1) {
        openNotification('warning', 'Uyarı', 'Sadece bir adet dosya yükleyebilirsiniz')
        // If there's already a file in the list, reject the new file
        return Upload.LIST_IGNORE;
      }
      // If not, add the new file to the list
      setFileList([file]);
      return false;
    },
    fileList,
    defaultFileList: fileList, // Show the initial file list
    accept: '.pdf', // Accept only PDF files
    multiple: false, // Disable multiple file selection
  };

  return (
    <>
      <Card className='border-radius-md'>
        <Row justify={'space-between'} align={'middle'}>
          <Col><h3 className='fs-32'>Doküman Ekle</h3></Col>
        </Row>
        <Row justify={'center'}>
          <Col xl={24} xs={24}>
            <Form form={form} onFinish={update} layout="vertical" initialValues={{ description: '' }}>
              <Item
                name="name"
                label="Başlık"
                rules={[
                  {
                    required: true,
                    message: 'Doküman adı zorunlu',
                  }]}
              >
                <Input />
              </Item>
              <Item
                name="description"
                label="Açıklama"
              >
                <TextArea defaultValue={''} />
              </Item>
              <Item
                name="url"
                label="Doküman"
              >
                <Upload {...props}>
                  <Button icon={<UploadOutlined />}>Yükle</Button>
                </Upload>
              </Item>
              <Item>
                <Row justify={'end'}>
                  <Button className='btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text' htmlType="submit">
                    Kaydet
                  </Button>
                </Row>
              </Item>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default DocumentCreate