import React, { useContext, useMemo } from 'react';
import { HolderOutlined } from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import type { DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
  arrayMove,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Empty, Table } from 'antd';
import type { TableColumnsType } from 'antd';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';

interface RowContextProps {
  setActivatorNodeRef?: (element: HTMLElement | null) => void;
  listeners?: SyntheticListenerMap;
}

const RowContext = React.createContext<RowContextProps>({});

export const DragHandle: React.FC = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{ cursor: 'move' }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row: React.FC<RowProps> = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props['data-row-key'] });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  const contextValue = useMemo<RowContextProps>(
    () => ({ setActivatorNodeRef, listeners }),
    [setActivatorNodeRef, listeners],
  );

  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

interface SortableTableProps<T> {
  loading: boolean;
  columns: TableColumnsType<T>;
  dataSource: T[];
  onDragEnd: (event: DragEndEvent) => void;
  defaultSortField?: keyof T;
}

const SortableTable = <T extends { id: number }>({
  loading,
  columns,
  dataSource,
  onDragEnd,
  defaultSortField,
}: SortableTableProps<T>) => {
  const sortedDataSource = useMemo(() => {
    if (defaultSortField) {
      return [...dataSource].sort((a, b) => {
        if (a[defaultSortField] && b[defaultSortField]) {
          return (a[defaultSortField] as any) - (b[defaultSortField] as any);
        }
        return 0;
      });
    }
    return dataSource;
  }, [dataSource, defaultSortField]);

  return (
    <>
      {(sortedDataSource.length > 0) ?
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext items={sortedDataSource.map((i) => i.id)} strategy={verticalListSortingStrategy}>
            <Table
              pagination={{ pageSize: 100 }}
              loading={loading}
              rowKey="id"
              components={{ body: { row: Row } }}
              columns={columns}
              dataSource={sortedDataSource}
            />
          </SortableContext>
        </DndContext> : <Empty />
      }
    </>
  );
};

export default SortableTable;
