import { Button, Card, Col, Form, Input, Row, Select, Switch, Upload, UploadFile, UploadProps } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import ExplanationService from '../../../services/ExplanationService';
import { openNotification } from '../../../utils';
import { useState } from 'react';
import SubjectService from '../../../services/SubjectService';
import ReactQuill from 'react-quill';
import { UploadOutlined } from '@ant-design/icons';

const { Item } = Form;

interface ExplanationViewProps {
  subjectId: any;
  getTrigger: any;
  setCurrentExplanationId: any;
}

const ExplanationCreate: React.FC<ExplanationViewProps> = ({ subjectId, getTrigger, setCurrentExplanationId }) => {
  const [form] = Form.useForm();
  const [isFile, setIsFile] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const update = (values: any) => {
    let postObj = {
      title: values.name,
      description: isFile ? "" : values.description,
      subjectId: subjectId,
      type: isFile ? "FILE" : "TEXT",  // Forma type alanı eklendi
    };

    ExplanationService.create(postObj, fileList[0])
      .then((response: any) => {
        openNotification('success', 'Başarılı', 'Konu Anlatımı Başarıyla Oluşturuldu.');
        setCurrentExplanationId(null);
        getTrigger();
      }).catch((e: Error) => {
        openNotification('error', 'Başarısız', 'Konu Anlatımı oluşturulamadı.');
        console.error(e);
      });
  }

  const props: UploadProps = {
    onRemove: (file) => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      if (fileList.length >= 1) {
        openNotification('warning', 'Uyarı', 'Sadece bir adet dosya yükleyebilirsiniz')
        // If there's already a file in the list, reject the new file
        return Upload.LIST_IGNORE;
      }
      // If not, add the new file to the list
      setFileList([file]);
      return false;
    },
    fileList,
    defaultFileList: fileList, // Show the initial file list
    accept: '.pdf', // Accept only PDF files
    multiple: false, // Disable multiple file selection
  };

  return (
    <>
      <Card className='border-radius-md'>
        <Row justify={'space-between'} align={'middle'}>
          <Col><h3 className='fs-32'>Konu Anlatımı Ekle</h3></Col>
          <Col>
            <p>Yazı - Dosya</p>
            <Switch checked={isFile} onChange={() => setIsFile(!isFile)} className='w-full'></Switch>
          </Col>
        </Row>
        <Row justify={'center'}>
          <Col xl={24} xs={24}>
            <Form form={form} onFinish={update} layout="vertical">
              <Item
                name="name"
                label="Başlık"
                rules={[
                  {
                    required: true,
                    message: 'Konu Anlatımı adı zorunlu',
                  }]}
              >
                <Input />
              </Item>
              {!isFile ? (
                <Item name="description" label="İçerik">
                  <ReactQuill className='mb-24' theme="snow" />
                </Item>
              ) : (
                <Item name="file" label="İçerik">
                  <Upload {...props}>
                    <Button icon={<UploadOutlined />}>Yükle</Button>
                  </Upload>
                </Item>
              )}
              <Item>
                <Row justify={'end'}>
                  <Button className='btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text' htmlType="submit">
                    Kaydet
                  </Button>
                </Row>
              </Item>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default ExplanationCreate