import { Button, Col, Divider, Form, Input, Modal, Row } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react'
import { openNotification } from '../../../utils';
import GradeService from '../../../services/GradeService';

interface CreateGradeModalProps {
  showModal: boolean;
  setShowModal: any;
  gradeId: number;
  getTrigger: any;
}

const { Item } = Form;

const EditGradeModal: React.FC<CreateGradeModalProps> = ({ showModal, setShowModal, gradeId, getTrigger }) => {
  const [form] = Form.useForm();
  const [fetchedData, setFetchedData] = useState(false); // State to track whether data has been fetched

  useEffect(() => {
    if (showModal && !fetchedData) { // Check if modal is shown and data has not been fetched
      getService();
      setFetchedData(true); // Update state to indicate data has been fetched
    }
  }, [showModal]);

  const handleGradeCreateCancel = () => {
    setShowModal(false);
    setFetchedData(false); 
  };

  useEffect(() => {
    form.resetFields();
  }, [showModal])

  const update = (values: any) => {
    let postObj = {
      "name": values.name,
      "description": values.description,
    };

    GradeService.update(gradeId, postObj)
      .then((response: any) => {
        openNotification('success', 'Başarılı', 'Sınıf Başarıyla Düzenlendi.')
        setShowModal(false)
        setFetchedData(false); 
        getTrigger();
      }).catch((e: Error) => {
        openNotification('error', 'Başarısız', 'Sınıf Düzenlenemedi.')
        console.log(e)
      });
  }

  const getService = () => {
    GradeService.get(gradeId)
      .then((response: any) => {
        const data = response.data
        form.setFieldsValue({
          name: data?.name,
          description: data?.description,
        });
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  return (
    <>
      <Modal title="Sınıf Düzenle" open={showModal} closeIcon={<div onClick={() => setShowModal(false) && setFetchedData(false)}>X</div>}
        footer={
          <></>
        }
      >
        <Divider></Divider>
        <Form form={form} onFinish={update} layout="vertical">
          <Item
            name="name"
            label="Sınıf"
            rules={[
              {
                required: true,
                message: 'Sınıf adı zorunlu',
              }]}
          >
            <Input />
          </Item>
          <Item
            name="description"
            label="Açıklama"
          >
            <TextArea />
          </Item>
          <Divider></Divider>
          <Row gutter={8}>
            <Col span={12}>
              <Button onClick={handleGradeCreateCancel} className='w-full height-40'>
                İptal
              </Button>
            </Col>
            <Col span={12}>
              <Item>
                <Button htmlType="submit" className='w-full height-40 btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text'>
                  Kaydet
                </Button>
              </Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default EditGradeModal