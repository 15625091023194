import { useEffect, useState } from 'react';
import { Table, Image, Select, Modal, Form, Button, Row, Upload, UploadFile, UploadProps, GetProp, TabsProps, Tabs } from 'antd';
import StudentQuestionService from '../services/StudentQuestionService';
import LessonService from '../services/LessonService';
import { formatDate, openNotification } from '../utils';
import StudentService from '../services/StudentService';
import { PlusOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];
const { Option } = Select;

const Questions = () => {
  const [loading, setLoading] = useState(false);
  const id = localStorage.getItem('id');
  const [selectedQuestion, setSelectedQuestion] = useState<any>();
  const [questions, setQuestions] = useState<any[]>([]);
  const [lessons, setLessons] = useState<any[]>([]);
  const [students, setStudents] = useState<any[]>([]);
  const [filterLessonId, setFilterLessonId] = useState<string | undefined>(undefined);
  const [isModalVisible, setIsModalVisible] = useState(false);
  let authId = localStorage.getItem('id');
  const [form] = Form.useForm();
  const [pdfUrl, setPdfUrl] = useState<string>('');
  const [responseImage, setResponseImage] = useState<File | null>(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [lessonsMap, setLessonsMap] = useState<Map<any, any>>(new Map());
  const [studentsMap, setStudentsMap] = useState<Map<any, any>>(new Map());

  const handleFileChange = (info: any) => {
    if (info.fileList.length > 0) {
      setResponseImage(info.fileList[0].originFileObj);
    } else {
      setResponseImage(null);
    }
  };

  const getQuestionsAndLessons = async () => {
    setLoading(true);
    try {
      const [lessonResponse, questionResponse, studentResponse] = await Promise.all([
        LessonService.getAll(),
        StudentQuestionService.getNotAnswered(),
        StudentService.getAll(),
      ]);

      StudentService.getAll()
        .then((response: any) => {
          const lessonsData: any = lessonResponse?.data;
          const questionsData = questionResponse?.data;
          const studentsData: any = studentResponse?.data;
          // Map lessons to a useful structure
          const lessonsMapData = new Map(lessonsData.map((lesson: any) => [lesson.id, lesson.name]));
          const studentsMapData = new Map(studentsData.map((student: any) => [student.id, `${student.firstName} ${student.lastName}`]));
          // Enrich questions with lesson names
          setLessonsMap(lessonsMapData);
          setStudentsMap(studentsMapData);
          setLessons(lessonsData);
          setQuestions(questionsData);
          setStudents(response.data);
          setLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setLoading(false);
        });

    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const getAnswered = () => {
    StudentQuestionService.getAnswered()
      .then((response: any) => {
        const questionsData = response.data;
        setQuestions(questionsData);
      }).catch((e: Error) => {
        console.error(e);
      });
  }

  const getFile = (fileName: any) => {
    StudentQuestionService.getFile(fileName)
      .then((response: any) => {
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        const url = URL.createObjectURL(pdfBlob);
        setPdfUrl(url);
      }).catch((e: Error) => {
        console.error('Dosya getirilirken hata oluştu:', e);
        setPdfUrl('');  // Hata durumunda URL'i temizle.
      });
  }

  useEffect(() => {
    getQuestionsAndLessons();
  }, []);

  const handleLessonFilterChange = (value: string) => {
    setFilterLessonId(value);
  };

  const filteredQuestions = questions.filter(question =>
    filterLessonId ? question.lessonId.toString() === filterLessonId : true
  );

  const columns = [
    {
      title: 'Ders',
      dataIndex: 'lessonId',
      key: 'lessonId',
      render: (lessonId: number) => lessonsMap.get(lessonId) || ' '
    },
    {
      title: 'Soru',
      dataIndex: 'question',
      key: 'question',
    },
    {
      title: 'Öğrenci', dataIndex: 'studentId', key: 'studentId',
      render: (studentId: number) => studentsMap.get(studentId) || ' '
    },
    {
      title: 'Oluşturulma Tarihi',
      key: 'action',
      render: (_: any, record: any) => (
        <>
          {formatDate(record.createdAt)}
        </>
      )
    },
    {
      title: 'Cevap',
      key: 'action',
      render: (_: any, record: any) => (
        <Button onClick={() => showRespondModal(record)}>Cevapla</Button>
      )
    },

  ];

  const showRespondModal = (record: any) => {
    setIsModalVisible(true);
    setSelectedQuestion(record);
    getFile(record.image);  // Sorunun resmi veya PDF'i burada getiriliyor.
  };

  const handleRespondCancel = () => {
    setIsModalVisible(false);
  };

  const handleRespondSubmit = () => {
    form.validateFields()
      .then(values => {
        form.resetFields();
        let userId: any = id
        let postObj: any = {
          userId: userId,
          studentId: selectedQuestion?.studentId,
          lessonId: selectedQuestion?.lessonId,
          question: selectedQuestion?.question,
          answer: values.answer
        };
        // API çağrısı yapılır
        StudentQuestionService.update(selectedQuestion?.id, postObj, fileList[0])
          .then((response: any) => {
            openNotification('success', 'Başarılı', 'Cevap Kaydedildi.')
            setIsModalVisible(false);
            getQuestionsAndLessons();
          }).catch((e: Error) => {
            openNotification('error', 'Başarısız', 'Cevap Kaydedilemedi.')
          });
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });


  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Yükle</div>
    </button>
  );

  const props: UploadProps = {
    onPreview: async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as FileType);
      }

      setPreviewImage(file.url || (file.preview as string));
      setPreviewOpen(true);
    },
    onRemove: (file) => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      if (fileList.length >= 1) {
        openNotification('warning', 'Uyarı', 'Sadece bir adet dosya yükleyebilirsiniz')
        // If there's already a file in the list, reject the new file
        return Upload.LIST_IGNORE;
      }
      // If not, add the new file to the list
      setFileList([file]);
      return false;
    },
    fileList,
    defaultFileList: fileList, // Show the initial file list
    accept: '.jpg,.jpeg,.png', 
    multiple: false, // Disable multiple file selection
  };

  const onChange = (key: string) => {
    let number = parseInt(key)
    if (number === 1) {
      getQuestionsAndLessons();
    } else if (number === 2) {
      getAnswered();
    }
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Öğrenci Sorular',
      children:
        <>
          <Table loading={loading} dataSource={filteredQuestions} columns={columns} rowKey="id" />
        </>
      ,
    },
    {
      key: '2',
      label: 'Cevaplanan Sorular',
      children:
        <>
          <Table loading={loading} dataSource={filteredQuestions} columns={columns} rowKey="id" />
        </>
      ,
    },
  ];

  return (
    <div>
      <Row justify={'space-between'}>
        <Select style={{ width: 200, marginBottom: 16 }} placeholder="Derse Göre Filtrele" onChange={handleLessonFilterChange} allowClear onClear={() => setFilterLessonId(undefined)}>
          {lessons.map((lesson) => (
            <Option key={lesson.id} value={lesson.id.toString()}>{lesson.name}</Option>
          ))}
        </Select>
      </Row>
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />;
      <Modal title="Soruyu Cevapla" visible={isModalVisible} onOk={handleRespondSubmit} onCancel={handleCancel} okText="Kaydet" cancelText="İptal">
        {pdfUrl && <Image src={pdfUrl} width="100%" className='mt-24' />}
        <Form className='mt-24' form={form} layout="vertical">
          <Form.Item label="">
            <Upload  {...props} listType="picture-card"
              maxCount={1}
            >
              {fileList.length >= 1 ? null : uploadButton}
            </Upload>
            {previewImage && (
              <Image
                wrapperStyle={{ display: 'none' }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                  afterOpenChange: (visible) => !visible && setPreviewImage(''),
                }}
                src={previewImage}
              />
            )}
          </Form.Item>
          <Form.Item name="answer" label="Cevap" rules={[{ required: true, message: 'Cevap giriniz' }]}>
            <TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Questions;
