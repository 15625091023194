import { Button, Col, Divider, Form, Input, Modal, Row, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { useEffect, useState } from 'react'
import { openNotification } from '../../../utils';
import SubjectService from '../../../services/SubjectService';
import UnitService from '../../../services/UnitService';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles

interface CreateSubjectModalProps {
    showModal: boolean;
    setShowModal: any;
    getTrigger: any;
    unitId: any;
}

const { Item } = Form;

const CreateSubjectModal: React.FC<CreateSubjectModalProps> = ({ showModal, setShowModal, getTrigger, unitId }) => {
    const [units, setUnits] = useState<any>([]);
    const [form] = Form.useForm();
    const [fetchedData, setFetchedData] = useState(false); // State to track whether data has been fetched

    useEffect(() => {
      if (showModal && !fetchedData) { // Check if modal is shown and data has not been fetched
        getUnits()
        setFetchedData(true); // Update state to indicate data has been fetched
      }
    }, [showModal]);

    const handleSubjectCreateCancel = () => {
        setShowModal(false);
    };

    useEffect(() => {
        form.resetFields();
    }, [showModal])

    const getUnits = () => {
        UnitService.getAll()
            .then((response: any) => {
                const data = response.data
                const options = data.map((unit: { id: number; name: string; }) => ({
                    label: unit.name, // Kullanıcıya gösterilecek metin
                    value: unit.id, // Form üzerinden gönderilecek değer
                }));
                setUnits(options);

            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const update = (values: any) => {
        let postObj: any = {
            "title": values.name,
            "description": values.description,
            "unitId": unitId,
        };

        SubjectService.create(postObj)
            .then((response: any) => {
                openNotification('success', 'Başarılı', 'Konu Başarıyla Oluşturuldu.')
                setShowModal(false)
                getTrigger();
            }).catch((e: Error) => {
                openNotification('error', 'Başarısız', 'Konu oluşturulamadı.')
                console.log(e)
            });
    }

    const findUnitName = (unitId: number) => {
        const unit = units.find((unit: any) => unit.value === unitId);
        return unit ? unit.label : '';
    }

    return (
        <div>
            <Modal title={`Konu Ekle - ${findUnitName(unitId)}`} open={showModal} closeIcon={<div onClick={() => setShowModal(false)}>X</div>}
                footer={
                    <></>
                }
            >
                <Divider></Divider>
                <Form form={form} onFinish={update} layout="vertical">
                    <Item
                        name="name"
                        label="Konu"
                        rules={[
                            {
                                required: true,
                                message: 'Konu adı zorunlu',
                            }]}
                    >
                        <Input />
                    </Item>
                    <Item
                        name="description"
                        label="Açıklama"
                    >
                        <ReactQuill className='height-150 mb-24' theme="snow" />
                    </Item>
                    <Divider></Divider>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Button onClick={handleSubjectCreateCancel} className='w-full height-40'>
                                İptal
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Item>
                                <Button htmlType="submit" className='w-full height-40 btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text'>
                                    Kaydet
                                </Button>
                            </Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}

export default CreateSubjectModal