import { Card, Select, Table, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import UserService from '../../services/UserService';
import RoleService from '../../services/RoleService';

function UserList() {
    const [userList, setUserList] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getUserList();
        getRoleList();
    }, [])

    const getUserList = (filter = "") => {
        setUserList([]);
        setLoading(true);
        UserService.getAll(filter)
            .then((response: any) => {
                setLoading(false);
                setUserList(response.data);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    const handleChangeRole = (roleId: string) => {
        getUserList(`/role/${roleId}`);
    }

    const getRoleList = () => {
        RoleService.getAll()
            .then((response: any) => {
                const options = response.data.map((role: { id: number; name: string; }) => ({
                    label: role.name,
                    value: role.id, 
                }));
                setRoleList(options);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Ad',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Soyad',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'E-Posta',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Rol',
            key: 'roles',
            dataIndex: 'roles',
            render: (_: any, { roles }: any) => (
                <>
                    {roles.map((role: any) => {
                        return (
                            <Tag key={role.name}>
                                {role.name}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
    ];

    return (
        <Card title="Kullanıcı Listesi" extra={<Select onChange={handleChangeRole} placeholder="Rol Seçiniz" style={{ width: '250px' }} options={roleList} />
        }>
            <Table loading={loading} dataSource={userList} columns={columns} />
        </Card>
    )
}

export default UserList