import { Button, Card, Col, Drawer, Form, Input, Row } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import ExplanationService from '../../../services/ExplanationService';
import { openNotification } from '../../../utils';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';

const { Item } = Form;

interface ExplanationViewProps {
    explanationId: number;
    getTrigger: any;
    setCurrentExplanationId: any;
}

const ExplanationUpdate: React.FC<ExplanationViewProps> = ({ explanationId, getTrigger, setCurrentExplanationId }) => {
    const [subjectId, setSubjectId] = useState<number>()
    const [loading, setLoading] = useState<boolean>(false)
    const [form] = Form.useForm();
    const [pdfUrl, setPdfUrl] = useState<string>('');
    const [contentType, setContentType] = useState<string>('');
    const [showDrawer, setShowDrawer] = useState(false);

    useEffect(() => {
        if (explanationId) {
            getService();
        }
    }, [explanationId]);

    const getService = () => {
        setLoading(true)
        ExplanationService.get(explanationId)
            .then((response: any) => {
                const data = response.data
                setSubjectId(data?.subjectId)
                setContentType(data?.type);
                form.setFieldsValue({
                    name: data?.title,
                    description: data?.description,
                });
                if (data?.type === 'FILE') {
                    getFile(data?.url);
                }
                setLoading(false)
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const getFile = (fileName: any) => {
        ExplanationService.getFile(fileName).then((response: any) => {
            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
            const url = URL.createObjectURL(pdfBlob);
            setPdfUrl(url);

            // const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
            // const pdfUrl = URL.createObjectURL(pdfBlob);

            // // Yeni bir yazdırma penceresi oluştur
            // const printWindow = window.open(pdfUrl);
            // if (printWindow) {
            //   printWindow.onload = () => {
            //     printWindow.print(); // PDF yüklendikten sonra yazdır
            //     URL.revokeObjectURL(pdfUrl); // Kullanılmayan URL'yi temizle
            //   };
            // }

        }).catch((e: Error) => {

        });

    }

    const update = (values: any) => {
        let postObj = {
            "title": values.name,
            "description": values.description,
            "subjectId": subjectId
        };

        ExplanationService.update(explanationId, postObj)
            .then((response: any) => {
                openNotification('success', 'Başarılı', 'Konu Anlatımı Başarıyla Düzenlendi.')
                setCurrentExplanationId(null)
                getTrigger()
                setShowDrawer(false);
            }).catch((e: Error) => {
                openNotification('error', 'Başarısız', 'Konu Anlatımı Düzenlenemedi.')
                console.log(e)
            });
    }

    return (
        <>
            <Button className='mr-12' onClick={() => setShowDrawer(true)}>
                Düzenle
            </Button>
            <Drawer title="Konu Anlatımı Düzenle" placement="right" onClose={() => setShowDrawer(false)} open={showDrawer}>
                <Row justify={'center'}>
                    <Col xl={24} xs={24}>
                        <Form form={form} onFinish={update} layout="vertical">
                            <Item
                                name="name"
                                label="Başlık"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Explanation adı zorunlu',
                                    }]}
                            >
                                <Input />
                            </Item>
                            {contentType === 'TEXT' && (
                                <Item
                                    name="description"
                                    label="İçerik"
                                >
                                    <ReactQuill className=' mb-24' theme="snow" />
                                </Item>
                            )}
                            {contentType === 'FILE' && (
                                <Item
                                    name="file"
                                    label="Dosya İçeriği"
                                >
                                    {pdfUrl && <iframe src={pdfUrl} width="100%" height="600px" />}
                                </Item>
                            )}
                            <Item>
                                <Row justify={'end'}>
                                    <Button className='btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text' htmlType="submit">
                                        Kaydet
                                    </Button>
                                </Row>
                            </Item>
                        </Form>
                    </Col>
                </Row>
            </Drawer>
        </>
    )
}

export default ExplanationUpdate