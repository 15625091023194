import { Calendar, Card, Row } from 'antd';
import { useState, useEffect } from 'react';
import LiveLessonService from '../services/LiveLessonService';
import LessonService from '../services/LessonService';
import moment from 'moment';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import LiveLessonDetail from '../components/LiveLessons/LiveLessonDetail';
import '../components/LiveLessons/lesson-detail.css';
import UserService from '../services/UserService';
import StudentService from '../services/StudentService';
import Filter from '../components/LiveLessons/Filter';

const LiveLessons = () => {
  const [liveLessons, setliveLessons] = useState([]);
  const [lessons, setLessons] = useState<any>([]);
  const [students, setStudents] = useState<any>([]);
  const [teachers, setTeachers] = useState<any>([])
  const [filter, setFilter] = useState<any>({
    startLiveLessonDate: dayjs().startOf('month').format('YYYY-MM-DDT00:00:00'),
    endLiveLessonDate: dayjs().endOf('month').format('YYYY-MM-DDT23:59:59'),
  });

  const [loading, setLoading] = useState(false)

  const getLiveLessons = async () => {
    try {
      setLoading(true)
      const queryString = Object.keys(filter).map(key => `${key}=${filter[key]}`).join('&');

      const [liveLessonsResponse, lessonsResponse, studentResponse, userResponsive] = await Promise.all([
        LiveLessonService.getAll(`?${queryString}`),
        LessonService.getAll(),
        StudentService.getAll(),
        UserService.getAll(),

      ]);

      const lessonsMap = new Map<string, string>();
      (lessonsResponse?.data as any).forEach((lesson: any) => {
        lessonsMap.set(lesson.id, lesson.name);
      });
      setStudents(studentResponse?.data)
      setTeachers(userResponsive?.data)
      setLessons(lessonsResponse?.data)

      const studentsMap = new Map<string, string>();
      (studentResponse?.data as any).forEach((student: any) => {
        const fullName = student.firstName + ' ' + student.lastName;
        studentsMap.set(student.id, fullName);
      });



      const lessonsData = (liveLessonsResponse?.data as any).map((liveLesson: any) => {
        const lessonName = lessonsMap.get(liveLesson.lessonId);
        const studentName = studentsMap.get(liveLesson.studentId);
        return {
          ...liveLesson,
          lessonName,
          studentName,
          liveLessonMoment: moment(liveLesson.liveLessonDate)
        };
      });
      setliveLessons(lessonsData);
      setLoading(false)

      // setliveLessons(lessonsData);
    } catch (error) {
      console.error(error);
      setLoading(false)

    }
  };

  const onPanelChange = (value: Dayjs) => {
    const startOfMonth = value.startOf('month').format('YYYY-MM-DDT00:00:00');
    const endOfMonth = value.endOf('month').format('YYYY-MM-DDT23:59:59');

    setFilter((prev: any) => ({
      ...prev,
      startLiveLessonDate: startOfMonth,
      endLiveLessonDate: endOfMonth,
    }));
  };

  useEffect(() => {
    getLiveLessons();
    // getUsers();
  }, [filter]);


  const getListData = (x: Dayjs) => {
    const dateData: any = liveLessons || [];
    let matchedItems = [];
    for (let item of dateData) {
      if (x.format('YYYY-MM-DD') === dayjs(item.liveLessonDate).format('YYYY-MM-DD')) {
        matchedItems.push({ type: 'success', item: item, id: item.id });
      }
    }
    return matchedItems.length > 0 ? matchedItems : [];
  };

  const dateCellRender = (currentDate: any) => {
    const listData = getListData(currentDate);
    return (
      <ul className="events" style={{
        listStyleType: 'none',
        paddingLeft: 5
      }}>
        {listData.map((lesson: any, index) => (
          <li key={index} >
            {lesson.item && (
              <LiveLessonDetail item={lesson} getLiveLessons={getLiveLessons} />
            )}
          </li>
        ))}
      </ul>
    );
  };

  const handleOnChangeFilter = (values: any) => {
    setFilter((prev: any) => {
      const newFilter = { ...prev };

      if (values.student !== undefined) {
        newFilter.studentId = values.student;
      } else {
        delete newFilter.studentId; // Eğer undefined ise, filter objesinden bu anahtarı çıkarıyoruz.
      }

      if (values.teacher !== undefined) {
        newFilter.userId = values.teacher;
      } else {
        delete newFilter.userId;
      }

      if (values.lesson !== undefined) {
        newFilter.lessonId = values.lesson;
      } else {
        delete newFilter.lessonId;
      }

      if (values.isApprovel !== undefined) {
        newFilter.isApprovel = values.isApprovel;
      } else {
        delete newFilter.isApprovel;
      }

      if (values.isComplete !== undefined) {
        newFilter.isComplete = values.isComplete;
      } else {
        delete newFilter.isComplete;
      }

      return newFilter;
    });
  };


  return (
    <>
      <Card>
        <Row justify={'end'} style={{ marginRight: 16 }}>
          <Filter onChangeFilter={handleOnChangeFilter} students={students} teachers={teachers} lessons={lessons} />
        </Row>
        <Calendar dateCellRender={dateCellRender} mode="month" onPanelChange={onPanelChange} />
      </Card>
    </>
  );
};

export default LiveLessons;
