import { Button, Col, Form, Radio, Row, Select, Upload, Image, Input, Drawer } from 'antd';
import QuestionService from '../../../services/QuestionService';
import { openNotification } from '../../../utils';
import { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import type { GetProp, UploadFile, UploadProps } from 'antd';
import { IQuestionRequest } from '../../../types/Question';
import VideoList from '../../../components/Video/VideoList';
import VimeoPlayer from '../../../components/Subjects/Components/VimeoPlayer';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];
const { Item } = Form;

interface QuestionCreateProps {
  subjectId: number;
  getData: any;
}

const QuestionCreate: React.FC<QuestionCreateProps> = ({ subjectId, getData }) => {
  const [form] = Form.useForm();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [vimeoId, setVimeoId] = useState(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [answerOptions, setAnswerOptions] = useState([
    { value: "A", label: "Doğru" },
    { value: "B", label: "Yanlış" }
  ]);

  const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Yükle</div>
    </button>
  );


  const create = (values: any) => {
    let postObj: any = {
      "correctAnswer": values.correctAnswer,
      "answerType": values.answerType,
      "vimeoId": vimeoId,
      "subjectId": subjectId,
    };

    QuestionService.create(postObj, fileList[0])
      .then((response: any) => {
        getData();
        openNotification('success', 'Başarılı', 'Soru Başarıyla Oluşturuldu.');
        setShowDrawer(false);
        form.resetFields(); // Formu resetle
        setVimeoId(null);
        setFileList([]); // Dosya listesini temizle
      }).catch((e: Error) => {
        openNotification('error', 'Başarısız', 'Soru oluşturulamadı.');
        console.log(e);
      });
  };

  const props: UploadProps = {
    onPreview: async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as FileType);
      }

      setPreviewImage(file.url || (file.preview as string));
      setPreviewOpen(true);
    },
    onRemove: (file) => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      if (fileList.length >= 1) {
        openNotification('warning', 'Uyarı', 'Sadece bir adet dosya yükleyebilirsiniz');
        return Upload.LIST_IGNORE;
      }
      setFileList([file]);
      setPreviewImage(URL.createObjectURL(file));
      return false;
    },
    fileList,
    defaultFileList: fileList,
    accept: '.jpg,.jpeg,.png',
    multiple: false,
  };


  const handleAnswerTypeChange = (e: any) => {
    const value = e.target.value;
    let options: any[] = [];
    switch (value) {
      case '2':
        options = [
          { value: "A", label: "Doğru" },
          { value: "B", label: "Yanlış" }
        ];
        break;
      case '4':
        options = [
          { value: "A", label: "A" },
          { value: "B", label: "B" },
          { value: "C", label: "C" },
          { value: "D", label: "D" }
        ];
        break;
      case '5':
        options = [
          { value: "A", label: "A" },
          { value: "B", label: "B" },
          { value: "C", label: "C" },
          { value: "D", label: "D" },
          { value: "E", label: "E" }
        ];
        break;
      default:
        break;
    }
    setAnswerOptions(options);
    form.setFieldsValue({ correctAnswer: undefined }); // Doğru cevap alanını temizle
  };

  return (
    <>
      <Button className='btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text' onClick={() => setShowDrawer(true)}>
        Soru Ekle
      </Button>
      <Drawer title="Yeni Soru Oluştur" placement="right" onClose={() => setShowDrawer(false)} open={showDrawer} >
        <Row justify={'center'}>
          <Col span={24}>
            <Form form={form} onFinish={create} layout="vertical" initialValues={{ answerType: '2' }}>
              <Item
                name="file"
                label="Soru"
              >
                <Upload {...props} listType="picture-card">
                  {fileList.length >= 1 ? null : uploadButton}
                </Upload>
                {(previewImage && fileList.length >= 1) && (
                  <Image
                    preview={{
                      visible: previewOpen,
                      onVisibleChange: (visible) => setPreviewOpen(visible),
                      src: previewImage,
                    }}
                    src={previewImage}
                  />
                )}
              </Item>
              <Item
                name="answerType"
                label="Soru Tipi"
                rules={[
                  {
                    required: true,
                    message: 'Soru Tipi zorunlu',
                  }]}
              >
                <Radio.Group onChange={handleAnswerTypeChange}>
                  <Radio value={'2'}>Doğru/Yanlış</Radio>
                  <Radio value={'4'}>4 Cevaplı</Radio>
                  <Radio value={'5'}>5 Cevaplı</Radio>
                </Radio.Group>
              </Item>
              <Item
                name="correctAnswer"
                label="Doğru Cevap"
                rules={[
                  {
                    required: true,
                    message: 'Doğru cevap zorunlu',
                  }]}
              >
                <Select options={answerOptions} />
              </Item>
              {(vimeoId) ? <><VimeoPlayer videoId={vimeoId}></VimeoPlayer><Button danger onClick={()=>{setVimeoId(null)}}>Sil</Button></> : <VideoList setVimeoId={(id: any) => { setVimeoId(id) }}></VideoList>}
              <Item>
                <Row justify={'end'}>
                  <Button className='btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text' htmlType="submit">
                    Kaydet
                  </Button>
                </Row>
              </Item>
            </Form>
          </Col>
        </Row>
      </Drawer>
    </>
  );
}

export default QuestionCreate;
