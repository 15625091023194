import { notification } from 'antd';
import dayjs from 'dayjs';

type NotificationType = 'success' | 'info' | 'warning' | 'error';
export const openNotification = (type: NotificationType, status: string, description: string) => {
    const args = {
        message: status,
        description: description,
        duration: 2,
    };
    notification[type](args);
};

export function formatDate(date: string): string {
    if (!date) {
        return '';
    }
    return dayjs(date).format('DD/MM/YYYY HH:mm');
}

export function formatJustDate(date: string): string {
    if (!date) {
        return '';
    }
    return dayjs(date).format('DD/MM/YYYY');
}

export function getDateRangeDefault(): any {
    let dateRangeDefaultValue: [dayjs.Dayjs, dayjs.Dayjs] = [
        dayjs().add(-90, 'd'),
        dayjs()
    ];
    return dateRangeDefaultValue;
}

export function getCurrentMonthDateRange() {
    let dateRangeDefaultValue: [dayjs.Dayjs, dayjs.Dayjs] = [
        dayjs().add(-7, 'd'), dayjs()
    ];

    return dateRangeDefaultValue;
}

export function getCurrentMonthDateRangeNew() {

    let dateRangeDefaultValue: [dayjs.Dayjs, dayjs.Dayjs] = [
        dayjs().startOf('month'), dayjs()
    ];

    return dateRangeDefaultValue;
}

export function convertDateFormat(dateStr: any): string {
    return dayjs(dateStr, 'DD/MM/YYYY').format('YYYY-MM-DD');
}

export function truncateText(text: any, maxLength: any) {
    if (text?.length <= maxLength) return text;
    return text?.substring(0, maxLength) + '...';
};

export function isAuthorized(requiredPermission: any) {
    let permissions = localStorage.getItem('permissions')
    return permissions?.includes(requiredPermission);
}

export function removeLocal() {
    localStorage.removeItem('firstName')
    localStorage.removeItem('lastName')
    localStorage.removeItem('email')
    localStorage.removeItem('phoneNumber')
    localStorage.removeItem('role')
    localStorage.removeItem('id')
    localStorage.removeItem('accessToken');
    localStorage.removeItem('accessTokenExpiredAt');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('refreshTokenExpiredAt');
    localStorage.removeItem('permissions');
    localStorage.removeItem('profilePicture');
    localStorage.removeItem('videoList');
    
}


export function turkishToEnglish(input: string): string {
    const turkishChars: Record<string, string> = {
        'Ç': 'C',
        'Ş': 'S',
        'Ğ': 'G',
        'Ü': 'U',
        'İ': 'I',
        'Ö': 'O',
        'ç': 'c',
        'ş': 's',
        'ğ': 'g',
        'ü': 'u',
        'ı': 'i',
        'ö': 'o'
    };

    return input.split('').map(char => turkishChars[char] || char).join('');
}
