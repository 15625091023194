import { Button, Card, Col, Drawer, Form, Input, Row } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import DocumentService from '../../../services/DocumentService';
import { openNotification } from '../../../utils';
import { useEffect, useState } from 'react';
import { IDocumentRequest } from '../../../types/Documents';

const { Item } = Form;

interface DocumentUpdateProps {
    documentId: number;
    getTrigger: any;
    setCurrentDocumentId: any;
}

const DocumentUpdate: React.FC<DocumentUpdateProps> = ({ documentId, getTrigger, setCurrentDocumentId }) => {
    const [subjectId, setSubjectId] = useState<number | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false)
    const [form] = Form.useForm();
    const [pdfUrl, setPdfUrl] = useState<string>('');
    const [showDrawer, setShowDrawer] = useState(false);

    useEffect(() => {
        if (documentId) {
            getDocument();
        }
    }, [documentId]);

    const getDocument = () => {
        setLoading(true)
        DocumentService.get(documentId)
            .then((response: any) => {
                const data = response.data
                setSubjectId(data?.subjectId)
                form.setFieldsValue({
                    name: data?.title,
                    description: data?.description,
                    url: data?.url,
                });

                getFile(data?.url);
                setLoading(false)
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const getFile = (fileName: any) => {
        DocumentService.getFile(fileName).then((response: any) => {
            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
            const url = URL.createObjectURL(pdfBlob);
            setPdfUrl(url);
        }).catch((e: Error) => {

        });

    }

    const update = (values: any) => {
        let postObj: IDocumentRequest = {
            "title": values.name,
            "description": values.description,
            "subjectId": subjectId !== undefined ? subjectId : 0
        };

        DocumentService.update(documentId, postObj)
            .then((response: any) => {
                openNotification('success', 'Başarılı', 'Document Başarıyla Düzenlendi.')
                getTrigger()
                setShowDrawer(false);
            }).catch((e: Error) => {
                openNotification('error', 'Başarısız', 'Document Düzenlenemedi.')
                console.log(e)
            });
    }

    return (
        <>
            <Button className='mr-12' onClick={() => setShowDrawer(true)}>
                Düzenle
            </Button>
            <Drawer title="Doküman Düzenle" placement="right" onClose={() => setShowDrawer(false)} open={showDrawer}>
                <Row justify={'center'}>
                    <Col xl={24} xs={24}>
                        <Form form={form} onFinish={update} layout="vertical">
                            <Item
                                name="name"
                                label="Başlık"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Doküman adı zorunlu',
                                    }]}
                            >
                                <Input />
                            </Item>
                            <Item
                                name="description"
                                label="Açıklama"
                            >
                                <TextArea />
                            </Item>
                            <Item
                                name="url"
                                label="Doküman"
                            >
                                {pdfUrl && <iframe src={pdfUrl} width="100%" height="600px" />}
                            </Item>
                            <Item>
                                <Row justify={'end'}>
                                    <Button className='btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text' htmlType="submit">
                                        Kaydet
                                    </Button>
                                </Row>
                            </Item>
                        </Form>
                    </Col>
                </Row>
            </Drawer>
        </>
    )
}

export default DocumentUpdate