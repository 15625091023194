import { Button, Modal, Form, Select, Row, DatePicker } from 'antd';
import React, { useState } from 'react';
import filterIcon from '../../assets/Layout/filter.svg';
import DateRange from './../Common/DateRange';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import 'dayjs/locale/tr';
import tr from 'antd/es/date-picker/locale/tr_TR';



const { RangePicker } = DatePicker;
const { Item } = Form;

interface LearnerFilterProps {
    onChangeFilter: (values: any) => void;
}


const Filter: React.FC<LearnerFilterProps> = ({ onChangeFilter }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const [dateRange, setDateRange] = useState<any>(null)

    const showModal = () => {
        setIsModalOpen(true);
    };

    const onFinish = (values: any) => {
        if (dateRange) {
            values.startDueDate = dateRange.startDueDate;
            values.endDueDate = dateRange.endDueDate;
        }
        onChangeFilter(values);
        setIsModalOpen(false);
    };


    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        form.resetFields();
        setDateRange(null);
        setIsModalOpen(false);
    };

    const clearForm = () => {
        form.resetFields();
        setDateRange(null);  
    };

    const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
        if (dates && dates[0] && dates[1]) {
            const formattedStart = dates[0].format('YYYY-MM-DDT00:00:00');
            const formattedEnd = dates[1].format('YYYY-MM-DDT23:59:59');
            setDateRange({
                startDueDate: formattedStart,
                endDueDate: formattedEnd
            });
        } else {
            setDateRange(null);
        }
    };

    return (
        <>
            <Modal title="Canlı Ders Filtre" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[]}>
                <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
                    <div style={{ marginTop: 24, }}>
                        Ödev Teslim Tarihi
                        <div style={{ marginTop: 10 }}>
                        <RangePicker style={{ width: '100%' }} locale={tr} format="DD/MM/YYYY" value={dateRange ? [dayjs(dateRange.startDueDate), dayjs(dateRange.endDueDate)] : null}
                                onChange={onRangeChange} />
                        </div>
                    </div>
                    <Item>
                        <Row justify="end" style={{ marginTop: 16 }}>
                            <Button key="back" style={{ marginRight: "10px" }} onClick={clearForm}>
                                Temizle
                            </Button>
                            <Button htmlType='submit' className='bg-saha-purple  font-color-white' type='default'>
                                Filtre
                            </Button>
                        </Row>
                    </Item>

                </Form>
            </Modal>
            <Button className='mr-15' onClick={showModal} type="default">
                <Row>
                    <img src={filterIcon} alt='filterIcon' style={{ margin: 1 }} />
                </Row>
            </Button>
        </>
    );
}

export default Filter
