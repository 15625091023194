import { Button, Card, Col, Form, Popconfirm, Row, Space, Table, Tag } from 'antd'
import PackageService from '../../services/PackagesService'
import { useEffect, useState } from 'react';
import PackagesItemCard from '../../components/Packages/PackagesItemCard';
import { NavLink } from 'react-router-dom';
import type { TableProps } from 'antd';
import { openNotification } from '../../utils';
import { DeleteOutlined } from '@ant-design/icons'

const { Item } = Form;

interface Package {
  id: number;
  name: string;
  tags: string;
  description: string;
  price: string;
}

const Packages = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    getPackages()
  }, []);


  const columns: TableProps<Package>['columns'] = [
    {
      title: 'Paket',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Açıklama',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Ücret',
      dataIndex: 'price',
      key: 'price',
      render: (price) => <b>{price} ₺</b>,
    },
    {
      title: 'Komisyon Oranı',
      dataIndex: 'commissionRate',
      key: 'commissionRate',
      render: (commissionRate) => <b>% {commissionRate}</b>,
    },
    {
      title: 'Etiketler',
      key: 'tags',
      dataIndex: 'tags',
      render: (_, { tags }) => (
        <>
          {tags?.split(',').map((tag:any) => {
            return (
              <Tag key={tag}>
                #{tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: 'İşlem',
      align:"center",
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <NavLink to={`/package/${record.id}`}>
            <Button className='bg-saha-purple btn-hover-dark fw-700 font-color-white'>
              Detay
            </Button>
          </NavLink>
          <Popconfirm
            key={record.id}
            title="Paket silinsin mi?"
            onConfirm={() => removePackage(record.id)}
            okText="Evet"
            cancelText="Hayır"
          >
            <DeleteOutlined
              className='cursor-pointer' />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const getPackages = () => {
    setLoading(true);
    PackageService.getAll()
      .then((response: any) => {
        const data = response.data;
        setPackages(data);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
        setLoading(false);
      });
  };

  const removePackage = (id: number) => {
    PackageService.remove(id)
      .then((response: any) => {
        getPackages();
        openNotification('success', 'Başarılı', 'Paket Başarıyla Silindi.')
      }).catch((e: Error) => {
        openNotification('error', 'Başarısız', 'Paket silinemedi.')
      });
  }

  return (
    <>
      <Card title="Paket Listesi" extra={<NavLink to={'/packages/create'}>
        <Button className='bg-saha-purple fw-700 font-color-white btn-hover-dark'>
          Yeni Paket Oluştur
        </Button>
      </NavLink>} loading={loading}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Table pagination={{ pageSize:100 }}  columns={columns} dataSource={packages} />
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default Packages