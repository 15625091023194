import {http} from "../http-common";
import { IGradeRequest, IGradeResponse } from "../types/Grade";
import { isAuthorized, openNotification } from "../utils";

const getAll = () => {
    if (isAuthorized('grade.list')) {
        return http.get<IGradeResponse>(`/grade`);
    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const create = (data: IGradeRequest) => {
    if (isAuthorized('grade.create')) {
        return http.post<IGradeRequest>(`/grade`, data);
    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const get = (id: number) => {
    if (isAuthorized('grade.show')) {
        return http.get<IGradeResponse>(`/grade/${id}`);
    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const update = (id: number, data: IGradeRequest) => {
    if (isAuthorized('grade.update')) {
        return http.patch<IGradeRequest>(`/grade/${id}`, data);
    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const remove = (id: number) => {
    if (isAuthorized('grade.delete')) {
        return http.delete<IGradeRequest>(`/grade/${id}`);
    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const updateSortOrders = (data: any) => {
    if (isAuthorized('grade.update')) {
        return http.put<any>(`/grade/update-sort-orders`, data);
    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const GradeService = {
    create,
    getAll,
    get,
    update,
    remove,
    updateSortOrders
}

export default GradeService;