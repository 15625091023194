import { Card, Col, Form, Input, Row } from 'antd'
import { useEffect, useState } from 'react'
import UserService from '../services/UserService';
import { openNotification } from '../utils';

const { Item } = Form;

const Profile = () => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        getService()
    }, [])

    const getService = () => {
        setLoading(true)
        UserService.getMe()
            .then((response: any) => {
                const data = response.data
                form.setFieldsValue({
                    firstName: data?.firstName,
                    lastName: data?.lastName,
                    email: data?.email,
                });
                setLoading(false)
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const update = (values: any) => {
        let postObj = {
            "firstName": values.firstName,
            "lastName": values.lastName,
            "email": values.email,
        }

        UserService.updateMe(postObj)
            .then((response: any) => {
                openNotification('success', 'Başarılı', 'Profil Başarıyla Düzenlendi.')
            }).catch((e: Error) => {
                openNotification('error', 'Başarısız', 'Profil Kaydedilemedi.')
            });
    }

    return (
        <>
            <Card loading={loading}>
                <h3 className='fs-24 fw-700'>Profil</h3>
                <Form form={form} onFinish={update} layout="vertical">
                    <Row className='mb-24' gutter={24}>
                        <Col xl={6} md={12} xs={24}>
                            <Item
                                name="firstName"
                                label="İsim"
                                rules={[
                                    {
                                        required: true,
                                        message: 'İsim zorunlu',
                                    }]}
                            >
                                <Input />
                            </Item>
                        </Col>
                        <Col xl={6} md={12} xs={24}>
                            <Item
                                name="lastName"
                                label="Soyisim"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Soyisim zorunlu',
                                    }]}
                            >
                                <Input />
                            </Item>
                        </Col>
                    </Row>
                    <Row className='mb-24' gutter={24}>
                        <Col xl={12} md={12} xs={24}>
                            <Item
                                name="email"
                                label="E-Posta"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Email zorunlu',
                                    }]}
                            >
                                <Input />
                            </Item>
                        </Col>
                    </Row>
                    {/* <Item>
                        <Row justify={'end'}>
                            <Button className='btn btn-hover-dark bg-cloud font-color-onyx btn-text mr-16' >
                                Vazgeç
                            </Button>
                            <Button  className='btn btn-hover-dark bg-slate-blue font-color-cloud btn-text' htmlType="submit">
                                Kaydet
                            </Button>
                        </Row>
                    </Item> */}
                </Form>
            </Card>
        </>
    )
}

export default Profile