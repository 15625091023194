import { Card, Col, Row, Form, Button, Drawer, Input } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import GradeService from '../services/GradeService';
import GradesItemCard from '../components/Grades/GradesItemCard';
import TextArea from 'antd/es/input/TextArea';
import { openNotification } from '../utils';

const { Item } = Form;

const Grades = () => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [grades, setGrades] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    getGrades()
  }, []);

  const getGrades = () => {
    setLoading(true);
    GradeService.getAll()
      .then((response: any) => {
        const data = response.data;
        setGrades(data);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
        setLoading(false);
      });
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [open])

  const update = (values: any) => {

    let postObj = {
      "name": values.name,
      "description": values.description,
    };

    GradeService.create(postObj)
      .then((response: any) => {
        getGrades();
        openNotification('success', 'Başarılı', 'Sınıf Başarıyla Eklendi.')
        onClose();
      }).catch((e: Error) => {
        console.log(e)
        openNotification('error', 'Başarısız', 'Sınıf Eklenemedi')
      });
  }
  return (
    <>
      <Row justify={'center'} className='m-24'>
        <Col span={16}>
          <Card loading={loading}>
            <Row align={'middle'} justify={'space-between'}>
              <Col>
                <h2 className='mr-16'>Sınıflar</h2>
                <Button  onClick={showDrawer} className=''>
                  Yeni Sınıf Ekle
                </Button>
              </Col>
              <Col>
              <Button onClick={()=>navigate(-1)} className=''>
                  Geri
                </Button>
              </Col>
            </Row>
            <Row gutter={[24, 24]} className='mt-24'>
              {grades.map((grade) => (
                <Col span={24}>
                  <GradesItemCard grade={grade} getTrigger={getGrades} />
                </Col>
              ))}
            </Row>
          </Card>
          <Drawer title="Sınıf Ekle" placement="right" onClose={onClose} open={open}>
            <Form form={form} onFinish={update} layout="vertical">
              <Item
                name="name"
                label="Sınıf"
                rules={[
                  {
                    required: true,
                    message: 'Sınıf adı zorunlu',
                  }]}
              >
                <Input />
              </Item>
              <Item
                name="description"
                label="Açıklama"
              >
                <TextArea />
              </Item>
              <Item>
                <Row justify={'end'}>
                  <Button className='btn btn-hover-dark bg-cloud font-color-onyx btn-text mr-16' onClick={onClose}>
                    Vazgeç
                  </Button>
                  <Button  className='btn btn-hover-dark bg-slate-blue font-color-cloud btn-text' htmlType="submit">
                    Kaydet
                  </Button>
                </Row>
              </Item>
            </Form>
          </Drawer>
        </Col>
      </Row>
    </>
  )
}

export default Grades