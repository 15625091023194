import { Button, Col, Divider, Drawer, Form, Input, Modal, Row } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect, useState } from 'react'
import { openNotification } from '../../../utils';
import GradeService from '../../../services/GradeService';
import { EditOutlined } from '@ant-design/icons'

interface GradeUpdateProps {
    gradeId: number;
    getTrigger: any;
}

const { Item } = Form;

const GradeUpdate: React.FC<GradeUpdateProps> = ({ gradeId, getTrigger }) => {
    const [form] = Form.useForm();
    const [showDrawer, setShowDrawer] = useState(false);


    useEffect(() => {
        if (showDrawer) {
            getData();
        }
    }, [showDrawer]);

    const handleGradeCreateCancel = () => {
        setShowDrawer(false);
    };

    useEffect(() => {
        form.resetFields();
    }, [])

    const update = (values: any) => {
        let postObj = {
            "name": values.name,
            "description": values.description,
        };

        GradeService.update(gradeId, postObj)
            .then((response: any) => {
                openNotification('success', 'Başarılı', 'Sınıf Başarıyla Düzenlendi.')
                setShowDrawer(false)
                getTrigger();
            }).catch((e: Error) => {
                openNotification('error', 'Başarısız', 'Sınıf Düzenlenemedi.')
                console.log(e)
            });
    }

    const getData = () => {
        GradeService.get(gradeId)
            .then((response: any) => {
                const data = response.data
                form.setFieldsValue({
                    name: data?.name,
                    description: data?.description,
                });
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    return (
        <>
            <Button onClick={() => setShowDrawer(true)}>
                <EditOutlined />
            </Button>
            <Drawer title="Sınıf Düzenle" placement="right" onClose={() => setShowDrawer(false)} open={showDrawer}>
                <Form form={form} onFinish={update} layout="vertical">
                    <Item
                        name="name"
                        label="Sınıf"
                        rules={[
                            {
                                required: true,
                                message: 'Sınıf adı zorunlu',
                            }]}
                    >
                        <Input />
                    </Item>
                    <Item
                        name="description"
                        label="Açıklama"
                    >
                        <TextArea />
                    </Item>
                    <Divider></Divider>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Button onClick={handleGradeCreateCancel} className='w-full height-40'>
                                İptal
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Item>
                                <Button htmlType="submit" className='w-full height-40 btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text'>
                                    Kaydet
                                </Button>
                            </Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    )
}

export default GradeUpdate