import {http} from "../http-common";
import { IQuizRequest, IQuizResponse } from "../types/Quiz";
import { isAuthorized, openNotification } from "../utils";


const getAll = () => {
    if (isAuthorized('quiz.list')) {
        return http.get<IQuizResponse>(`/quiz`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const create = (data: any) => {
    if (isAuthorized('quiz.create')) {
        return http.post<IQuizRequest>(`/quiz`, data);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const get = (id: any) => {
    if (isAuthorized('quiz.show')) {
        return http.get<IQuizResponse>(`/quiz/${id}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const update = (id: any, data: any) => {
    if (isAuthorized('quiz.update')) {
        return http.patch<IQuizRequest>(`/quiz/${id}`, data);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const remove = (id: number) => {
    if (isAuthorized('quiz.delete')) {
        return http.delete<IQuizRequest>(`/quiz/${id}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const QuizService = {
    create,
    getAll,
    get,
    update,
    remove
}

export default QuizService;