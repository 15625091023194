import React, { useEffect, useState } from 'react';
import { DatePicker, TimeRangePickerProps } from 'antd';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { getCurrentMonthDateRange, convertDateFormat, getCurrentMonthDateRangeNew } from '../../utils';


interface DateRangeProps {
    onChangeDateRange: (values: any) => void;
    thisMonth?: boolean;
    width?: any;
}

const DateRange: React.FC<DateRangeProps> = ({ onChangeDateRange, thisMonth = false,width=300 }) => {
    const { RangePicker } = DatePicker;

    useEffect(() => {
        let dateFormat;
        if (thisMonth) {
            dateFormat = getCurrentMonthDateRangeNew();
        } else {
            dateFormat = getCurrentMonthDateRange();
        }
        let convertedDateFormat = [convertDateFormat(dateFormat[0]), convertDateFormat(dateFormat[1])];
        onChangeDateRange(convertedDateFormat);
    }, [])


    const rangePresets: TimeRangePickerProps['presets'] = [
        { label: 'Bugün', value: [dayjs().startOf('day'), dayjs()] },
        { label: 'Dün', value: [dayjs().add(-1, 'd'), dayjs()] },
        { label: 'Son 7 Gün', value: [dayjs().add(-7, 'd'), dayjs()] },
        { label: 'Son 30 Gün', value: [dayjs().add(-30, 'd'), dayjs()] },
        { label: 'Bu Ay', value: [dayjs().startOf('month'), dayjs()] },
       
    ];

    const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
        if (dates && dates[0] && dates[1]) {
            const formattedDates = [convertDateFormat(dates[0]), convertDateFormat(dates[1])];
            onChangeDateRange(formattedDates);
        } else {
            onChangeDateRange([]);
        }
    };


    return (
        <RangePicker size="large" defaultValue={thisMonth ? getCurrentMonthDateRangeNew() : getCurrentMonthDateRange()} presets={rangePresets} onChange={onRangeChange} format={"DD/MM/YYYY"} style={{width:width}}/>
    );
}

export default DateRange
