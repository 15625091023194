import { Card, Col, Row, Select } from 'antd'
import ReactApexChart from 'react-apexcharts'
import DashboardService from '../../services/DashboardService';
import { useEffect, useState } from 'react';
import DateRange from '../Common/DateRange';
import dayjs from 'dayjs';
import { debounce } from 'lodash'; 

const LiveLessonGraph = () => {
  const [series, setSeries] = useState<any>([])
  const [count, setCount] = useState<any>()
  const [dateRange, setDateRange] = useState<any>([])

  const [options, setOptions] = useState<any>({
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false // Toolbar'ı gizlemek için
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 5, // Sütunların üst kısımlarını yuvarlak yapar
        columnWidth: '50%', // Sütun genişliğini belirleyebilirsiniz
      }
    },
    stroke: {
      width: [4, 4, 4], // Her seride genişlik aynı
      curve: 'smooth' // Smooth curves için
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [1]
    },
    xaxis: {
      type: "category",
      labels: {
        formatter: function(val: string) {
          return dayjs(val).format('DD/MM/YYYY');
        }
      }
    },
    yaxis: [

    ],
    legend: {
      horizontalAlign: "left"
    },
    colors: ['#FED449', '#F47D38', '#6324F5'],
  })

  const getData = (dateRange: any) => {
    let startDate = dayjs(dateRange[0]).format('YYYY-MM-DDT00:00:00');
    let endDate = dayjs(dateRange[1]).format('YYYY-MM-DDT23:59:59');
    DashboardService.liveLessonGraph(`?startDate=${startDate}&endDate=${endDate}`)
      .then((response: any) => {
        const data = response.data
        let totalCount = 0;
        Object.values(data).forEach((dayData: any) => {
          totalCount += dayData.approved + dayData.created + dayData.completed;
        });
        setCount(totalCount);
        updateSeries(data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  }

  const debounceDelay = 800; 

  // getData işlevini debounce yapın
  const debouncedGetData = debounce(getData, debounceDelay);

  useEffect(() => {
    debouncedGetData(dateRange);
  }, [dateRange])

  const updateSeries = (responseData: any) => {
    // Tarihleri ve verileri ayırma
    const labels = Object.keys(responseData); // Tarihleri etiket olarak kullan
    const gerceklesenData = labels.map(date => responseData[date].completed);
    const planlananData = labels.map(date => responseData[date].approved);
    const talepEdilenData = labels.map(date => responseData[date].created);

    setSeries([
      { name: "Gerçekleşen", type: "column", data: gerceklesenData },
      { name: "Planlanan", type: "line", data: planlananData },
      { name: "Talep Edilen", type: "line", data: talepEdilenData }
    ]);

    // Ayrıca, xAxis etiketlerini de güncelleyelim
    setOptions((prevOptions: any) => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: labels
      }
    }));
  };

  return (
    <div>
      <Card className='border-radius-md'>
        <Row justify={'space-between'} align={'middle'}>
          <Col>
            <h3 className='fs-24 fw-700 m-0'>Canlı Ders İstatistikleri</h3>
          </Col>
          <Col>
            <DateRange onChangeDateRange={setDateRange}/>

          </Col>
        </Row>
        <Row className='mt-24'>
          <Col className='pt-48' span={6}>
            <h3 className='fs-24 fw-700 m-0'>{count} Adet</h3>
            <p className='fs-14 fw-500 font-color-gray m-0'>Toplam Canlı Ders</p>
            {/* <Row className='mt-64' align={'middle'} gutter={8}>
              <Col>
                <div className='wh-10 bg-warning border-radius-lg'></div>
              </Col>
              <Col>
                <p className='fs-12'>Gerçekleşen</p>
              </Col>
            </Row>
            <Row align={'middle'} gutter={8}>
              <Col>
                <div className='wh-10 bg-orange border-radius-lg'></div>
              </Col>
              <Col>
                <p className='m-0 fs-12'>Planlanan</p>
              </Col>
            </Row>
            <Row align={'middle'} gutter={8}>
              <Col>
                <div className='wh-10 bg-info border-radius-lg'></div>
              </Col>
              <Col>
                <p className='fs-12'>Talep Edilen</p>
              </Col>
            </Row> */}
          </Col>
          <Col span={18}>
            <ReactApexChart
              options={options}
              series={series}
              type="line"
              height={200}
            />
          </Col>
        </Row>
      </Card>
    </div>
  )
}

export default LiveLessonGraph