import { http } from "../http-common";
import { IAnnouncementRequest, IAnnouncementResponse } from "../types/Announcement";
import { isAuthorized, openNotification } from "../utils";



const getAll = () => {
    if (isAuthorized('announcement.list')) {
        return http.get<IAnnouncementResponse>(`/announcement/all`);
    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const create = (data: IAnnouncementRequest, file: any) => {
    const formData = new FormData();
    if (file) {
        formData.append("file", file);
    }
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("cover", data.cover);

    const config = {
        headers: {
            maxBodyLength: Infinity,
            'Content-Type': 'multipart/form-data',
        },
    };

    if (isAuthorized('announcement.create')) {
        return http.post<IAnnouncementRequest>(`/announcement`, formData, config);
    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }

};

const get = (id: number) => {

    if (isAuthorized('announcement.show')) {
        return http.get<IAnnouncementResponse>(`/announcement/${id}`);
    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const getFile = (fileName: any) => {
    return http.get<any>(`/announcement/download?file=${fileName}`, { responseType: 'arraybuffer' });
};

const update = (id: number, data: IAnnouncementRequest, file: any) => {
    const formData = new FormData();
    if (file) {
        formData.append("thumbnail", file);
    }
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("cover", data.cover);
    // formData.append("thumbnail", data.cover);

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    };

    if (isAuthorized('announcement.update')) {
        return http.patch<IAnnouncementRequest>(`/announcement/${id}`, formData, config);
    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const remove = (id: number) => {

    if (isAuthorized('announcement.delete')) {
        return http.delete<IAnnouncementRequest>(`/announcement/${id}`);
    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const AnnouncementService = {
    create,
    getAll,
    get,
    update,
    remove,
    getFile
}

export default AnnouncementService;