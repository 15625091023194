import { http } from "../http-common";
import { IUserListResponse, IUserRequest, IUserResponse } from "../types/User";
import { isAuthorized, openNotification } from "../utils";

const getMe = () => {
    return http.get<IUserResponse>(`/users/me`);
};

const getAll = (filter = "") => {
    if (isAuthorized('users.list')) {
        return http.get<IUserListResponse>(`/users${filter}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const updateMe = (data: any) => {
    return http.put<IUserRequest>(`/users/me`, data);

};

const update = (userId: any, data: any) => {
    return http.patch<IUserRequest>(`/users/${userId}`, data);

    if (isAuthorized('users.update')) {
        return http.patch<IUserRequest>(`/users/${userId}`, data);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const UserService = {
    getMe,
    getAll,
    updateMe,
    update,
};

export default UserService;