import { http } from "../http-common";

const getAllMe = () => {
    return http.get<any>(`/video-histories/me`);
};

const getAllMeStatistics = () => {
    return http.get<any>(`/video-histories/me/statistics`);
};

const create = (data: any) => {
    return http.post<any>(`/video-histories`, data);
};

const get = (id: any) => {
    return http.get<any>(`/students/${id}/video-histories`);
};

const VideoHistoryService = {
    create,
    getAllMe,
    get,
    getAllMeStatistics
}

export default VideoHistoryService;