import { Button, Card, Col, Form, Radio, Row, Select, Image, Input } from 'antd'
import QuestionService from '../../../services/QuestionService';
import { openNotification } from '../../../utils';
import { useEffect, useState } from 'react';
import { IQuestionRequest } from '../../../types/Question';

const { Item } = Form;

interface QuestionViewProps {
  questionId: number;
  getTrigger: any;
  setCurrentQuestionId: any;
}

const QuestionEdit: React.FC<QuestionViewProps> = ({ questionId, getTrigger, setCurrentQuestionId }) => {
  const [subjectId, setSubjectId] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false)
  const [answerType, setAnwserType] = useState<boolean>(false)

  const [form] = Form.useForm();
  const [pdfUrl, setPdfUrl] = useState<string>('');

  useEffect(() => {
    if (questionId) {
      getService();
    }
  }, [questionId]);

  const getService = () => {
    setLoading(true)
    QuestionService.get(questionId)
      .then((response: any) => {
        const data = response.data
        setSubjectId(data?.subjectId)
        form.setFieldsValue({
          correctAnswer: data?.correctAnswer,
          answerType: data?.answerType,
          videoUrl: data?.videoUrl,
          file: data?.file,
        });
        setAnwserType(data?.answerType);
        getFile(data?.question);
        setLoading(false)
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const getFile = (fileName: any) => {
    QuestionService.getFile(fileName).then((response: any) => {
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const url = URL.createObjectURL(pdfBlob);
      setPdfUrl(url);
    }).catch((e: Error) => {

    });

  }

  const update = (values: any) => {
    let postObj: IQuestionRequest = {
      "correctAnswer": values.correctAnswer,
      "answerType": values.answerType,
      "vimeoId": values.vimeoId,
      // "file": values.url,
      "subjectId": subjectId !== undefined ? subjectId : 0 
    };

    QuestionService.update(questionId, postObj)
      .then((response: any) => {
        openNotification('success', 'Başarılı', 'Soru Başarıyla Düzenlendi')
        setCurrentQuestionId(null)
        getTrigger()
      }).catch((e: Error) => {
        openNotification('error', 'Başarısız', 'Soru Düzenlenemedi.')
        console.log(e)
      });
  }

  return (
    <>
      <Card className='border-radius-md' loading={loading}>
        <Row justify={'space-between'} align={'middle'}>
          <Col><h3 className='fs-24'>Soruyu Düzenle</h3></Col>
        </Row>
        <Row justify={'center'}>
          <Col xl={24} xs={24}>
            <Form form={form} onFinish={update} layout="vertical">
              {pdfUrl && <Image src={pdfUrl} width="100%" />}
              <Item
                name="answerType"
                label="Soru Tipi"
                rules={[
                  {
                    required: true,
                    message: 'Soru Tipi zorunlu',
                  }]}
              >
                <Radio.Group defaultValue={answerType}>
                  <Radio value={2}>Doğru/Yanlış</Radio>
                  <Radio value={4}>4 Cevaplı</Radio>
                  <Radio value={5}>5 Cevaplı</Radio>
                </Radio.Group>
              </Item>
              <Item
                name="correctAnswer"
                label="Doğru Cevap"
                rules={[
                  {
                    required: true,
                    message: 'Doğru cevap zorunlu',
                  }]}
              >
                <Select options={
                  [
                    { value: "A", label: "Doğru - A" },
                    { value: "B", label: "Yanlış - B" },
                    { value: "C", label: "C" },
                    { value: "D", label: "D" },
                    { value: "E", label: "E" },
                  ]
                } />
              </Item>
              <Item
                name="videoUrl"
                label="Video linki"
                rules={[
                  {
                    required: true,
                    message: 'Video linki zorunlu',
                  }]}
              >
                <Input />
              </Item>
              <Item>
                <Row justify={'end'}>
                  <Button className='btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text' htmlType="submit">
                    Kaydet
                  </Button>
                </Row>
              </Item>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default QuestionEdit