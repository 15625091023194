import React, { useEffect, useRef } from 'react';
import Player from '@vimeo/player';

interface VimeoPlayerProps {
  videoId: string;
}

const VimeoPlayer: React.FC<VimeoPlayerProps> = ({ videoId }) => {
  const playerRef = useRef<HTMLIFrameElement>(null);

  return (
    <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
      <iframe
        ref={playerRef}
        src={`https://player.vimeo.com/video/${videoId}?badge=0&autopause=0&player_id=0&app_id=58479`}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
        title="Vimeo Video Player"
      ></iframe>
    </div>
  );
};

export default VimeoPlayer;
