import { Button, Card, Col, Popconfirm, Row, Table } from 'antd'
import { useEffect, useState } from 'react'
import { formatJustDate, openNotification } from '../../utils';
import QuizService from '../../services/QuizService';
import { NavLink } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';

const Quiz = () => {
    const [loading, setLoading] = useState(false);
    const [quizzes, setQuizzes] = useState<any[]>([]);

    useEffect(() => {
        getQuizzes()
    }, [])

    const removeQuiz = (id: any) => {
        QuizService.remove(id)
            .then((response: any) => {
                getQuizzes();
                openNotification('success', 'Başarılı', 'Sınav Başarıyla Silindi.')
            }).catch((e: Error) => {
                openNotification('error', 'Başarısız', 'Sınav silinemedi.')
            });
    }

    const getQuizzes = () => {
        setLoading(true)
        QuizService.getAll()
            .then((response: any) => {
                const data = response.data
                setQuizzes(data)
                setLoading(false)
            })
            .catch((e: Error) => {
                console.log(e);
                setLoading(false)
            });
    };

    const columns = [
        {
            title: 'Sınav Adı',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Oluşturma Tarihi',
            key: 'action',
            render: (_: any, record: any) => (
                <>
                    {formatJustDate(record.createdAt)}
                </>
            )
        },
        {
            title: '',
            key: 'action',
            render: (_: any, record: any) => (
                <Row align={'middle'} gutter={24} justify={'end'}>
                    <Col>
                        <NavLink to={`/quiz/${record.id}`}>
                            <Button className='btn btn-hover-dark bg-saha-purple font-color-white fw-700'>Detay</Button>
                        </NavLink>
                    </Col>
                    <Col>
                        <Popconfirm
                            title="Sınav silinsin mi?"
                            onConfirm={() => removeQuiz(record.id)}
                            okText="Evet"
                            cancelText="Hayır"
                        >
                            <DeleteOutlined
                                className='btn fs-16' />
                        </Popconfirm>
                    </Col>
                </Row>
            )
        },

    ];
    return (
        <>
            <Card className='border-radius-md'>
                <Row justify={'space-between'} align={'middle'}>
                    <Col>
                        <h3 className='fs-24'>
                            Sınavlar
                        </h3>
                    </Col>
                    <Col>
                        <NavLink to={'/quiz/create'}>
                            <Button className='btn btn-hover-dark bg-saha-purple font-color-white fw-700'>
                                Yeni Sınav Oluştur
                            </Button>
                        </NavLink>
                    </Col>
                </Row>
                <Table className='bg-white-table-cell' loading={loading} dataSource={quizzes} columns={columns} rowKey="id" />
            </Card>
        </>
    )
}

export default Quiz