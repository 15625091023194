import { Card, Drawer, Divider, Tag, Switch, Image, Button, DatePicker, Form, Row, Tooltip, Avatar } from 'antd';
import React, { useEffect, useState } from 'react';
import { openNotification } from '../../utils';
import AssignmentService from './../../services/AssignmentService';
import './assignment-detail.css';
import moment from 'moment';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/tr_TR';

const AssignmentDetail: React.FC<any> = ({ item, getLessonsAndAssignments }) => {
    console.log(item)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(item.isDone);
    const [filesUrl, setFilesUrl] = useState<any>({});
    const [filesUrl2, setFilesUrl2] = useState<any>({});

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (isModalOpen) {

            const fetchFile = async () => {
                if (item?.image) {
                    try {
                        const response = await AssignmentService.getFile(item.image);
                        const blobType = response.headers['content-type'];
                        const fileBlob = new Blob([response.data], { type: blobType });
                        setFilesUrl(URL.createObjectURL(fileBlob));
                    } catch (error) {
                        // Hata mesajını konsola yazdırın.
                        console.error('File fetching failed:', error);
                    }
                }
                if (item?.responseImage) {
                    try {
                        const response = await AssignmentService.getFile(item.responseImage);
                        const blobType = response.headers['content-type'];
                        const fileBlob = new Blob([response.data], { type: blobType });
                        setFilesUrl2(URL.createObjectURL(fileBlob));
                    } catch (error) {
                        // Hata mesajını konsola yazdırın.
                        console.error('File fetching failed:', error);
                    }
                }
            }
            fetchFile();
        }

    }, [isModalOpen])


    const onChangeDone = (newChecked: any) => {
        console.log();
        AssignmentService.update(item.id, { isDone: newChecked })
            .then((response: any) => {
                openNotification('success', 'Başarılı', 'Ders Statüsü Değiştirildi.');
                getLessonsAndAssignments();
                setLoading(false);
            })
            .catch((e: Error) => {
                console.log(e)
                setLoading(false);
            });
    };

    return (
        <>
            <Button
                style={{ cursor: 'pointer', marginBottom: 10 }}
                onClick={showModal}
            >
                {/* {item.studentName ?? ''} */}
                Detay
            </Button>

            {/* <Button className='mr-15 assignment-detail' key={item?.id} onClick={showModal} type={item.status == 'DONE' ? 'default' : 'primary'} size="small">{item?.title}</Button> */}
            <Drawer title="Ödev Detay" placement="right" onClose={handleCancel} open={isModalOpen} style={{ padding: '0px', margin: '0px', border: 'none' }}>
                <Card loading={loading} className='assignment-detail-card' style={{ padding: '0px', margin: '0px', border: 'none' }}>
                    <h4>Öğrenci Bilgileri</h4>
                    <p>{item.studentName}</p>
                    <Divider />
                    <h4>Ders</h4>
                    <p>{item?.lessonName}</p>
                    <Divider />
                    <h4>Ödev İçerik</h4>
                    <p>{item?.content}</p>
                    {/* <Divider />
                    <h4>Ödev Döküman</h4>
                    <p>{item?.document}</p> */}

                    {item.image ?
                        <>
                            <Divider />
                            <h4>Soru Görsel</h4>
                            {filesUrl && (
                                <Image src={filesUrl} fallback="error.png" />
                            )}
                        </> : null
                    }

                    {item.responseContent ?
                        <>
                            <Divider />
                            <h4>Cevap Detay</h4>
                            <p>{item.responseContent}</p>
                        </> : null
                    }
                    {item.responseImage ?
                        <>
                            <Divider />
                            <h4>Cevap Görsel</h4>
                            {filesUrl2 && (
                                <Image src={filesUrl2} fallback="error.png" />
                            )}
                        </> : null
                    }
                    <Divider />
                    <h4>Statü</h4>
                    <Switch
                        checked={checked} // Yerel state'i kullanın
                        onChange={(newChecked) => {
                            setChecked(newChecked); // Yerel state'i güncelleyin
                            onChangeDone(newChecked); // Yeni durumu `onChangeDone` işlevine iletin
                        }}
                    />

                    {/* <Divider />
                    <h4>Cevap Döküman</h4>
                    <p>{item.responseContent}</p> */}
                    {/* <h4>Ders Tarihi</h4> */}

                    {/* <Divider /> */}
                    <Divider />
                </Card>
            </Drawer >
        </>
    );
}
export default AssignmentDetail;
