import React, { useEffect, useState } from 'react';
import { arrayMove } from '@dnd-kit/sortable';
import type { DragEndEvent } from '@dnd-kit/core';
import { Breadcrumb, Button, Card, Col, Popconfirm, Row, type TableColumnsType } from 'antd';
import { formatDate } from '../utils';
import SortableTable, { DragHandle } from '../components/Common/SortableTable';
import { QuestionCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import LessonService from '../services/LessonService';
import VideoService from '../services/VideoService';
import UnitService from '../services/UnitService';
import UnitCreate from './SubjetDetail/Create/UnitCreate';
import LessonUpdate from './SubjetDetail/Update/LessonUpdate';
import { removeItem } from '../services/RemoveService';

interface DataType {
    id: number;
    title: string;
    sortOrder: number;
}

interface LessonDetailProps {
    lesson: any;
    getTrigger: any;
}

const LessonDetail: React.FC<LessonDetailProps> = ({ lesson, getTrigger }) => {
    const [dataSource, setDataSource] = useState<DataType[]>([]);
    const [loading, setLoading] = useState(false);
    const [actionNode, setActionNode] = useState<any>();

    useEffect(() => {
        getData();
        console.log('lesson', lesson)
    }, [lesson?.id])

    const columns: TableColumnsType<DataType> = [
        { key: 'sort', align: 'center', width: 80, render: () => <DragHandle /> },
        { title: '#', dataIndex: 'id', width: 40 },
        { title: 'Ders', dataIndex: 'name', width: 200 },
        {
            title: 'Kayıt Tarihi',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 80,
            render: (createdAt) => (
                <>
                    {formatDate(createdAt)}
                </>
            ),
        },
    ];

    useEffect(() => {
        if (actionNode?.action === 'delete') {
            removeItem(actionNode).then(() => {
                getTrigger();
            })
                .catch((error) => {
                    console.error('Silme işlemi başarısız:', error);
                });
        }
    }, [actionNode])

    const getData = () => {
        LessonService.get(lesson?.id)
            .then((response: any) => {
                const filteredUnits = response?.data?.units.filter((unit: any) => unit.gradeId === lesson?.gradeId);
                setDataSource(filteredUnits);
            })
            .catch((e: Error) => {
                // Handle the error if needed
            });
    }


    const updateSortOrders = (data: any) => {
        setLoading(true);
        UnitService.updateSortOrders(data)
            .then((response: any) => {
                getTrigger();
                setLoading(false);
            })
            .catch((e: Error) => {
                console.log(e);
                setLoading(false);
            });
    };

    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {
            setDataSource((prevState) => {
                const activeIndex = prevState.findIndex((record) => record.id === active?.id);
                const overIndex = prevState.findIndex((record) => record.id === over?.id);
                const newState = arrayMove(prevState, activeIndex, overIndex);

                const updatedSortOrder = newState.map((item, index) => ({
                    id: item.id,
                    sortOrder: index + 1
                }));

                updateSortOrders(updatedSortOrder);

                return newState;
            });
        }
    };
    return (
        <Card title={lesson.name}
            extra={
                <Row gutter={[16, 16]} align={"middle"} >
                    <Col>
                        <Breadcrumb separator=">">
                            {lesson.breadcrumb.slice(0, -1).map((item: any, index: number) => (
                                <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
                            ))}
                        </Breadcrumb>
                    </Col>
                    <Col>
                        <LessonUpdate lessonId={lesson?.id} getTrigger={() => { getTrigger(); getData(); }}></LessonUpdate>
                    </Col>
                    <Col>
                        <Popconfirm
                            title="Dersi Sil"
                            description="Dersi silmek istediğinize emin misiniz?"
                            okText="Evet"
                            cancelText="Hayır"
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            onConfirm={() => setActionNode({ id: lesson?.id, data: null, gradeId: lesson?.gradeId, action: 'delete', type: 'lesson' })}
                        >
                            <Button danger onClick={e => e.stopPropagation()}><DeleteOutlined /></Button>
                        </Popconfirm>
                    </Col>
                </Row>}>
            <Row align={"middle"} justify={"space-between"} className='mb-16'>
                <Col>
                    Ünite Listesi
                </Col>
                <Col>
                    <UnitCreate getTrigger={() => { getTrigger(); getData(); }} gradeId={lesson?.gradeId} lessonId={lesson?.id}></UnitCreate>
                </Col>
            </Row>
            <SortableTable<DataType>
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                onDragEnd={onDragEnd}
                defaultSortField="sortOrder"
            />
        </Card>
    );
};

export default LessonDetail;
