import { Card, Row } from 'antd';
import { ReactComponent as LessonIcon } from './../../assets/Dashboard/live-lesson-badge.svg'
import { ReactComponent as Question } from './../../assets/Dashboard/questions-badge.svg'

const CountCard: React.FC<any> = ({ text, count, iconType }) => {
    return (
        <Card>
            <Row style={{ marginBottom: 10 }}>
                {iconType == 'lesson' ?? <LessonIcon />}
                {iconType == 'question' ?? <Question />}
            </Row>
            <Row >
                <span style={{ fontSize: 24, fontWeight: 'bold' }}>
                    {count}
                </span>
            </Row>
            <Row>
                <span style={{ fontSize: 14, fontWeight: 'bold', color: 'gray'}}>
                    {text}
                </span>
            </Row>
        </Card>
    );
};

export default CountCard;
