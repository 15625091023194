import {http} from "../http-common";
import { IPackageRequest, IPackageResponse } from "../types/Package";
import { isAuthorized, openNotification } from "../utils";

const getAllPackagesByAffilate = () => {
        return http.get<any[]>(`/student-packages/affiliate/me`);
};

const getAll = () => {
    if (isAuthorized('packages.list')) {
        return http.get<IPackageResponse>(`/package`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const create = (data: IPackageRequest) => {
    if (isAuthorized('packages.create')) {
        return http.post<IPackageRequest>(`/package`, data);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const get = (id: number) => {
    if (isAuthorized('packages.show')) {
        return http.get<IPackageResponse>(`/package/${id}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const update = (id: number, data: IPackageRequest) => {
    if (isAuthorized('packages.update')) {
        return http.patch<IPackageRequest>(`/package/${id}`, data);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const remove = (id: number) => {
    if (isAuthorized('packages.delete')) {
        return http.delete<IPackageRequest>(`/package/${id}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const PackageService = {
    create,
    getAll,
    get,
    update,
    remove,
    getAllPackagesByAffilate
}

export default PackageService;