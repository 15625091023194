import { Button, Col, Divider, Form, Input, Modal, Row, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useState } from 'react'
import UnitService from '../../../services/UnitService';
import { openNotification } from '../../../utils';
import LessonService from '../../../services/LessonService';
import { IUnitRequest } from '../../../types/Unit';

interface CreateUnitModalProps {
  showModal: boolean;
  setShowModal: any;
  unitId: number;
  getTrigger: any;
}

const { Item } = Form;

const EditUnitModal: React.FC<CreateUnitModalProps> = ({ showModal, setShowModal, unitId, getTrigger }) => {
  const [lessons, setLessons] = useState<any>([]);
  const [lessonId, setLessonId] = useState<any>();
  const [fetchedData, setFetchedData] = useState(false); // State to track whether data has been fetched
  const [form] = Form.useForm();

  useEffect(() => {
    if (showModal && !fetchedData) { // Check if modal is shown and data has not been fetched
      getService();
      setFetchedData(true); // Update state to indicate data has been fetched
    }
  }, [showModal]);

  const getService = () => {
    UnitService.get(unitId)
      .then((response: any) => {
        const data = response.data
        setLessonId(data?.lessonId)
        form.setFieldsValue({
          name: data?.name,
          description: data?.description,
        });
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };


  const handleUnitCreateCancel = () => {
    setShowModal(false);
    setFetchedData(false)
  };

  useEffect(() => {
    form.resetFields();
  }, [showModal])

  const getLessons = () => {
    LessonService.getAll()
      .then((response: any) => {
        const data = response.data
        const options = data.map((lesson: { id: number; name: string; }) => ({
          label: lesson.name, // Kullanıcıya gösterilecek metin
          value: lesson.id, // Form üzerinden gönderilecek değer
        }));
        setLessons(options);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const update = (values: any) => {
    let postObj: any = {
      "name": values.name,
      "description": values.description,
    };

    UnitService.update(unitId,postObj)
      .then((response: any) => {
        openNotification('success', 'Başarılı', 'Ünite Başarıyla Düzenlendi.')
        setShowModal(false)
        setFetchedData(false)
        getTrigger();
      }).catch((e: Error) => {
        openNotification('error', 'Başarısız', 'Ünite Düzenlenemedi.')
        console.log(e)
      });
  }

  const findLessonName = (lessonId: number) => {
    const lesson = lessons.find((lesson: any) => lesson.value === lessonId);
    return lesson ? lesson.label : '';
}
  
  return (
    <>
      <Modal title={`Ünite Düzenle - ${findLessonName(lessonId)}`} open={showModal} closeIcon={<div onClick={() => setShowModal(false) && setFetchedData(false)}>X</div>}
        footer={
          <></>
        }
      >
        <Divider></Divider>
        <Form form={form} onFinish={update} layout="vertical">
          <Item
            name="name"
            label="Ünite"
            rules={[
              {
                required: true,
                message: 'Ünite adı zorunlu',
              }]}
          >
            <Input />
          </Item>
          <Item
            name="description"
            label="Açıklama"
          >
            <TextArea />
          </Item>
          
          <Divider></Divider>
          <Row gutter={8}>
            <Col span={12}>
              <Button onClick={handleUnitCreateCancel} className='w-full height-40'>
                İptal
              </Button>
            </Col>
            <Col span={12}>
              <Item>
                <Button htmlType="submit" className='w-full height-40 btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text'>
                  Kaydet
                </Button>
              </Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default EditUnitModal