import { Button, Card, Col, Empty, Form, Input, Modal, Row } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import VideoService from '../../../services/VideoService';
import _ from 'lodash';

interface VideoListProps {
    setVideoId: (videoId: string) => void;
}

const VideoList: React.FC<VideoListProps> = ({ setVideoId }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [videoList, setVideoList] = useState<any[]>([]);
    const [filteredVideoList, setFilteredVideoList] = useState<any[]>([]);

    useEffect(() => {
        getVimeoList();
    }, [])


    const getVimeoList = () => {
        setLoading(true);
        VideoService.getVimeoList()
            .then((response: any) => {
                const data = response.data.data;
                setVideoList(data);
                setLoading(false);
            })
            .catch((e: Error) => {
                console.log(e);
                setLoading(false);
            });
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchText(value);

        if (value.length > 2) {
            debouncedGetVimeo(value);
        } else {
            setFilteredVideoList([]);
        }
    };

    const normalizeString = (str: string): string => {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
            .replace(/ğ/g, 'g')
            .replace(/ü/g, 'u')
            .replace(/ş/g, 's')
            .replace(/ı/g, 'i')
            .replace(/ö/g, 'o')
            .replace(/ç/g, 'c')
            .replace(/Ğ/g, 'G')
            .replace(/Ü/g, 'U')
            .replace(/Ş/g, 'S')
            .replace(/İ/g, 'I')
            .replace(/Ö/g, 'O')
            .replace(/Ç/g, 'C');
    };

    const debouncedGetVimeo = useCallback(
        _.debounce((value: string) => {
            const lowercasedValue = normalizeString(value.toLowerCase());
            console.log("Normalized arama değeri:", lowercasedValue);

            const filteredVideos = videoList.filter((video: any) => {
                const name = video.name ? normalizeString(video.name.toLowerCase()) : '';
                const description = video.description ? normalizeString(video.description.toLowerCase()) : '';
                console.log("Normalized video adı:", name);
                console.log("Normalized video açıklaması:", description);

                const nameIncludes = name.includes(lowercasedValue);
                const descriptionIncludes = description.includes(lowercasedValue);
                console.log("Ad içerir mi:", nameIncludes);
                console.log("Açıklama içerir mi:", descriptionIncludes);

                return nameIncludes || descriptionIncludes;
            });

            console.log("Filtrelenmiş videolar:", filteredVideos);
            setFilteredVideoList(filteredVideos);
        }, 300),
        [videoList]
    );


    const select = (video: any) => {
        setVideoId(video);
        setIsOpen(false);
    };

    return (
        <>
            <Button className='btn bg-saha-purple btn-hover-dark fw-700 font-color-white' onClick={() => { setIsOpen(true) }}>
                Video Listesi
            </Button>
            <Modal
                title="Video Listesi"
                open={isOpen}
                onCancel={() => { setIsOpen(false) }}
                width={800}
                footer
            >
                <Row>
                    <Col span={24}>
                        <Form>
                            <Form.Item
                                name="search"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Lütfen en az 3 karakter girin',
                                        min: 3,
                                    },
                                ]}
                            >
                                <Input
                                    disabled={loading}
                                    size="large"
                                    className=' mt-12'
                                    placeholder="Video ara"
                                    value={searchText}
                                    onChange={handleSearchChange}
                                />
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={24}>
                        <Card loading={loading} className='mt-12'>
                            <Row gutter={[24, 24]}>
                                {filteredVideoList?.map((item: any, index: number) => (
                                    <Col span={12} className='mb-24' key={index} >
                                        <Card cover={<img alt={item.name} src={item.pictures.sizes[item.pictures.sizes.length - 1].link} />}>
                                            <Row align={'middle'} justify={'space-between'}>
                                                <Col>
                                                    {item.name}
                                                </Col>
                                                <Col>
                                                    <Button onClick={() => select(item)} className='btn bg-saha-purple btn-hover-dark fw-700 font-color-white'>
                                                        Seç
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                            {(filteredVideoList.length == 0) && <Row justify={"center"} align={"middle"}><Empty description="Video seçmek için arama yapınız"></Empty></Row>}
                        </Card>
                    </Col>
                </Row>
            </Modal>
        </>

    )
}

export default VideoList