import { Button, Col, Divider, Drawer, Form, Input, Row } from 'antd'
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useState } from 'react'
import UnitService from '../../../services/UnitService';
import { openNotification } from '../../../utils';
import LessonService from '../../../services/LessonService';
import { EditOutlined } from '@ant-design/icons'

interface UnitUpdateProps {
    unitId: number;
    getTrigger: any;
}

const { Item } = Form;

const UnitUpdate: React.FC<UnitUpdateProps> = ({ unitId, getTrigger }) => {
    const [form] = Form.useForm();
    const [showDrawer, setShowDrawer] = useState(false);


    useEffect(() => {
        if (showDrawer) { // Check if modal is shown and data has not been fetched
            getService();
        }
    }, [showDrawer]);

    const getService = () => {
        UnitService.get(unitId)
            .then((response: any) => {
                const data = response.data
                form.setFieldsValue({
                    name: data?.name,
                    description: data?.description,
                });
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };


    const handleUnitCreateCancel = () => {
        setShowDrawer(false);
    };

    useEffect(() => {
        form.resetFields();
    }, [showDrawer])

    const getLessons = () => {
        LessonService.getAll()
            .then((response: any) => {
                const data = response.data
                const options = data.map((lesson: { id: number; name: string; }) => ({
                    label: lesson.name, // Kullanıcıya gösterilecek metin
                    value: lesson.id, // Form üzerinden gönderilecek değer
                }));
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const update = (values: any) => {
        let postObj: any = {
            "name": values.name,
            "description": values.description,
        };

        UnitService.update(unitId, postObj)
            .then((response: any) => {
                openNotification('success', 'Başarılı', 'Ünite Başarıyla Düzenlendi.')
                setShowDrawer(false)
                getTrigger();
            }).catch((e: Error) => {
                openNotification('error', 'Başarısız', 'Ünite Düzenlenemedi.')
                console.log(e)
            });
    }

    return (
        <>
            <Button onClick={() => setShowDrawer(true)}>
                <EditOutlined />
            </Button>
            <Drawer title="Ünite Düzenle" placement="right" onClose={() => setShowDrawer(false)} open={showDrawer}>
                <Form form={form} onFinish={update} layout="vertical">
                    <Item
                        name="name"
                        label="Ünite"
                        rules={[
                            {
                                required: true,
                                message: 'Ünite adı zorunlu',
                            }]}
                    >
                        <Input />
                    </Item>
                    <Item
                        name="description"
                        label="Açıklama"
                    >
                        <TextArea />
                    </Item>

                    <Divider></Divider>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Button onClick={handleUnitCreateCancel} className='w-full height-40'>
                                İptal
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Item>
                                <Button htmlType="submit" className='w-full height-40 btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text'>
                                    Kaydet
                                </Button>
                            </Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    )
}

export default UnitUpdate