import { http } from "../http-common";
import { ICountdownRequest, ICountdownResponse } from "../types/Countdown";
import { isAuthorized, openNotification } from "../utils"; 


const getAll = () => {
    return http.get<ICountdownResponse>(`/assessment-exam`);

    if (isAuthorized('assessment-exam.list')) {
        return http.get<ICountdownResponse>(`/assessment-exam`);
    } else {
        openNotification('error','Başarısız','Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const create = (data: ICountdownRequest) => {
    return http.post<ICountdownRequest>(`/assessment-exam`, data);

    if (isAuthorized('assessment-exam.create')) {
        return http.post<ICountdownRequest>(`/assessment-exam`, data);
    } else {
        openNotification('error','Başarısız','Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const get = (id: number) => {
    return http.get<ICountdownResponse>(`/assessment-exam/${id}`);

    if (isAuthorized('assessment-exam.show')) {
        return http.get<ICountdownResponse>(`/assessment-exam/${id}`);
    } else {
        openNotification('error','Başarısız','Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const update = (id: number, data: ICountdownRequest) => {
    return http.patch<ICountdownRequest>(`/assessment-exam/${id}`, data);

    if (isAuthorized('assessment-exam.update')) {
        return http.patch<ICountdownRequest>(`/assessment-exam/${id}`, data);
    } else {
        openNotification('error','Başarısız','Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const remove = (id: number) => {
    return http.delete<ICountdownRequest>(`/assessment-exam/${id}`);

    if (isAuthorized('assessment-exam.delete')) {
        return http.delete<ICountdownRequest>(`/assessment-exam/${id}`);
    } else {
        openNotification('error','Başarısız','Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const AssessmentExamService = {
    create,
    getAll,
    get,
    update,
    remove
}

export default AssessmentExamService;
