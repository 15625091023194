import { Col, Row } from 'antd'
import { NavLink, useNavigate } from 'react-router-dom'
import { ReactComponent as AnnouncementIcon } from '../../assets/Layout/announcement-icon.svg'
import { ReactComponent as ExamIcon } from '../../assets/Layout/exam-icon.svg'
import { ReactComponent as LogoutIcon } from '../../assets/Layout/logout-icon.svg'
import { ReactComponent as TeacherIcon } from '../../assets/Layout/teachers-icon.svg'

const Pages = () => {
    const navigate = useNavigate()

    const logout = () => {
        localStorage.removeItem('accessToken')
        navigate('/login')
    }

    return (
        <>
            <Row>
                <p className='font-color-gray upper-case fw-700 fs-12 ml-16'>Kullanıcı Yönetimi</p>
                <Col span={24}>
                    <div className='menu-wrapper w-full'>
                        <NavLink className={({ isActive }) =>
                            isActive ? 'font-color-dark fw-700' : ''
                    } to={'/applications'}>
                            <Row className='pl-16' gutter={16} justify={"start"} align={"middle"}>
                                <Col>
                                    <TeacherIcon className='mt-8' />
                                </Col>
                                <Col>
                                    <p className='font-color-gray'>Başvurular</p>
                                </Col>
                            </Row>
                        </NavLink>
                    </div>
                </Col>
                <div className='menu-wrapper w-full'>
                    <NavLink className={({ isActive }) =>
                            isActive ? 'font-color-dark fw-700' : ''
                } to={'/users'}>
                        <Row className='pl-16' gutter={16} justify={"start"} align={"middle"}>
                            <Col>
                                <TeacherIcon className='mt-8' />
                            </Col>
                            <Col>
                                <p className='font-color-gray'>Kullanıcılar</p>
                            </Col>
                        </Row>
                    </NavLink>
                </div>
                {/* <div className='menu-wrapper ml-16'>
                    <NavLink className={'font-color-gray'} to={'javascript:;'} onClick={() => (logout())}>
                        <Row gutter={16} justify={"start"} align={"middle"}>
                            <Col>
                                <LogoutIcon className='mt-8' />
                            </Col>
                            <Col>
                                <p className='text fs-14 font-color-gray'>Çıkış</p>
                            </Col>
                        </Row>
                    </NavLink>
                </div> */}
            </Row >
            <Row>
                <p className='font-color-gray upper-case fw-700 fs-12 ml-16'>Web Sitesi</p>
                <Col span={24}>
                    <div className='menu-wrapper w-full'>
                        <NavLink className={({ isActive }) =>
                            isActive ? 'font-color-dark fw-700' : ''
                        } to={'/assessment-exam'}>
                            <Row className='pl-16' gutter={16} justify={"start"} align={"middle"}>
                                <Col>
                                    <ExamIcon className='mt-8' />
                                </Col>
                                <Col>
                                    <p className=' font-color-gray fs-14 '>Sınavlar</p>
                                </Col>
                            </Row>
                        </NavLink>
                    </div>
                </Col>
                <div className='menu-wrapper w-full'>
                    <NavLink className={({ isActive }) =>
                        isActive ? 'font-color-dark fw-700' : ''
                    } to={'/announcements'}>
                        <Row className='pl-16' gutter={16} justify={"start"} align={"middle"}>
                            <Col>
                                <AnnouncementIcon className='mt-8' />
                            </Col>
                            <Col>
                                <p className='font-color-gray fs-14'>Duyurular</p>
                            </Col>
                        </Row>
                    </NavLink>
                </div>
                {/* <div className='menu-wrapper ml-16'>
                    <NavLink className={'font-color-gray'} to={'javascript:;'} onClick={() => (logout())}>
                        <Row gutter={16} justify={"start"} align={"middle"}>
                            <Col>
                                <LogoutIcon className='mt-8' />
                            </Col>
                            <Col>
                                <p className='text fs-14 font-color-gray'>Çıkış</p>
                            </Col>
                        </Row>
                    </NavLink>
                </div> */}
            </Row >
        </>
    )
}

export default Pages