import {http} from "../http-common";
import { ICurriculumRequest, ICurriculumResponse } from "../types/Curriculum";
import { isAuthorized, openNotification } from "../utils";

const getAll = () => {
    if (isAuthorized('curriculum.list')) {
        return http.get<ICurriculumResponse>(`/curriculum`);
    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const create = (data: ICurriculumRequest) => {
    if (isAuthorized('curriculum.create')) {
        return http.post<ICurriculumRequest>(`/curriculum`, data);
    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const get = (id: number) => {
    if (isAuthorized('curriculum.show')) {
        return http.get<ICurriculumResponse>(`/curriculum/${id}`);
    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const getGradesByCurriculumId = (id: number) => {
    if (isAuthorized('curriculum.show')) {
        return http.get<ICurriculumResponse>(`/curriculum/${id}/grade`);
    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const update = (id: number, data: ICurriculumRequest) => {
    if (isAuthorized('curriculum.update')) {
        return http.patch<ICurriculumRequest>(`/curriculum/${id}`, data);
    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const remove = (id: number) => {
    if (isAuthorized('curriculum.delete')) {
        return http.delete<ICurriculumRequest>(`/curriculum/${id}`);
    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const CurriculumService = {
    create,
    getAll,
    get,
    update,
    remove,
    getGradesByCurriculumId
}

export default CurriculumService;