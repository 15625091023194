import React, { useState } from 'react';
import { Modal, Form, Input, Button, Spin, message } from 'antd';
import DocumentService from '../../../services/DocumentService';
import { PDFDocument, rgb, degrees } from 'pdf-lib'; // Ensure you have pdf-lib installed
import { turkishToEnglish } from '../../../utils';

interface ShareDocumentModalProps {
  document: any; // Adjust the type according to your document structure
}

const ShareDocumentModal: React.FC<ShareDocumentModalProps> = ({ document }) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleCreate = () => {
    form.validateFields().then(values => {
      const { name, phone } = values;
      setLoading(true);
      getFile(document.url, name, phone);
    });
  };

  const getFile = (fileName: any, name: string, phone: string) => {
    DocumentService.getFile(fileName)
      .then(async (response: any) => {
        try {
          // Ensure the response data is a Blob
          const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
          const arrayBuffer = await pdfBlob.arrayBuffer(); // Blob'dan ArrayBuffer'a çevirme

          // PDF dosyasını yükle
          const pdfDoc = await PDFDocument.load(arrayBuffer);

          // Her sayfaya filigran ekleme
          const pages = pdfDoc.getPages();
          pages.forEach(page => {
            let fullName = turkishToEnglish(name);

            const { width, height } = page.getSize();
            const fontSize = 25; // Metin boyutunu küçült
            const text = `${fullName}\n${phone}`;
            const textWidth = fontSize * text.length * 0.6; // Yaklaşık metin genişliği

            const yOffsetIncrement = height / 5;

            // Sol yarıda dört metin
            for (let i = 1; i <= 4; i++) {
              page.drawText(text, {
                x: width / 3 - textWidth / 2,
                y: yOffsetIncrement * i,
                size: fontSize,
                color: rgb(0.75, 0.75, 0.75),
                rotate: degrees(40), // 45 derece açı
                opacity: 0.7
              });
            }

            // Sağ yarıda dört metin
            for (let i = 1; i <= 4; i++) {
              page.drawText(text, {
                x: 2 * width / 2 - textWidth / 2,
                y: yOffsetIncrement * i,
                size: fontSize,
                color: rgb(0.75, 0.75, 0.75),
                rotate: degrees(40), // 45 derece açı
                opacity: 0.7
              });
            }
          });

          // Değişikliklerle yeni bir PDF dosyası oluştur
          const newPdfBytes = await pdfDoc.save();

          // Yeni PDF için Blob oluştur ve URL'yi ayarla
          const newPdfBlob = new Blob([newPdfBytes], { type: 'application/pdf' });
          const url = URL.createObjectURL(newPdfBlob);
          setPdfUrl(url);
        } catch (e) {
          console.error('PDF işleme sırasında hata oluştu:', e);
          message.error('PDF işleme sırasında hata oluştu.');
        } finally {
          setLoading(false);
        }
      }).catch((e: Error) => {
        setLoading(false);
        console.error('PDF dosyasını alırken hata oluştu:', e);
        message.error('PDF dosyasını alırken hata oluştu.');
      });
  };

  const handleOpen = () => {
    setModalVisible(true);
  };

  const handleClose = () => {
    setModalVisible(false);
    form.resetFields();
    setPdfUrl(null);
  };

  return (
    <>
      <Button className='mr-12' type="primary" onClick={handleOpen}>Paylaş</Button>
      <Modal
        title="Doküman Paylaş"
        visible={isModalVisible}
        onCancel={handleClose}
        footer={null}
      >
        <Spin spinning={loading}>
          <Form form={form} layout="vertical" onFinish={handleCreate}>
            <Form.Item
              name="name"
              label="Ad Soyad"
              rules={[{ required: true, message: 'Lütfen ad soyad giriniz' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="phone"
              label="Telefon"
              rules={[
                { required: true, message: 'Lütfen telefon giriniz' },
                { len: 10, message: 'Telefon numarası 10 haneli olmalıdır' },
                { pattern: /^\d+$/, message: 'Telefon numarası sadece rakamlardan oluşmalıdır' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Oluştur
              </Button>
            </Form.Item>
          </Form>
          {pdfUrl && (
            <iframe src={pdfUrl} width="100%" height="500px" />
          )}
        </Spin>
      </Modal>
    </>
  );
};

export default ShareDocumentModal;