import { http } from "../http-common";
import { IRoleRequestData, IRoleResponseData } from "../types/Role";
import { isAuthorized, openNotification } from "../utils";

const getAll = () => {
  return http.get<Array<IRoleResponseData>>("/roles");

  if (isAuthorized('role.list')) {
    return http.get<Array<IRoleResponseData>>("/roles");

  } else {
    openNotification('error', 'Başarısız', 'Yetkiniz yok.');
    return Promise.resolve(null);
  }
};

const get = (id: number) => {
  return http.get<IRoleResponseData>(`/roles/${id}`);

  if (isAuthorized('role.show')) {
    return http.get<IRoleResponseData>(`/roles/${id}`);

  } else {
    openNotification('error', 'Başarısız', 'Yetkiniz yok.');
    return Promise.resolve(null);
  }
};

const create = (data: IRoleRequestData) => {
  return http.post<IRoleRequestData>("/roles", data);

  if (isAuthorized('role.create')) {
    return http.post<IRoleRequestData>("/roles", data);

  } else {
    openNotification('error', 'Başarısız', 'Yetkiniz yok.');
    return Promise.resolve(null);
  }
};

const update = (id: number, data: IRoleRequestData) => {
  return http.put<IRoleRequestData>(`/roles/${id}`, data);

  if (isAuthorized('role.update')) {
    return http.put<IRoleRequestData>(`/roles/${id}`, data);

  } else {
    openNotification('error', 'Başarısız', 'Yetkiniz yok.');
    return Promise.resolve(null);
  }
};

const remove = (id: number) => {
  return http.delete<IRoleRequestData>(`/roles/${id}`);

  if (isAuthorized('role.delete')) {
    return http.delete<IRoleRequestData>(`/roles/${id}`);

  } else {
    openNotification('error', 'Başarısız', 'Yetkiniz yok.');
    return Promise.resolve(null);
  }
};

const RoleService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default RoleService;