import { Card, Col, Row } from 'antd'
import { ReactComponent as StudentIcon } from '../../assets/Dashboard/total-students-icon.svg'

const TotalStudent = (data: any) => {
  return (
    <div>
      <Card className='border-radius-md'>
        <Row gutter={24} align={'middle'}>
          <Col>
            <div className='d-flex p-16 bg-cloud border-radius-lg'>
              <StudentIcon />
            </div>
          </Col>
          <Col span={18}>
            <p className='m-0 font-color-gray fs-14 fw-500'>Toplam Öğrenci</p>
            <h3 className='m-0 font-color-dark-blue fs-24 fw-700'>{data?.data?.total ?? '0'}</h3>
            <Row gutter={4}>
              <Col>
                <p className='m-0 font-color-success fs-12 fw-400'>+{data?.data?.newLast30days ?? '0'}</p>
              </Col>
              <Col>
                <p className='m-0 font-color-gray fs-12 fw-400'>yeni öğrenci eklendi</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  )
}

export default TotalStudent