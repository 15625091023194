import React, { useEffect, useState } from 'react';
import { Table, Select, Modal, Form, Upload, Input, Button, Row, DatePicker } from 'antd';
import AssignmentService from '../services/AssignmentService';
import { formatDate, openNotification } from '../utils';
import StudentService from '../services/StudentService';
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  PlusOutlined
} from '@ant-design/icons';
import UserService from '../services/UserService';
import AssignmentDetail from '../components/Assignments/AssignmentDetail';
import LessonService from '../services/LessonService';
import type { GetProp, UploadFile, UploadProps } from 'antd';
import dayjs from 'dayjs';
import Filter from '../components/Assignments/Filter';

const { Option } = Select;

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];


const Assignments = () => {
  const [loading, setLoading] = useState(false);
  const [assignments, setAssignments] = useState<any[]>([]);
  const [students, setStudents] = useState<any[]>([]);
  const [lessons, setLessons] = useState<any>([]);
  const [filterAssignmentId, setFilterAssignmentId] = useState<string | undefined>(undefined);
  const [isModalVisible, setIsModalVisible] = useState(false);
  let authId = localStorage.getItem('id');
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [filter, setFilter] = useState<any>({});

  const getLessonsAndAssignments = async () => {
    setLoading(true);
    try {
      const queryString = Object.keys(filter).map(key => `${key}=${filter[key]}`).join('&');

      const [assignmentResponse, lessonResponse, userResponse] = await Promise.all([
        AssignmentService.getAll(`?${queryString}`),
        LessonService.getAll(),
        UserService.getAll(),
      ]);

      const usersMap = new Map<string, string>();
      (userResponse?.data as any).forEach((user: any) => {
        const fullName = user.firstName + ' ' + user.lastName;
        usersMap.set(user.id, fullName);
      });
      setLessons(lessonResponse?.data)
      StudentService.getAll()
        .then((response: any) => {
          const assignmentsData: any = assignmentResponse?.data;
          const lessonData: any = lessonResponse?.data;

          // Map assignments to a useful structure
          const lessonMap = new Map(lessonData.map((lesson: any) => [lesson.id, lesson.name]));

          // Enrich questions with assignment names
          const enrichedQuestions = assignmentsData
            .filter((question: any) => !question.answer)
            .map((question: any) => ({
              ...question,
              teacherName: usersMap.get(question.userId),
              lessonName: lessonMap.get(question.lessonId),
              studentName: response.data.find((s: any) => s.id === question.studentId)?.firstName + ' ' + response.data.find((s: any) => s.id === question.studentId)?.lastName || 'Öğrenci Bulunamadı',
              answer: 'Öğretmenden Yanıt Bekleniyor'
            }));

          setAssignments(enrichedQuestions);
          setStudents(response.data);
          setLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setLoading(false);
        });


    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLessonsAndAssignments();
  }, [filter]);

  const filteredQuestions = assignments.filter(assigment =>
    filterAssignmentId ? assigment.lessonId === filterAssignmentId : true
  );

  const columns = [
    {
      title: 'Durum',
      dataIndex: 'status',
      key: 'status',
      render: (_: any, record: any) => (
        <>
          {record?.responseContent || record?.responseImage ?
            <>
              {record.isDone ?
                <Row align={'middle'}>
                  <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: 24, marginRight: 5 }} />Tamamlandı
                </Row> :
                <Row align={'middle'}>
                  <CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: 24, marginRight: 5 }} />Eksik veya Hatalı
                </Row>
              }
            </>

            :
            <Row align={'middle'}>
              <CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: 24, marginRight: 5 }} />Cevaplanmadı
            </Row>
          }
        </>
      )
    },
    {
      title: 'Ders Adı',
      dataIndex: 'lessonName',
      key: 'lessonName',
    },
    {
      title: 'Öğretmen Adı',
      dataIndex: 'teacherName',
      key: 'teacherName',
    },
    {
      title: 'Atanan Öğrenci',
      dataIndex: 'studentName',
      key: 'studentName'
    },
    {
      title: 'Ödev Verilme Tarihi',
      dataIndex: 'createdAt',
      render: (text: any) => (
        <>
          {formatDate(text)}
        </>
      )
    },
    {
      title: 'Ödev Tarihi',
      dataIndex: 'dueDate',
      render: (text: any) => (
        <>
          {formatDate(text)}
        </>
      )
    },
    {
      title: 'Detay',
      key: 'action',
      render: (_: any, record: any) => (
        <AssignmentDetail item={record} getLessonsAndAssignments={getLessonsAndAssignments} />
      )
    },

  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCreate = () => {
    form
      .validateFields()
      .then(values => {
        form.resetFields();
        let postObj: any = {
          userId: parseInt(authId || '0', 10),
          studentId: parseInt(values.studentId || '0', 10),
          lessonId: parseInt(values.lessonId || '0', 10),
          dueDate: values.dueDate.format("YYYY-MM-DDTHH:mm"),
          content: values.content,
        };

        AssignmentService.create(postObj, fileList[0])
          .then((response: any) => {
            form.resetFields();
            openNotification('success', 'Başarılı', 'Ödev Oluşturuldu.');
            getLessonsAndAssignments();
            setIsModalVisible(false);
          }).catch((e: Error) => {
            console.log(e)
          });
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const props: UploadProps = {
    onPreview: async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as FileType);
      }

      setPreviewImage(file.url || (file.preview as string));
      setPreviewOpen(true);
    },
    onRemove: (file) => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      if (fileList.length >= 1) {
        openNotification('warning', 'Uyarı', 'Sadece bir adet dosya yükleyebilirsiniz')
        // If there's already a file in the list, reject the new file
        return Upload.LIST_IGNORE;
      }
      // If not, add the new file to the list
      setFileList([file]);
      return false;
    },
    fileList,
    defaultFileList: fileList, // Show the initial file list
    accept: '.jpg', // Accept only jpg files
    multiple: false, // Disable multiple file selection
  };

  const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });


  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Yükle</div>
    </button>
  );


  const handleOnChangeFilter = (values: any) => {
    setFilter((prev: any) => {
      const newFilter = { ...prev };

      if (values.startDueDate !== undefined) {
        newFilter.startDueDate = values.startDueDate;
      } else {
        delete newFilter.startDueDate; // Eğer undefined ise, filter objesinden bu anahtarı çıkarıyoruz.
      }

      if (values.endDueDate !== undefined) {
        newFilter.endDueDate = values.endDueDate;
      } else {
        delete newFilter.endDueDate;
      }

      return newFilter;
    });
  };

  return (
    <div>
      <Row justify={'space-between'} style={{ marginBottom: 16 }}>
        <Button type="default" className='bg-saha-purple  font-color-white' onClick={showModal} style={{ marginBottom: 16 }}>
          Ödev Oluştur
        </Button>
        <Filter onChangeFilter={handleOnChangeFilter} />
        {/* <Select style={{ width: 200, marginBottom: 16 }} placeholder="Derse Göre Filtrele" onChange={handleAssignmentFilterChange} allowClear onClear={() => setFilterAssignmentId(undefined)}>
          {lessons.map((lesson: any) => (
            <Option key={lesson.id} value={lesson.id}>{lesson.name}</Option>
          ))}
        </Select> */}
      </Row>
      <Table loading={loading} dataSource={filteredQuestions} columns={columns} rowKey="id" />
      <Modal title="Yeni Ödev Oluştur" visible={isModalVisible} onOk={handleCreate} onCancel={handleCancel} okText="Oluştur" cancelText="İptal"
        okButtonProps={{ className: 'bg-saha-purple  font-color-white', type: 'default' }}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="lessonId" label="Ders" rules={[{ required: true, message: 'Ders seçiniz' }]}>
            <Select style={{ marginBottom: 16 }} placeholder="Seçiniz" allowClear onClear={() => (undefined)}>
              {lessons.map((lesson: any) => (
                <Option key={lesson.id} value={lesson.id}>{lesson.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="studentId" label="Öğrenci" rules={[{ required: true, message: 'Öğrenci seçiniz' }]}>
            <Select style={{ marginBottom: 16 }} placeholder="Seçiniz" allowClear onClear={() => (undefined)}>
              {students.map((student: any) => (
                <Option key={student.id} value={student.id}>{student.firstName} {student.lastName}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="dueDate"
            label="Ödev Tarihi"
            rules={[
              {
                required: true,
                message: 'Ödev Tarihi zorunlu',
              }]}
          >
            <DatePicker
              className='w-full'
              format="DD/MM/YYYY HH:mm"
              showTime={{ defaultValue: dayjs('00:00', 'HH:mm'), minuteStep: 5 }}
            />
            {/* {formatDate(exam.examDate)} */}
          </Form.Item>
          <Form.Item name="content" label="Ödev İçerik" rules={[{ required: true, message: 'İçerik giriniz' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="document" label="Görsel" >
            <Upload {...props} listType="picture-card"
            >
              {fileList.length >= 1 ? null : uploadButton}
            </Upload>
          </Form.Item>

        </Form>
      </Modal>
    </div>
  );
};

export default Assignments;
