import React, { useEffect, useState } from 'react';
import { Tooltip, Card, Timeline } from 'antd';
import { FormOutlined, FileDoneOutlined, QuestionCircleOutlined, LaptopOutlined, PlaySquareOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/tr';  // Optional: Import locale for Turkish if needed
import AccountHistoryService from '../../services/AccountHistoryService';
import { useParams } from 'react-router-dom';

dayjs.extend(relativeTime);
dayjs.locale('tr'); // Optional: Set locale to Turkish

const iconMap: { [key: string]: React.ReactNode } = {
  Assignment: <FileDoneOutlined style={{ fontSize: '16px' }} />,
  StudentQuestion: <QuestionCircleOutlined style={{ fontSize: '16px' }} />,
  LiveLesson: <LaptopOutlined style={{ fontSize: '16px' }} />,
  VideoHistory: <PlaySquareOutlined style={{ fontSize: '16px' }} />,
};

const textMap: { [key: string]: string } = {
  Assignment: 'Ödev Oluşturuldu',
  StudentQuestion: 'Soru Sordu',
  LiveLesson: 'Canlı Ders Oluşturdu',
  VideoHistory: 'Video İzledi',
};

const TimelineActivity: React.FC = () => {
  const [data, setData] = useState([]);
  const { id }: any = useParams();

  useEffect(() => {
    getHistory();
  }, []);

  const getHistory = () => {
    AccountHistoryService.get(id)
      .then((response: any) => {
        const data = response.data;
        setData(data);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const timelineItems = data.map((item: any) => {
    const icon = iconMap[item.entityName] || <FormOutlined style={{ fontSize: '16px' }} />; // Default icon if none found
    const text = textMap[item.entityName] || 'Etkinlik'; // Default text if none found
    const createdAt = dayjs(item.createdAt);
    const relativeTime = createdAt.fromNow();  // 5 minutes ago, etc.
    const fullTime = createdAt.format('DD/MM/YYYY HH:mm');  // Full date in 24-hour format

    return {
      label: (
        <Tooltip title={fullTime}>
          <span>{relativeTime}</span>
        </Tooltip>
      ),
      dot: icon,
      color: '#6324F5',
      children: text,
    };
  });

  return (
    <Card className='mt-24 bg-cloud-light' bodyStyle={{ margin: '0px', padding: '0px 20px' }}>
      <h3 className='pb-16'>Son Aktivitelerim</h3>
      <Timeline mode={'left'} items={timelineItems} />
    </Card>
  );
};

export default TimelineActivity;
