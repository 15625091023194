import { useEffect, useState } from 'react'
import { Card, Col, Row } from 'antd'
import RoleService from '../services/RoleService';
import { IRoleResponseData } from '../types/Role';
import RoleItemCard from '../components/Roles/RolesItemCard';
import PermissionService from '../services/PermissionService';

const Roles = () => {
    const [roles, setRoles] = useState<IRoleResponseData[]>([]);
    const [permissionList, setPermissionList] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getRoles();
        getPermissions();
    }, [])


    const getPermissions = () => {
        PermissionService.getAll()
            .then((response: any) => {
                setPermissionList(response.data);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    }

    const getRoles = () => {
        setLoading(true);
        RoleService.getAll()
            .then((response: any) => {
                let data = response.data;
                setRoles(data);
                setLoading(false);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    return (
        <>
            <Card className='bg-cloud border-radius-md' loading={loading}>
                <Row gutter={[24, 24]}>
                    {roles.map((role: any) => (
                        <Col span={24}>
                            <RoleItemCard permissionList={permissionList} getRoles={getRoles} role={role} />
                        </Col>
                    ))}
                </Row>
            </Card>
        </>
    )
}

export default Roles