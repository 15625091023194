import axios, { CancelTokenSource } from 'axios';
import { openNotification } from './utils';

const locale = 'tr-TR';

export const withoutAccessHttp = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  headers: {
    'Accept': '*/*',
    'content-type': 'application/json',
  }
});

const cancelTokens: CancelTokenSource[] = [];

export const http = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  headers: {
    'Accept': '*/*',
    'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
    'content-type': 'application/json',
  }
});

// Request interceptor
http.interceptors.request.use(function (config: any) {
  const source = axios.CancelToken.source();
  config.cancelToken = source.token;
  cancelTokens.push(source);

  let token = localStorage.getItem('accessToken');
  if (token || config.url === '/login' || config.url === '/refresh' || config.url === '/authorize/token') {
    config.headers = {
      'authorization': `Bearer ${localStorage.getItem('accessToken')}`,
    };

    if (config.url === '/authorize/token') {
      config.headers = {
        "Content-type": "application/json",
        "accept-language": locale,
        'Access-Control-Allow-Origin': '*'
      }
    }

    return config;
  } else {
    window.location.href = '/login';
    throw new axios.Cancel('Operation canceled by the user.');
  }
}, function (error) {
  return Promise.reject(error);
});

// Response interceptor
http.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      openNotification('error', error.response.status, 'Giriş yapınız');
      localStorage.clear();
      // Cancel all requests
      cancelTokens.forEach(source => source.cancel('Operation canceled due to 401 error'));
      setTimeout(() => {
        window.location.href = '/login';
      }, 500);
    }
    return Promise.reject(error);
  }
);
