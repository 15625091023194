import { Button, Card, Col, Form, Popconfirm, Row, Space, Table, Tag } from 'antd'
import PackageService from '../services/PackagesService'
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import type { TableProps } from 'antd';
import { openNotification } from '../utils';
import { DeleteOutlined } from '@ant-design/icons'
import CourseService from '../services/CourseService';

const { Item } = Form;

interface Course {
  id: number;
  name: string;
  tags: string;
  description: string;
  salePrice: string;
}

const CourseList = () => {
  const [loading, setLoading] = useState(false);
  const [courseList, setCourseList] = useState([]);

  useEffect(() => {
    getCourseList()
  }, []);

  const columns: TableProps<Course>['columns'] = [
    {
      title: 'Paket',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Ücret',
      dataIndex: 'salePrice',
      key: 'salePrice',
      render: (salePrice) => <b>{salePrice} ₺</b>,
    },
    {
      title: 'Komisyon Oranı',
      dataIndex: 'commissionRate',
      key: 'commissionRate',
      render: (commissionRate) => <b>% {commissionRate}</b>,
    },
    {
      title: 'Etiketler',
      key: 'tags',
      dataIndex: 'tags',
      render: (_, { tags }) => (
        <>
          {tags?.split(',').map((tag:any) => {
            return (
              <Tag key={tag}>
                #{tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: 'İşlem',
      align:"center",
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <NavLink to={`/course/${record.id}`}>
            <Button className='bg-saha-purple btn-hover-dark fw-700 font-color-white'>
              Detay
            </Button>
          </NavLink>
          <Popconfirm
            key={record.id}
            title="Paket silinsin mi?"
            onConfirm={() => removePackage(record.id)}
            okText="Evet"
            cancelText="Hayır"
          >
            <DeleteOutlined
              className='cursor-pointer' />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const getCourseList = () => {
    setLoading(true);
    CourseService.getAll()
      .then((response: any) => {
        const data = response.data;
        setCourseList(data);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
        setLoading(false);
      });
  };

  const removePackage = (id: number) => {
    CourseService.remove(id)
      .then((response: any) => {
        getCourseList();
        openNotification('success', 'Başarılı', 'Kurs Başarıyla Silindi.')
      }).catch((e: Error) => {
        openNotification('error', 'Başarısız', 'Kurs silinemedi.')
      });
  }

  return (
    <>
      <Card title="Kurs Listesi" extra={<NavLink to={'/course/create'}>
        <Button className='bg-saha-purple fw-700 font-color-white btn-hover-dark'>
          Yeni Kurs Oluştur
        </Button>
      </NavLink>} loading={loading}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Table pagination={{ pageSize:100 }}  columns={columns} dataSource={courseList} />
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default CourseList