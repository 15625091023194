import { http } from "../http-common";
import { IStudentRequest, IStudentResponse } from "../types/Student";
import { isAuthorized, openNotification } from "../utils";

const loginByAccountId = (id: any) => {
  return http.post<any>(`/auth/login/by-accountId?accountId=${id}`);
};

const getAll = () => {
  if (isAuthorized('student.list')) {
    return http.get<IStudentResponse>(`/students`);

  } else {
    openNotification('error', 'Başarısız', 'Yetkiniz yok.');
    return Promise.resolve(null);
  }
};

const getAllMe = () => {
    return http.get<IStudentResponse>(`/students/affiliate/me`);
};

const create = (data: IStudentRequest) => {
  if (isAuthorized('student.create')) {
    return http.post<IStudentRequest>(`/students`, data);

  } else {
    openNotification('error', 'Başarısız', 'Yetkiniz yok.');
    return Promise.resolve(null);
  }
};

const get = (id: number) => {
  if (isAuthorized('student.show')) {
    return http.get<IStudentResponse>(`/students/${id}`);

  } else {
    openNotification('error', 'Başarısız', 'Yetkiniz yok.');
    return Promise.resolve(null);
  }
};

const update = (id: number, data: IStudentRequest) => {
  if (isAuthorized('student.update')) {
    return http.patch<IStudentRequest>(`/students/${id}`, data);

  } else {
    openNotification('error', 'Başarısız', 'Yetkiniz yok.');
    return Promise.resolve(null);
  }
};

const remove = (id: number) => {
  if (isAuthorized('student.delete')) {
    return http.delete<IStudentRequest>(`/students/${id}`);

  } else {
    openNotification('error', 'Başarısız', 'Yetkiniz yok.');
    return Promise.resolve(null);
  }
};

const getAssignmentStatistics = (id: number) => {
  if (isAuthorized('student.show')) {
      return http.get<any>(`/students/${id}/assignments/statistics`);

  } else {
      openNotification('error', 'Başarısız', 'Yetkiniz yok.');
      return Promise.resolve(null);
  }
};

const getStudentQuestionStatistics = (id: number) => {
  if (isAuthorized('student.show')) {
    return http.get<any>(`/students/${id}/student-questions/statistics`);

  } else {
      openNotification('error', 'Başarısız', 'Yetkiniz yok.');
      return Promise.resolve(null);
  }
};

const getVideoHistoryStatistics = (id: number) => {
  return http.get<any>(`/students/${id}/video-histories/statistics`);
};

const StudentService = {
  create,
  getAll,
  get,
  update,
  remove,
  getAllMe,
  getAssignmentStatistics,
  getStudentQuestionStatistics,
  getVideoHistoryStatistics,
  loginByAccountId
}

export default StudentService;