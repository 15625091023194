import { Card, Col, Row, Form, Button, Drawer, Input, UploadFile, UploadProps, Image, GetProp, Upload } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import AnnouncementService from '../services/AnnouncementService';
import AnnouncementsItemCard from '../components/Announcements/AnnouncementsItemCard';
import TextArea from 'antd/es/input/TextArea';
import { openNotification } from '../utils';
import { PlusOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';

const { Item } = Form;
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const Announcements = () => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [announcements, setAnnouncements] = useState<any[]>([]);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  let navigate = useNavigate();

  useEffect(() => {
    getAnnouncements()
  }, []);

  const getAnnouncements = () => {
    setLoading(true);
    AnnouncementService.getAll()
      .then((response: any) => {
        const data = response.data;
        setAnnouncements(data);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
        setLoading(false);
      });
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [open])

  const update = (values: any) => {

    let postObj = {
      "title": values.title,
      "description": values.description,
      "cover": values.cover,
    };

    AnnouncementService.create(postObj, fileList[0])
      .then((response: any) => {
        openNotification('success', 'Başarılı', 'Duyuru Başarıyla Eklendi.')
        getAnnouncements();
        onClose();
      }).catch((e: Error) => {
        openNotification('error', 'Başarısız', 'Duyuru Eklenemedi.')
        console.log(e)
      });
  }

  const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });


  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Yükle</div>
    </button>
  );

  const props: UploadProps = {
    onPreview: async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as FileType);
      }

      setPreviewImage(file.url || (file.preview as string));
      setPreviewOpen(true);
    },
    onRemove: (file) => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      if (fileList.length >= 1) {
        openNotification('warning', 'Uyarı', 'Sadece bir adet dosya yükleyebilirsiniz')
        // If there's already a file in the list, reject the new file
        return Upload.LIST_IGNORE;
      }
      // If not, add the new file to the list
      setFileList([file]);
      return false;
    },
    fileList,
    defaultFileList: fileList, // Show the initial file list
    accept: 'image/png, image/jpeg, image/jpg', // Accept only png, jpg and jpeg files
    multiple: false, // Disable multiple file selection
  };

  return (
    <>
      <Row justify={'center'} className='m-24'>
        <Col span={24}>
          <Card loading={loading}>
            <Row align={'middle'} justify={'space-between'}>
              <Col>
                <h2 className='mr-16'>Duyurular</h2>
                <Button onClick={showDrawer} className='btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text'>
                  Yeni Duyuru Ekle
                </Button>
              </Col>
              <Col>
                <Button onClick={() => navigate(-1)} className=''>
                  Geri
                </Button>
              </Col>
            </Row>
            <Row gutter={[24, 24]} className='mt-24'>
              {announcements?.map((announcement: any) => (
                <Col span={24}>
                  <AnnouncementsItemCard announcement={announcement} getTrigger={getAnnouncements} />
                </Col>
              ))}
            </Row>
          </Card>
          <Drawer title="Duyuru Ekle" placement="right" onClose={onClose} open={open}>
            <Form form={form} onFinish={update} layout="vertical">
              <Item
                name="title"
                label="Duyuru"
                rules={[
                  {
                    required: true,
                    message: 'Duyuru adı zorunlu',
                  }]}
              >
                <Input />
              </Item>
              <Item
                name="description"
                label="Açıklama"
              >
                <ReactQuill theme="snow" />
              </Item>
              <Item
                name="cover"
                label="Duyuru Cover"
              >
                <Input />
              </Item>
              <Form.Item label="">
                <Upload  {...props} listType="picture-card"
                  maxCount={1}
                >
                  {fileList.length >= 1 ? null : uploadButton}
                </Upload>
                {previewImage && (
                  <Image
                    wrapperStyle={{ display: 'none' }}
                    preview={{
                      visible: previewOpen,
                      onVisibleChange: (visible) => setPreviewOpen(visible),
                      afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                  />
                )}
              </Form.Item>
              <Item>
                <Row justify={'end'}>
                  <Button className='btn btn-hover-dark bg-cloud font-color-onyx btn-text mr-16' onClick={onClose}>
                    Vazgeç
                  </Button>
                  <Button className='btn btn-hover-white bg-saha-purple font-color-white fw-700 btn-text' htmlType="submit">
                    Kaydet
                  </Button>
                </Row>
              </Item>
            </Form>
          </Drawer>
        </Col>
      </Row>
    </>
  )
}

export default Announcements