import { Button, Col, Divider, Drawer, Form, Input, Modal, Row, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { useEffect, useState } from 'react'
import { openNotification } from '../../../utils';
import SubjectService from '../../../services/SubjectService';
import UnitService from '../../../services/UnitService';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles

interface SubjectCreateProps {
    getTrigger: any;
    unitId: any;
}

const { Item } = Form;

const SubjectCreate: React.FC<SubjectCreateProps> = ({ getTrigger, unitId }) => {
    const [units, setUnits] = useState<any>([]);
    const [form] = Form.useForm();
    const [showDrawer, setShowDrawer] = useState(false);

    useEffect(() => {
        if (showDrawer) {
            getUnits()
        }
    }, [showDrawer]);

    const handleSubjectCreateCancel = () => {
        setShowDrawer(false);
    };

    useEffect(() => {
        form.resetFields();
    }, [showDrawer])

    const getUnits = () => {
        UnitService.getAll()
            .then((response: any) => {
                const data = response.data
                const options = data.map((unit: { id: number; name: string; }) => ({
                    label: unit.name, // Kullanıcıya gösterilecek metin
                    value: unit.id, // Form üzerinden gönderilecek değer
                }));
                setUnits(options);

            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const create = (values: any) => {
        let postObj: any = {
            "title": values.name,
            "description": values.description,
            "unitId": unitId,
        };

        SubjectService.create(postObj)
            .then((response: any) => {
                openNotification('success', 'Başarılı', 'Konu Başarıyla Oluşturuldu.')
                setShowDrawer(false)
                getTrigger();
            }).catch((e: Error) => {
                openNotification('error', 'Başarısız', 'Konu oluşturulamadı.')
                console.log(e)
            });
    }

    return (
        <>
            <Button onClick={() => setShowDrawer(true)}>
            Konu Ekle
            </Button>
            <Drawer title="Konu Ekle" placement="right" onClose={() => setShowDrawer(false)} open={showDrawer}>
                <Form form={form} onFinish={create} layout="vertical">
                    <Item
                        name="name"
                        label="Konu"
                        rules={[
                            {
                                required: true,
                                message: 'Konu adı zorunlu',
                            }]}
                    >
                        <Input />
                    </Item>
                    <Item
                        name="description"
                        label="Açıklama"
                    >
                        <ReactQuill className='height-150 mb-24' theme="snow" />
                    </Item>
                    <Divider></Divider>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Button onClick={handleSubjectCreateCancel} className='w-full height-40'>
                                İptal
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Item>
                                <Button htmlType="submit" className='w-full height-40 btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text'>
                                    Kaydet
                                </Button>
                            </Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    )
}

export default SubjectCreate