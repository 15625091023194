import { Card, Col, Row, Form, Button, Drawer, Input, Select } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import UnitService from '../services/UnitService';
import UnitsItemCard from '../components/Units/UnitsItemCard';
import LessonService from '../services/LessonService';
import TextArea from 'antd/es/input/TextArea';
import { openNotification } from '../utils';

const { Item } = Form;

const Units = () => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [units, setUnits] = useState([]);
  const [lessons, setLessons] = useState<any>([]);
  let navigate = useNavigate();

  useEffect(() => {
    getUnits()
    getLessons()
  }, []);

  const getUnits = () => {
    setLoading(true);
    UnitService.getAll()
      .then((response: any) => {
        const data = response.data;
        setUnits(data);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
        setLoading(false);
      });
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [open])

  const getLessons = () => {
    LessonService.getAll()
      .then((response: any) => {
        const data = response.data
        const options = data.map((lesson: { id: number; name: string; }) => ({
          label: lesson.name, // Kullanıcıya gösterilecek metin
          value: lesson.id, // Form üzerinden gönderilecek değer
        }));
        setLessons(options);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const update = (values: any) => {

    let postObj = {
      "name": values.name,
      "description": values.description,
      "lessonId": values.lessonId,
    };

    UnitService.create(postObj)
      .then((response: any) => {
        openNotification('success', 'Başarılı', 'Ünite Başarıyla Oluşturuldu.')
        getUnits();
        onClose();
      }).catch((e: Error) => {
        openNotification('error', 'Başarısız', 'Ünite oluşturulamadı.')
        console.log(e)
      });
  }
  
  return (
    <>
      <Row justify={'center'} className='m-24'>
        <Col span={16}>
          <Card loading={loading}>
            <Row align={'middle'} justify={'space-between'}>
              <Col>
                <h2 className='mr-16'>Üniteler</h2>
                <Button  onClick={showDrawer} className=''>
                  Yeni Ünite Ekle
                </Button>
              </Col>
              <Col>
                <Button onClick={() => navigate(-1)} className=''>
                  Geri
                </Button>
              </Col>
            </Row>
            <Row gutter={[24, 24]} className='mt-24'>
              {units.map((unit) => (
                <Col span={24}>
                  <UnitsItemCard lessons={lessons} unit={unit} getTrigger={getUnits} />
                </Col>
              ))}
            </Row>
          </Card>
          <Drawer title="Ünite Ekle" placement="right" onClose={onClose} open={open}>
            <Form form={form} onFinish={update} layout="vertical">
              <Item
                name="name"
                label="Ünite"
                rules={[
                  {
                    required: true,
                    message: 'Ünite adı zorunlu',
                  }]}
              >
                <Input />
              </Item>
              <Item
                name="description"
                label="Açıklama"
              >
                <TextArea />
              </Item>
              <Item
                name="lessonId"
                label="Ders"
                rules={[
                  {
                    required: true,
                    message: 'Lütfen bir ders seçin', // Kullanıcı alanı boş bıraktığında gösterilecek mesaj
                  },
                ]}
              >
                <Select options={lessons} />
              </Item>
              <Item>
                <Row justify={'end'}>
                  <Button className='btn btn-hover-dark bg-cloud font-color-onyx btn-text mr-16' onClick={onClose}>
                    Vazgeç
                  </Button>
                  <Button  className='btn btn-hover-dark bg-slate-blue font-color-cloud btn-text' htmlType="submit">
                    Kaydet
                  </Button>
                </Row>
              </Item>
            </Form>
          </Drawer>
        </Col>
      </Row>
    </>
  )
}

export default Units