import { Alert, Button, Col, Divider, Drawer, Form, Input, Modal, Row, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { useEffect, useState } from 'react'
import { openNotification } from '../../../utils';
import LessonService from '../../../services/LessonService';
import GradeService from '../../../services/GradeService';
import { FormInstance, RuleObject } from 'antd/lib/form';

interface LessonCreateProps {
    getTrigger: () => void;
    gradeId: number;
}

const { Item } = Form;

const LessonCreate: React.FC<LessonCreateProps> = ({ getTrigger, gradeId }) => {
    const [lessons, setLessons] = useState<{ label: string; value: number }[]>([]);
    const [form] = Form.useForm();
    const [showDrawer, setShowDrawer] = useState(false);

    useEffect(() => {
        if (showDrawer) {
            getLesson();
        }
        form.resetFields();
    }, [showDrawer]);

    const handleLessonCreateCancel = () => {
        setShowDrawer(false);
    };

    const getLesson = () => {
        LessonService.getAll()
            .then((response: any) => {
                const data = response.data;
                const options = data.map((lesson: { id: number; name: string; }) => ({
                    label: lesson.name,
                    value: lesson.id,
                }));
                setLessons([{ label: 'Seçiniz', value: null }, ...options]);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const update = (values: any) => {
        if (!values.lesson) {
            let postObj = {
                "name": values.name,
                "description": values.description,
                "gradeIds": [gradeId],
            };

            LessonService.create(postObj)
                .then((response: any) => {
                    openNotification('success', 'Başarılı', 'Ders Başarıyla Oluşturuldu.')
                    setShowDrawer(false)
                    getTrigger();
                }).catch((e: Error) => {
                    openNotification('error', 'Başarısız', 'Ders oluşturulamadı.')
                    console.log(e)
                });
        } else {
            LessonService.addToGrade(values.lesson, gradeId)
                .then((response: any) => {
                    openNotification('success', 'Başarılı', 'Ders Başarıyla Oluşturuldu.')
                    setShowDrawer(false)
                    getTrigger();
                }).catch((e: Error) => {
                    openNotification('error', 'Başarısız', 'Ders oluşturulamadı.')
                    console.log(e)
                });

        }

    };

    const validateFields = (_: RuleObject, value: any) => {
        const { lesson, name } = form.getFieldsValue(['lesson', 'name']);
        if (!lesson && !name) {
            return Promise.reject(new Error('Lütfen bir ders seçin veya ders adı girin'));
        }
        return Promise.resolve();
    };

    return (
        <>
            <Button onClick={() => setShowDrawer(true)}>
                Ders Ekle
            </Button>
            <Drawer title="Ders Ekle" placement="right" onClose={() => setShowDrawer(false)} open={showDrawer}>
                <Form form={form} onFinish={update} layout="vertical">
                    <Item
                        name="lesson"
                        label="Ders Seç"
                        rules={[
                            {
                                validator: validateFields,
                            },
                        ]}
                    >
                        <Select options={lessons} />
                    </Item>
                    <Divider />
                    <Alert message="Listede dersin adını bulamadıysanız aşağıdaki alanları doldurunuz" type="info" showIcon />
                    <Divider />
                    <Item
                        name="name"
                        label="Ders Adı"
                        rules={[
                            {
                                validator: validateFields,
                            },
                        ]}
                    >
                        <Input />
                    </Item>
                    <Item
                        name="description"
                        label="Açıklama"
                    >
                        <TextArea />
                    </Item>
                    <Divider />
                    <Row gutter={8}>
                        <Col span={12}>
                            <Button onClick={handleLessonCreateCancel} className="w-full height-40">
                                İptal
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Item>
                                <Button htmlType="submit" className="w-full height-40 btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text">
                                    Kaydet
                                </Button>
                            </Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    );
}

export default LessonCreate;
