import { Button, Card, Col, Drawer, Image, Row, Form, Input, Popconfirm, Upload, UploadProps, UploadFile, GetProp } from 'antd';
import React, { useEffect, useState } from 'react'
import AnnouncementService from '../../services/AnnouncementService';
import { DeleteOutlined } from '@ant-design/icons'
import TextArea from 'antd/es/input/TextArea';
import { openNotification, truncateText } from '../../utils';
import { PlusOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';

interface AnnouncementsItemCardProps {
    announcement: any;
    getTrigger: any;
}

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const { Item } = Form;

const AnnouncementsItemCard: React.FC<AnnouncementsItemCardProps> = ({ announcement, getTrigger }) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const [pdfUrl, setPdfUrl] = useState<string>('');
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (open) {
            getService();
        }
    }, [open])

    const getService = () => {
        setLoading(true)
        AnnouncementService.get(announcement?.id)
            .then((response: any) => {
                const data = response.data
                form.setFieldsValue({
                    title: data?.title,
                    description: data?.description,
                    cover: data?.cover,
                });
                getFile(data?.thumbnail);
                setLoading(false)
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const getFile = (fileName: any) => {
        AnnouncementService.getFile(fileName).then((response: any) => {
            // API'den gelen veriyi kullanarak File nesnesini oluşturun
            const file = new File([response.data], fileName, {
                type: response.headers['content-type'],
                lastModified: Date.now(),
            });

            // Mevcut listeyi güncelleyip, ilk elemanı değiştiriyoruz
            setFileList((prevList: any[]) => {
                const newList = [...prevList];
                newList[0] = file;
                return newList;
            });

            // PDF URL'sini ayarlayın
            const url = URL.createObjectURL(file);
            setPdfUrl(url);
        }).catch((e: Error) => {
            console.error(e);
        });
    };

    const update = (values: any) => {
        let postObj = {
            "title": values.title,
            "description": values.description,
            "cover": values.cover,
        }

        const file = fileList.length ? fileList[0] : " ";

        console.log(file)

        AnnouncementService.update(announcement?.id, postObj, file)
            .then((response: any) => {
                getTrigger();
                openNotification('success', 'Başarılı', 'Duyuru Başarıyla Kaydedildi.')
            }).catch((e: Error) => {
                openNotification('error', 'Başarısız', 'Duyuru Kaydedilemedi.')
            });
    }

    const removeAnnouncement = () => {
        AnnouncementService.remove(announcement?.id)
            .then((response: any) => {
                getTrigger();
                openNotification('success', 'Başarılı', 'Duyuru Başarıyla Silindi.')
            }).catch((e: Error) => {
                openNotification('error', 'Başarısız', 'Duyuru Silinemedi.')
            });
    }

    const getBase64 = (file: FileType): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });


    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Yükle</div>
        </button>
    );

    const props: UploadProps = {
        onPreview: async (file) => {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj as FileType);
            }

            setPreviewImage(file.url || (file.preview as string));
            setPreviewOpen(true);
        },
        onRemove: (file) => {
            setFileList([]);
        },
        beforeUpload: (file) => {
            // Check file type
            const isValidType = file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg";
            if (!isValidType) {
                openNotification('warning', 'Uyarı', 'Sadece .png, .jpg, ve .jpeg formatları destekleniyor');
                return Upload.LIST_IGNORE;
            }

            if (fileList.length >= 1) {
                openNotification('warning', 'Uyarı', 'Sadece bir adet dosya yükleyebilirsiniz')
                return Upload.LIST_IGNORE;
            }

            setFileList([file]);
            return false;
        },
        fileList,
        defaultFileList: fileList, // Show the initial file list
        accept: 'image/png, image/jpeg, image/jpg', // Accept only png, jpg and jpeg files
        multiple: false, // Disable multiple file selection
    };

    const modules = {
        toolbar: false  // Toolbar'ı kaldırmak için false kullanın
    };

    return (
        <>
            <Card>
                <Row gutter={24} align={'middle'} justify={'space-around'}>
                    <Col span={2}>
                        <p><b>ID:</b></p>
                        {announcement.id}
                    </Col>
                    <Col span={3}>
                        <p><b> Duyuru:</b></p>
                        {announcement.title}
                    </Col>
                    <Col span={12}>
                        <p><b>Açıklama:</b></p>
                        <ReactQuill
                            value={truncateText(announcement.description, 300)}
                            modules={modules}
                            className='quill-border-none'
                            readOnly={true}
                            theme='snow'
                        />
                    </Col>
                    <Col span={3}>
                        <p><b>Duyuru Cover:</b></p>
                        {announcement.cover}
                    </Col>
                    <Col span={2}>
                        <Button className='btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text' onClick={showDrawer}>
                            Detay
                        </Button>
                    </Col>
                    <Col span={1}>
                        <Popconfirm
                            title="Duyuru silinsin mi?"
                            onConfirm={() => removeAnnouncement()}
                            okText="Evet"
                            cancelText="Hayır"
                        >
                            <DeleteOutlined
                                className='cursor-pointer' />
                        </Popconfirm>
                    </Col>
                </Row>
            </Card>
            <Drawer title="Duyuru Detay" placement="right" onClose={onClose} open={open} extra={<b>#{announcement?.id}</b>}>
                <Card loading={loading}>
                    <Form form={form} onFinish={update} layout="vertical">
                        <Item
                            name="title"
                            label="Duyuru"
                            rules={[
                                {
                                    required: true,
                                    message: 'Duyuru adı zorunlu',
                                }]}
                        >
                            <Input />
                        </Item>
                        <Item
                            name="description"
                            label="Açıklama"
                        >
                            <ReactQuill theme="snow" />
                        </Item>
                        <Item
                            name="cover"
                            label="Duyuru Cover"
                        >
                            <Input />
                        </Item>
                        {pdfUrl && <Image src={pdfUrl} width="100%" />}
                        <Form.Item label="Thumbnail Düzenle">
                            <Upload  {...props} listType="picture-card"
                                maxCount={1}
                            >
                                {fileList.length >= 1 ? null : uploadButton}
                            </Upload>
                            {previewImage && (
                                <Image
                                    wrapperStyle={{ display: 'none' }}
                                    preview={{
                                        visible: previewOpen,
                                        onVisibleChange: (visible) => setPreviewOpen(visible),
                                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                    }}
                                    src={previewImage}
                                />
                            )}
                        </Form.Item>
                        <Item>
                            <Row justify={'end'} className='mt-24'>
                                <Button className='btn btn-hover-dark bg-cloud font-color-onyx btn-text mr-16' onClick={onClose}>
                                    Vazgeç
                                </Button>
                                <Button className='btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text' htmlType="submit">
                                    Kaydet
                                </Button>
                            </Row>
                        </Item>
                    </Form>
                </Card>
            </Drawer>
        </>
    )
}

export default AnnouncementsItemCard