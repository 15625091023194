import { Button, Col, Divider, Form, Input, Modal, Row } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useEffect } from 'react'
import { openNotification } from '../../../utils';
import GradeService from '../../../services/GradeService';
import { useParams } from 'react-router-dom';

interface CreateGradeModalProps {
  showModal: boolean;
  setShowModal: any;
  getTrigger: any;
}

const { Item } = Form;

const CreateGradeModal:React.FC<CreateGradeModalProps> = ({ showModal, setShowModal, getTrigger}) => {
  const { id }: any = useParams();

  const [form] = Form.useForm();

  const handleGradeCreateCancel = () => {
    setShowModal(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [showModal])

  const create = (values: any) => {
    let postObj = {
      "name": values.name,
      "description": values.description,
      "curriculumId": id
    };

    GradeService.create(postObj)
      .then((response: any) => {
        openNotification('success', 'Başarılı', 'Sınıf Başarıyla Oluşturuldu.')
        setShowModal(false)
        getTrigger();
      }).catch((e: Error) => {
        openNotification('error', 'Başarısız', 'Sınıf oluşturulamadı.')
        console.log(e)
      });
  }

  return (
    <>
      <Modal title="Sınıf Ekle" open={showModal} closeIcon={<div onClick={() => setShowModal(false)}>X</div>}
        footer={
          <></>
        }
      >
        <Divider></Divider>
        <Form form={form} onFinish={create} layout="vertical">
          <Item
            name="name"
            label="Sınıf"
            rules={[
              {
                required: true,
                message: 'Sınıf adı zorunlu',
              }]}
          >
            <Input />
          </Item>
          <Item
            name="description"
            label="Açıklama"
          >
            <TextArea />
          </Item>
          <Divider></Divider>
          <Row gutter={8}>
            <Col span={12}>
              <Button onClick={handleGradeCreateCancel} className='w-full height-40'>
                İptal
              </Button>
            </Col>
            <Col span={12}>
              <Item>
                <Button htmlType="submit" className='w-full height-40 btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text'>
                  Kaydet
                </Button>
              </Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default CreateGradeModal