import React, { useEffect, useState } from 'react'
import UnitService from '../services/UnitService';
import { Breadcrumb, Button, Card, Col, Popconfirm, Row, TableColumnsType } from 'antd';
import SortableTable, { DragHandle } from '../components/Common/SortableTable';
import { QuestionCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import { formatDate } from '../utils';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import SubjectService from '../services/SubjectService';
import SubjectCreate from './SubjetDetail/Create/SubjectCreate';
import UnitUpdate from './SubjetDetail/Update/UnitUpdate';
import { removeItem } from '../services/RemoveService';

interface DataType {
    id: number;
    title: string;
    sortOrder: number;
}

interface UnitDetailProps {
    unit: any;
    getTrigger: any;
}

const UnitDetail: React.FC<UnitDetailProps> = ({ unit, getTrigger }) => {

    const [dataSource, setDataSource] = useState<DataType[]>([]);
    const [loading, setLoading] = useState(false);
    const [actionNode, setActionNode] = useState<any>();

    useEffect(() => {
        if (actionNode?.action === 'delete') {
            removeItem(actionNode).then(() => {
                getTrigger();
            })
                .catch((error) => {
                    console.error('Silme işlemi başarısız:', error);
                });
        }
    }, [actionNode])

    useEffect(() => {
        getData();
    }, [unit])

    const columns: TableColumnsType<DataType> = [
        { key: 'sort', align: 'center', width: 80, render: () => <DragHandle /> },
        { title: '#', dataIndex: 'id', width: 40 },
        { title: 'Konu', dataIndex: 'title', width: 200 },
        {
            title: 'Kayıt Tarihi',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 80,
            render: (createdAt) => (
                <>
                    {formatDate(createdAt)}
                </>
            ),
        },
    ];

    const getData = () => {
        UnitService.get(unit?.id)
            .then((response: any) => {
                setDataSource(response.data.subjects)
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {
            setDataSource((prevState) => {
                const activeIndex = prevState.findIndex((record) => record.id === active?.id);
                const overIndex = prevState.findIndex((record) => record.id === over?.id);
                const newState = arrayMove(prevState, activeIndex, overIndex);

                const updatedSortOrder = newState.map((item, index) => ({
                    id: item.id,
                    sortOrder: index + 1
                }));

                updateSortOrders(updatedSortOrder);

                return newState;
            });
        }
    };

    const updateSortOrders = (data: any) => {
        SubjectService.updateSortOrders(data)
            .then((response: any) => {
                getTrigger();
            })
            .catch((e: Error) => {
                console.log(e);
                setLoading(false);
            });
    };

    return (
        <Card title={unit.name}
            extra={
                <Row gutter={[16, 16]} align={"middle"} >
                    <Col>
                        <Breadcrumb separator=">">
                            {unit.breadcrumb.slice(0, -1).map((item: any, index: number) => (
                                <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
                            ))}
                        </Breadcrumb>
                    </Col>
                    <Col>
                        <UnitUpdate unitId={unit?.id} getTrigger={() => { getTrigger(); getData(); }}></UnitUpdate>
                    </Col>
                    <Col>
                        <Popconfirm
                            title="Üniteyi Sil"
                            description="Üniteyi silmek istediğinize emin misiniz?"
                            okText="Evet"
                            cancelText="Hayır"
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            onConfirm={() => setActionNode({ id: unit?.id, data: null, action: 'delete', type: 'unit' })}
                        >
                            <Button danger onClick={e => e.stopPropagation()}><DeleteOutlined /></Button>
                        </Popconfirm>
                    </Col>
                </Row>}
        >
            <Row align={"middle"} justify={"space-between"} className='mb-16'>
                <Col>
                    Konu Listesi
                </Col>
                <Col>
                    <SubjectCreate unitId={unit?.id} getTrigger={() => { getTrigger(); getData(); }}></SubjectCreate>
                </Col>
            </Row>
            <SortableTable<DataType>
                loading={loading}
                columns={columns}
                dataSource={dataSource}
                onDragEnd={onDragEnd}
                defaultSortField="sortOrder"
            />
        </Card>
    )
}

export default UnitDetail