import { Card, Col, Layout, Row, Image, Breadcrumb, Input, Avatar, Dropdown, MenuProps } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { Content } from 'antd/es/layout/layout';
import { Link, NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import Logo from './assets/saha-logo.png'
import Menu from './components/MainLayout/Menu';
import Pages from './components/MainLayout/Pages';
import { ReactComponent as CollapseIcon } from './assets/Layout/arrow-down.svg'
import { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { removeLocal } from './utils';

const MainLayout = () => {
  const navigate = useNavigate()
  const [urlPath, setUrlPath] = useState('');
  const location = useLocation();

  const [firstName, setFirstName] = useState<any>()
  const [lastName, setLastName] = useState<any>()
  const [role, setRole] = useState<any>()
  const firstLetter = firstName ? firstName.charAt(0).toUpperCase() : '';

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken || accessToken == 'null' || accessToken === null) {
      logout();
    }

    setUrlPath(location.pathname);
  }, [location.pathname]);

  useEffect(() => {

    let firstN = localStorage.getItem('firstName')
    let lastN = localStorage.getItem('lastName')
    let role = localStorage.getItem('role')
    setFirstName(firstN)
    setLastName(lastN)
    setRole(role)

    const token = localStorage.getItem('accessToken');
    if (token) {
      const decodedToken: any = jwtDecode(token); // 'jwtDecode' fonksiyonunu doğru şekilde import ettiğinizden emin olun
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        // Token'ın süresi dolmuş
        localStorage.removeItem('accessToken'); // Token'ı temizleyin
        navigate('/login'); // Kullanıcıyı login sayfasına yönlendirin
      }
    } else {
      // Token yok, doğrudan login sayfasına yönlendir
      navigate('/login');
    }
  }, [location.pathname]);

  // Function to map paths to breadcrumb titles
  const getBreadcrumbItems = (path: string) => {
    const pathNames = path.split('/').filter((x) => x);
    const breadcrumbItems = pathNames.map((name, index) => {
      // Capitalize and replace any hyphens with spaces
      const title = name.charAt(0).toUpperCase() + name.slice(1).replace(/-/g, ' ');
      return { title: title, href: '/' + pathNames.slice(0, index + 1).join('/') };
    });

    // Always add 'Home' as the first breadcrumb item
    return [{ title: 'Home', href: '/' }, ...breadcrumbItems];
  };

  const breadcrumbItems = getBreadcrumbItems(location.pathname);

  const logout = () => {
    removeLocal()
    navigate('/login')
  }

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Link to={'/profile'} rel="noopener noreferrer" >
          Profil
        </Link>
      ),
    },
    {
      key: '2',
      label: (
        <Link to={'/login'} onClick={logout} rel="noopener noreferrer" >
          Çıkış Yap
        </Link>
      ),
    },
  ];

  const View = () => (
    <>
      <Layout>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
          width={320}
          style={{
            background: "#F4F4F4",
          }}
        >
          {MenuControl()}
        </Sider>
        <Content
              style={{
                padding: '1.6em',
                minHeight: 'calc(100vh )',
                background: '#F4F4F4',
              }}
            >
              <Row align={'middle'} justify={"end"}>
                <Col>
                  <Row align={'middle'} justify={'space-between'}>
                  </Row>
                </Col>
              </Row>
              <Outlet />
            </Content>
      </Layout>
    </>
  )

  const MenuControl: any = () => {
    return (
      <>
        <div className='p-24'>
          <Card className='bg-white border-radius-md oneamz-layout-menu'>
            <Row className='mb-32 mt-12' justify={'center'}>
              <Col>
                <NavLink to={'/'}>
                  <Image preview={false} src={Logo}></Image>
                </NavLink>
              </Col>
            </Row>
            <Row>
              <Col>
                <Menu />
              </Col>
            </Row>
            <Row className='mt-12'>
              <Col>
                <Pages />
              </Col>
            </Row>
          </Card>
        </div>
      </>
    )
  }

  return (
    <div>
      {View()}
    </div>
  )
}

export default MainLayout