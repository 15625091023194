import { Card, Col, Row } from 'antd'

const WelcomeCard = () => {
  return (
    <>
      <Card className='welcome-card font-color-white border-radius-md'>
        <Row justify={'space-between'} align={'middle'}>
          <Col span={24}>
            <h3 className='fs-24 fw-700 mb-0'>Saha Yönetim Paneline Hoşgeldiniz</h3>
            <p className='fs-14 fw-500'>Yönetim paneli üzerinden raporlar alabilir, ders ekleyip içeriklerini <br /> düzenleyebilirsiniz.</p>
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default WelcomeCard