import { Card, Col, Row, Tree, TreeProps, Menu, Button, Popconfirm, Tooltip } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { DownOutlined, QuestionCircleOutlined, DragOutlined } from '@ant-design/icons';

import CreateLessonModal from '../components/Subjects/Modals/CreateLessonModal';
import CreateUnitModal from '../components/Subjects/Modals/CreateUnitModal';
import CreateGradeModal from '../components/Subjects/Modals/CreateGradeModal';
import CreateSubjectModal from '../components/Subjects/Modals/CreateSubjectModal';
import QuizCreate from '../components/Subjects/Components/QuizCreate';
import ExplanationCreate from '../components/Subjects/Components/ExplanationCreate';
import DocumentCreate from '../components/Subjects/Components/DocumentCreate';
import QuestionCreate from '../components/Subjects/Components/QuestionCreate';

import EditGradeModal from '../components/Subjects/Modals/EditGradeModal';
import EditLessonModal from '../components/Subjects/Modals/EditLessonModal';
import EditUnitModal from '../components/Subjects/Modals/EditUnitModal';
import EditSubjectModal from '../components/Subjects/Modals/EditSubjectModal';
import QuestionEdit from '../components/Subjects/Components/QuestionEdit';
import QuizEdit from '../components/Subjects/Components/QuizEdit';
import DocumentEdit from '../components/Subjects/Components/DocumentEdit';
import ExplanationEdit from '../components/Subjects/Components/ExplanationEdit';
import VideoView from '../components/Subjects/Components/VideoView';

// const { Item } = Form;

import { ReactComponent as EditIcon } from '../assets/Subject/edit-icon.svg'
import { ReactComponent as DeleteIcon } from '../assets/Subject/delete-icon.svg'
import { ReactComponent as GradeIcon } from '../assets/Subject/grade-icon.svg'
import { ReactComponent as LessonIcon } from '../assets/Subject/lesson-icon.svg'
import { ReactComponent as UnitIcon } from '../assets/Subject/unit-icon.svg'
import { ReactComponent as MenuGrade } from '../assets/Subject/menu-grade.svg'
import { ReactComponent as MenuLesson } from '../assets/Subject/menu-lesson.svg'
import { ReactComponent as MenuQuestion } from '../assets/Subject/question-icon.svg'
import { ReactComponent as MenuVideo } from '../assets/Subject/camera-icon.svg'
import { ReactComponent as MenuSubject } from '../assets/Subject/menu-subject.svg'
import { ReactComponent as SubjectIcon } from '../assets/Subject/subject-icon.svg'
import { ReactComponent as MenuUnit } from '../assets/Subject/menu-unit.svg'
import { ReactComponent as MenuExplanation } from '../assets/Subject/explanation-icon.svg'
import { ReactComponent as MenuDocument } from '../assets/Subject/document-icon.svg'
import { useParams } from 'react-router-dom';
import CurriculumService from '../services/CurriculumService';
import { removeItem } from '../services/RemoveService';
import { setgroups } from 'process';

interface ContextMenuState {
  visible: boolean;
  x: number;
  y: number;
}

const formatDataForTree = (data: any) => {
  return data.map((grade: any) => ({
    title: grade.name,
    key: `grade-${grade.id}`,
    icon: <GradeIcon />,
    children: grade.lessons.map((lesson: any) => ({
      title: lesson.name,
      key: `grade-${grade.id}-lesson-${lesson.id}`,
      icon: <LessonIcon />,
      children: lesson.units
        .filter((unit: any) => unit.gradeId === grade.id)
        .map((unit: any) => ({
          title: unit.name,
          key: `grade-${grade.id}-lesson-${lesson.id}-unit-${unit.id}`,
          icon: <UnitIcon />,
          children: unit.subjects.map((subject:any, index:number) => ({
            title: (
              <span>
                <Tooltip title="Sürükle Bırak">
                <DragOutlined style={{ marginRight: 8 }} />
                {subject.title}
                </Tooltip>
              </span>
            ),
            unitId: unit.id,
            sortId: index, 
            key: `subject-${subject.id}`,
            icon: <SubjectIcon className='wh-20' />,
            children: [
              {
                title: "Videolar",
                key: `videos-${subject.id}`,
                icon: <MenuVideo className='wh-20' />,
                children: [
                  ...subject.videos.map((video: any) => ({
                    title: video.title,
                    key: `video-${video.id}`,
                    icon: <MenuVideo className='wh-20' />,
                  })),
                ]
              },
              // ...subject.quizzes.map((quiz: any) => ({
              //   title: quiz.title,
              //   key: `quiz-${quiz.id}`,
              //   icon: <MenuVideo className='wh-20' />,
              // })),
              {
                title: "Konu Anlatımları",
                key: `explanations-${subject.id}`,
                icon: <MenuExplanation className='wh-20' />,
                children: [
                  ...subject.explanations.map((explanation: any) => ({
                    title: explanation.title,
                    key: `explanation-${explanation.id}`,
                    icon: <MenuExplanation className='wh-20' />,
                  })),
                ]
              },
              {
                title: "Dokümanlar",
                key: `documents-${subject.id}`,
                icon: <MenuDocument className='wh-20' />,
                children: [
                  ...subject.documents.map((document: any) => ({
                    title: document.title,
                    key: `document-${document.id}`,
                    icon: <MenuDocument className='wh-20' />,
                  })),
                ]
              },
              {
                title: "Sorular",
                key: `questions-${subject.id}`,
                icon: <MenuQuestion className='wh-20 ' />,
                children: [
                  ...subject.questions.map((question: any, key: any) => ({
                    title: key + 1,
                    key: `question-${question.id}`,
                  })),
                ]
              },
            ],
          })),
        })),
    })),
  }));
};

interface MenuOption {
  key: string;
  label: string;
  children: any;
  onClick?: () => void;
}

interface SubMenuOption extends MenuOption {
  children: MenuOption[];
}

const Subjects = () => {
  const { id }: any = useParams();


  const [currentNode, setCurrentNode] = useState<any>({ id: null, data: null, action: null, type: null });

  const [contextMenu, setContextMenu] = useState<ContextMenuState>({ visible: false, x: 0, y: 0 });
  const menuRef = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const [grades, setGrades] = useState([]);
  const [cirriculum, setCirriculum] = useState<any>(null);
  const [showUnitCreateModal, setShowUnitCreateModal] = useState<boolean>(false);
  const [showLessonCreateModal, setShowLessonCreateModal] = useState<boolean>(false);
  const [showGradeCreateModal, setShowGradeCreateModal] = useState<boolean>(false);
  const [showUnitEditModal, setShowUnitEditModal] = useState<boolean>(false);
  const [currentUnitId, setCurrentUnitId] = useState<number | null>(null);
  const [showLessonEditModal, setShowLessonEditModal] = useState<boolean>(false);
  const [currentLessonId, setCurrentLessonId] = useState<number | null>(null);
  const [showGradeEditModal, setShowGradeEditModal] = useState<boolean>(false);
  const [currentGradeId, setCurrentGradeId] = useState<any>(null);
  const [showSubjectCreateModal, setShowSubjectCreateModal] = useState<boolean>(false);
  const [showSubjectEditModal, setShowSubjectEditModal] = useState<boolean>(false);
  const [currentSubjectId, setCurrentSubjectId] = useState<number | null>(null);

  const [currentVideoId, setCurrentVideoId] = useState<number | null>(null);
  const [showVideoView, setShowVideoView] = useState<boolean>(false);

  const [showQuizCreate, setShowQuizCreate] = useState<boolean>(false);
  const [currentQuizId, setCurrentQuizId] = useState<number | null>(null);

  const [showExplanationCreate, setShowExplanationCreate] = useState<boolean>(false);
  const [currentExplanationId, setCurrentExplanationId] = useState<number | null>(null);

  const [showDocumentCreate, setShowDocumentCreate] = useState<boolean>(false);
  const [currentDocumentId, setCurrentDocumentId] = useState<number | null>(null);

  const [showQuestionCreate, setShowQuestionCreate] = useState<boolean>(false);
  const [currentQuestionId, setCurrentQuestionId] = useState<number | null>(null);
  const [menuOptions, setMenuOptions] = useState([]);

  useEffect(() => {
    if (currentNode?.action === 'delete') {
      removeItem(currentNode).then(() => {
        getCurriculum();
      })
        .catch((error) => {
          console.error('Silme işlemi başarısız:', error);
        });
    }
  }, [currentNode])


  const determineMenuOptions = (key: string) => {

    const keyParts = key.split('-');
    let level = keyParts[0];
    let id = parseInt(keyParts[1]);
    let gradeId: number | null = null;

    if (key.indexOf('grade') > -1 && key.indexOf('lesson') > -1) {
      level = 'lesson';
      id = parseInt(keyParts[keyParts.length - 1]);
      gradeId = parseInt(keyParts[keyParts.length - 3]);
    }

    if (key.indexOf('grade') > -1 && key.indexOf('lesson') > -1 && key.indexOf('unit') > -1) {
      level = 'unit';
      id = parseInt(keyParts[keyParts.length - 1]);
    }

    switch (level) {
      case 'grade':
        return [
          {
            key: 'add-grade', label: <>
              <Row gutter={8} >
                <Col className='pt-4'><MenuGrade className='wh-20' /></Col>
                <Col>Sınıf Ekle</Col>
              </Row>
            </>, onClick: handleAddGradeClick
          },
          {
            key: 'add-lesson', label: <>
              <Row gutter={8} >
                <Col className='pt-4'><MenuLesson className='wh-20' /></Col>
                <Col>Ders Ekle</Col>
              </Row>
            </>, onClick: () => handleAddLessonClick(id)
          },
          {
            key: 'edit-grade', label: <>
              <Row gutter={8} >
                <Col className='pt-4'><EditIcon className='wh-20' /></Col>
                <Col>Sınıfı Düzenle</Col>
              </Row>
            </>, onClick: () => handleEditGradeClick(id)
          },
          {
            key: 'delete-grade',
            label: <Popconfirm
              title="Sınıf Sil"
              description="Sınıfı silmek istediğinize emin misiniz?"
              okText="Evet"
              cancelText="Hayır"
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => setCurrentNode({ id: id, data: null, action: 'delete', type: 'grade' })}
            >
              <Button danger onClick={e => e.stopPropagation()}>Sil</Button>
            </Popconfirm>
          },
        ];
      case 'lesson':
        return [
          {
            key: 'add-unit', label: <>
              <Row gutter={8} >
                <Col className='pt-4'><MenuUnit className='wh-20' /></Col>
                <Col>Unite Ekle</Col>
              </Row>
            </>, onClick: () => handleAddUnitClick(id, gradeId)
          },
          {
            key: 'edit-lesson', label: <>
              <Row gutter={8} >
                <Col className='pt-4'><EditIcon className='wh-20' /></Col>
                <Col>Dersi Düzenle</Col>
              </Row>
            </>, onClick: () => handleEditLessonClick(id)
          },
          {
            key: 'delete-lesson',
            label: <Popconfirm
              title="Ders Sil"
              description="Dersi silmek istediğinize emin misiniz?"
              okText="Evet"
              cancelText="Hayır"
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => setCurrentNode({ id: id, data: { lessonId: id, gradeId: gradeId }, action: 'delete', type: 'lesson' })}
            >
              <Button danger onClick={e => e.stopPropagation()}>Sil</Button>
            </Popconfirm>
          },
        ];
      case 'unit':
        return [
          {
            key: 'add-subject', label: <>
              <Row gutter={8} >
                <Col className='pt-4'><MenuSubject className='wh-20' /></Col>
                <Col>Konu Ekle</Col>
              </Row>
            </>, onClick: () => handleAddSubjectClick(id)
          },
          {
            key: 'edit-unit', label: <>
              <Row gutter={8} >
                <Col className='pt-4'><EditIcon className='wh-20' /></Col>
                <Col>Üniteyi Düzenle</Col>
              </Row>
            </>, onClick: () => handleEditUnitClick(id)
          },
          {
            key: 'delete-unit',
            label: <Popconfirm
              title="Ünite Sil"
              description="Üniteyi silmek istediğinize emin misiniz?"
              okText="Evet"
              cancelText="Hayır"
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => setCurrentNode({ id: id, data: null, action: 'delete', type: 'unit' })}
            >
              <Button danger onClick={e => e.stopPropagation()}>Sil</Button>
            </Popconfirm>
          },
        ];
      case 'subject':
        return [
          {
            key: 'edit-subject', label: <>
              <Row gutter={8} >
                <Col className='pt-4'><EditIcon className='wh-20' /></Col>
                <Col>Konuyu Düzenle</Col>
              </Row>
            </>, onClick: () => handleEditSubjectClick(id)
          },
          {
            key: 'delete-subject',
            label: <Popconfirm
              title="Konu Sil"
              description="Konuyu silmek istediğinize emin misiniz?"
              okText="Evet"
              cancelText="Hayır"
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => setCurrentNode({ id: id, data: null, action: 'delete', type: 'subject' })}
            >
              <Button danger onClick={e => e.stopPropagation()}>Sil</Button>
            </Popconfirm>
          },
          {
            key: 'add', // Key for the "Add" submenu
            label: 'Ekle',
            children: [ // Array of submenu options
              {
                key: 'add-video', label: <>
                  <Row gutter={8} >
                    <Col className='pt-4'><MenuVideo className='wh-20' /></Col>
                    <Col>Video Ekle</Col>
                  </Row>
                </>, onClick: () => {
                  setShowVideoView(true); // VideoView komponentini göster
                  setShowQuizCreate(false);
                  setShowExplanationCreate(false)
                  setShowQuestionCreate(false); // 
                  setShowDocumentCreate(false)
                  setCurrentSubjectId(id)
                  setCurrentVideoId(null); // VideoEdit komponentini gizle
                }
              },
              {
                key: 'add-question', label: <>
                  <Row gutter={8} >
                    <Col className='pt-4'><MenuQuestion className='wh-20' /></Col>
                    <Col>Soru Ekle</Col>
                  </Row>
                </>, onClick: () => {
                  setShowQuestionCreate(true); // 
                  setShowQuizCreate(false); // 
                  setShowVideoView(false);
                  setShowDocumentCreate(false)
                  setShowExplanationCreate(false);
                  setCurrentSubjectId(id);
                  setCurrentQuestionId(null); //Edit komponentini gizle
                }
              },
              {
                key: 'add-explanation', label: <>
                  <Row gutter={8} >
                    <Col className='pt-4'><MenuExplanation className='wh-20' /></Col>
                    <Col>Konu Anlatımı Ekle</Col>
                  </Row>
                </>, onClick: () => {
                  setShowExplanationCreate(true); //
                  setShowVideoView(false);
                  setShowQuizCreate(false);
                  setShowDocumentCreate(false)
                  setShowQuestionCreate(false); // 
                  setCurrentSubjectId(id);
                  setCurrentExplanationId(null); // Edit komponentini gizle
                }
              },
              {
                key: 'add-document', label: <>
                  <Row gutter={8} >
                    <Col className='pt-4'><MenuDocument className='wh-20' /></Col>
                    <Col>Doküman Ekle</Col>
                  </Row>
                </>, onClick: () => {
                  setShowExplanationCreate(false); //
                  setShowVideoView(false);
                  setShowQuizCreate(false);
                  setShowQuestionCreate(false); // 
                  setShowDocumentCreate(true)
                  setCurrentSubjectId(id);
                  setCurrentExplanationId(null); // Edit komponentini gizle
                }
              }
            ]
          },

          // {
          //   key: 'add-quiz', label: 'Quiz Ekle', onClick: () => {
          //     setShowQuizCreate(true); // 
          //     setShowVideoView(false);
          //     setShowDocumentCreate(false)
          //     setShowQuestionCreate(false); // 
          //     setShowExplanationCreate(false);
          //     setCurrentSubjectId(id);
          //     setCurrentQuizId(null); //Edit komponentini gizle
          //   }
          // },

        ];
      case 'video':
        return [
          {
            key: 'delete-video',
            label: <Popconfirm
              title="Video Sil"
              description="Videoyu silmek istediğinize emin misiniz?"
              okText="Evet"
              cancelText="Hayır"
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => setCurrentNode({ id: id, data: null, action: 'delete', type: 'video' })}
            >
              <Button danger onClick={e => e.stopPropagation()}>Sil</Button>
            </Popconfirm>
          },
          {
            key: 'edit-video', label: <>
              <Row gutter={8} >
                <Col className='pt-4'><EditIcon className='wh-20' /></Col>
                <Col>Videoyu Düzenle</Col>
              </Row>
            </>, onClick: () => {
              setCurrentVideoId(id); // Video seçildiğinde currentVideoId'yi güncelle
              setCurrentQuestionId(null);
              setCurrentDocumentId(null)
              setCurrentQuizId(null);
              setCurrentExplanationId(null);
            }
          },
        ];
      case 'quiz':
        return [
          {
            key: 'delete-quiz',
            label: <Popconfirm
              title="Sınav Sil"
              description="Sınavı silmek istediğinize emin misiniz?"
              okText="Evet"
              cancelText="Hayır"
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => setCurrentNode({ id: id, data: null, action: 'delete', type: 'quiz' })}
            >
              <Button danger onClick={e => e.stopPropagation()}>Sil</Button>
            </Popconfirm>
          },
          {
            key: 'edit-quiz', label: <>
              <Row gutter={8} >
                <Col className='pt-4'><EditIcon className='wh-20' /></Col>
                <Col>Quizi Düzenle</Col>
              </Row>
            </>, onClick: () => {
              setCurrentVideoId(null); // Video seçildiğinde currentVideoId'yi güncelle
              setCurrentQuestionId(null);
              setCurrentDocumentId(null)
              setCurrentQuizId(id);
              setCurrentExplanationId(null);
            }
          },
          // Ünite düzenleme gibi diğer seçenekler burada tanımlanabilir
        ];
      case 'question':
        return [
          {
            key: 'delete-question',
            label: <Popconfirm
              title="Soru Sil"
              description="Soruyu silmek istediğinize emin misiniz?"
              okText="Evet"
              cancelText="Hayır"
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => setCurrentNode({ id: id, data: null, action: 'delete', type: 'question' })}
            >
              <Button danger onClick={e => e.stopPropagation()}>Sil</Button>
            </Popconfirm>
          },
          {
            key: 'edit-question', label: <>
              <Row gutter={8} >
                <Col className='pt-4'><EditIcon className='wh-20' /></Col>
                <Col>Soruyu Düzenle</Col>
              </Row>
            </>, onClick: () => {
              setCurrentVideoId(null); // Video seçildiğinde currentVideoId'yi güncelle
              setCurrentDocumentId(null)
              setCurrentQuizId(null);
              setCurrentQuestionId(id);
              setCurrentExplanationId(null);
            }
          },
          // Ünite düzenleme gibi diğer seçenekler burada tanımlanabilir
        ];
      case 'explanation':
        return [
          {
            key: 'delete-explanation',
            label: <Popconfirm
              title="Konu Anlatımı Sil"
              description="Konu anlatımını silmek istediğinize emin misiniz?"
              okText="Evet"
              cancelText="Hayır"
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => setCurrentNode({ id: id, data: null, action: 'delete', type: 'explanation' })}
            >
              <Button danger onClick={e => e.stopPropagation()}>Sil</Button>
            </Popconfirm>
          },
          {
            key: 'edit-explanation', label: <>
              <Row gutter={8} >
                <Col className='pt-4'><EditIcon className='wh-20' /></Col>
                <Col>Konu Anlatımını Düzenle</Col>
              </Row>
            </>, onClick: () => {
              setCurrentVideoId(null); // Video seçildiğinde currentVideoId'yi güncelle
              setCurrentDocumentId(null)
              setCurrentQuestionId(null);
              setCurrentQuizId(null);
              setCurrentExplanationId(id);
            }
          },
          // Ünite düzenleme gibi diğer seçenekler burada tanımlanabilir
        ];
      case 'document':
        return [
          {
            key: 'delete-document',
            label: <Popconfirm
              title="Dokümanı Sil"
              description="Dokümanı anlatımını silmek istediğinize emin misiniz?"
              okText="Evet"
              cancelText="Hayır"
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={() => setCurrentNode({ id: id, data: null, action: 'delete', type: 'document' })}
            >
              <Button danger onClick={e => e.stopPropagation()}>Sil</Button>
            </Popconfirm>
          },
          {
            key: 'edit-document', label: <>
              <Row gutter={8} >
                <Col className='pt-4'><EditIcon className='wh-20' /></Col>
                <Col>Dokümanı Düzenle</Col>
              </Row>
            </>, onClick: () => {
              setCurrentVideoId(null); // Video seçildiğinde currentVideoId'yi güncelle
              setCurrentDocumentId(id)
              setCurrentQuestionId(null);
              setCurrentQuizId(null);
              setCurrentExplanationId(null);
            }
          },
          // Ünite düzenleme gibi diğer seçenekler burada tanımlanabilir
        ];
      default:
        return [];// Varsayılan olarak boş bir dizi döndür
    }
  };

  useEffect(() => {
    getCurriculum();
    setLoading(true);
  }, []);

  const onRightClick: TreeProps['onRightClick'] = ({ event, node }) => {
    event.preventDefault();

    const options: any = determineMenuOptions(node.key as string);

    setContextMenu({
      visible: true,
      x: event.clientX,
      y: event.clientY,
    });
    setMenuOptions(options);
  };

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (contextMenu.visible && (event.clientX < contextMenu.x || event.clientX > contextMenu.x + 320 || event.clientY < contextMenu.y - 100 || event.clientY > contextMenu.y + 300)) {
        hideContextMenu();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [contextMenu]);

  const onSelect = (selectedKeys: React.Key[], info: any) => {
    const key = info.node.key;
    const keyParts = key.split('-');
    const level = keyParts[0];
    const id = parseInt(keyParts[1]);

    setShowVideoView(false); // VideoView komponentini göster
    setShowQuizCreate(false)
    setShowExplanationCreate(false)
    setShowDocumentCreate(false)
    setShowQuestionCreate(false)

    if (level === 'video') {
      setCurrentVideoId(id); // Video seçildiğinde currentVideoId'yi güncelle
      setCurrentDocumentId(null)
      setCurrentQuestionId(null); // quiz
      setCurrentQuizId(null);
      setCurrentExplanationId(null); // explanation
    } else if (level === 'quiz') {
      setCurrentQuizId(id); // quiz
      setCurrentDocumentId(null)
      setCurrentQuestionId(null); // quiz
      setCurrentVideoId(null);
      setCurrentExplanationId(null); // explanation
    } else if (level === 'question') {
      setCurrentQuestionId(id); // quiz
      setCurrentQuizId(null); // quiz
      setCurrentDocumentId(null)
      setCurrentVideoId(null);
      setCurrentExplanationId(null); // explanation
    } else if (level === 'explanation') {
      setCurrentExplanationId(id); // explanation
      setCurrentVideoId(null);
      setCurrentQuestionId(null); // quiz
      setCurrentQuizId(null);
      setCurrentDocumentId(null)
    } else if (level === 'document') {
      setCurrentExplanationId(null); // document
      setCurrentVideoId(null);
      setCurrentQuestionId(null); // quiz
      setCurrentQuizId(null);
      setCurrentDocumentId(id)
    } else {
      setCurrentVideoId(null); // Video dışında bir şey seçildiğinde currentVideoId'yi null yap
      setCurrentQuizId(null); // Video dışında bir şey seçildiğinde currentVideoId'yi null yap
      setCurrentExplanationId(null); // explanation
      setCurrentQuestionId(null); // quiz
      setCurrentDocumentId(null);
    }
  };

  const getCurriculum = () => {
    setLoading(true);
    CurriculumService.get(id).then((response: any) => {
      setCirriculum(response.data)
      getGrades()
    }).catch((e: Error) => {
      setLoading(false);
      console.log(e)
    });
  }

  const getGrades = () => {
    CurriculumService.getGradesByCurriculumId(id)
      .then((response: any) => {
        let formattedData = formatDataForTree(response.data);
        setGrades(formattedData);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
        setLoading(false);
      });
  };

  const hideContextMenu = () => {
    setContextMenu({ visible: false, x: 0, y: 0 });
  };

  const handleAddUnitClick = (lessonId: number, gradeId: any) => {
    setCurrentLessonId(lessonId);
    setCurrentGradeId(gradeId);
    setShowUnitCreateModal(true);
    hideContextMenu();
  };

  const handleEditUnitClick = (unitId: number) => {
    setCurrentUnitId(unitId);
    setShowUnitEditModal(true);
  };

  const handleAddLessonClick = (gradeId: number) => {
    setCurrentGradeId(gradeId);
    setShowLessonCreateModal(true);
    hideContextMenu();
  };

  const handleEditLessonClick = (lessonId: number) => {
    setCurrentLessonId(lessonId);
    setShowLessonEditModal(true);
  };

  const handleAddGradeClick = () => {
    setShowGradeCreateModal(true);
    hideContextMenu();
  };

  const handleEditGradeClick = (gradeId: number) => {
    setCurrentGradeId(gradeId);
    setShowGradeEditModal(true);
  };

  const handleAddSubjectClick = (unitId: number) => {
    setCurrentUnitId(unitId)
    setShowSubjectCreateModal(true);
    hideContextMenu();
  };

  const handleEditSubjectClick = (subjectId: number) => {
    setCurrentSubjectId(subjectId);
    setShowSubjectEditModal(true);
  };

  const isDraggable = (node: any) => {
    return node.key.startsWith('subject-');
  };
  
  const isUnitNode = (key: string) => {
    return key.startsWith('grade-') && key.includes('-unit-');
  };

  const onDragEnter = (info: any) => {
    console.log(info);
  };

  const collectAllSubjects = (list: any[]): any[] => {
    const subjects: any[] = [];
    const loop = (data: any[], unitId?: number) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key.startsWith('subject-')) {
          subjects.push({ ...data[i], unitId: unitId, sortId: i });
        }
        if (data[i].children) {
          const newUnitId = data[i].key.startsWith('grade-') && data[i].key.includes('-unit-') ? data[i].key.split('-')[3] : unitId;
          loop(data[i].children, newUnitId);
        }
      }
    };
    loop(list);
    return subjects;
  };

  const onDrop = (info: any) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    // Ensure the target node is a unit node
    if (!isUnitNode(dropKey)) {
      return;
    }

    const loop = (data: any[], key: React.Key, callback: (node: any, i: number, data: any[]) => void) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };

    const data = [...grades];

    let dragObj: any;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        item.children.unshift(dragObj);
      });
    } else if (
      (info.node.children || []).length > 0 &&
      info.node.expanded &&
      dropPosition === 1
    ) {
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        item.children.unshift(dragObj);
      });
    } else {
      let ar: any[] = [];
      let i = 0;
      loop(data, dropKey, (item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }

    // Collect all subjects before updating the tree data
    const allSubjects = collectAllSubjects(data);
    console.log('All subjects:', allSubjects);

    setGrades(data);
  };


  return (
    <>
      <Row gutter={24} justify={'start'} className='m-12'>
        <Col className='pb-48' span={8}>
          <Card className='border-radius-md' loading={loading}>
            {contextMenu.visible && (
              <Menu
                ref={menuRef}
                style={{ position: 'fixed', zIndex: 10, left: `${contextMenu.x}px`, top: `${contextMenu.y}px` }}
                onClick={hideContextMenu}
              >
                {menuOptions.map((option: MenuOption | SubMenuOption) => (
                  option.children ? (
                    <Menu.SubMenu key={option.key} title={option.label}>
                      {option.children.map((childOption: any) => (
                        <Menu.Item key={childOption.key} onClick={childOption.onClick}>
                          {childOption.label}
                        </Menu.Item>
                      ))}
                    </Menu.SubMenu>
                  ) : (
                    <Menu.Item key={option.key} onClick={option.onClick}>
                      {option.label}
                    </Menu.Item>
                  )
                ))}
              </Menu>
            )}
            {(grades.length > 0) ? <Tree
              showLine={true}
              showIcon={true}
              onRightClick={onRightClick}
              onSelect={onSelect}
              defaultExpandAll
              treeData={grades}
              switcherIcon={<DownOutlined />}
              draggable={{
                icon: false,
                nodeDraggable: isDraggable,
              }}
              onDragEnter={onDragEnter}
              onDrop={onDrop}
            /> : <Button className='w-full height-40 btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text' onClick={() => { setShowGradeCreateModal(true) }}>Sınıf Ekle</Button>}

            <CreateUnitModal getTrigger={getGrades} setShowModal={setShowUnitCreateModal} showModal={showUnitCreateModal} gradeId={currentGradeId} lessonId={currentLessonId ?? 0} />
            <CreateLessonModal getTrigger={getGrades} setShowModal={setShowLessonCreateModal} showModal={showLessonCreateModal} gradeId={currentGradeId ?? 0} />
            <CreateGradeModal getTrigger={getGrades} setShowModal={setShowGradeCreateModal} showModal={showGradeCreateModal} />
            <CreateSubjectModal getTrigger={getGrades} setShowModal={setShowSubjectCreateModal} showModal={showSubjectCreateModal} unitId={currentUnitId ?? 0} />
            <EditGradeModal getTrigger={getGrades} setShowModal={setShowGradeEditModal} showModal={showGradeEditModal} gradeId={currentGradeId ?? 0} />
            <EditLessonModal getTrigger={getGrades} setShowModal={setShowLessonEditModal} showModal={showLessonEditModal} lessonId={currentLessonId ?? 0} />
            <EditUnitModal getTrigger={getGrades} setShowModal={setShowUnitEditModal} showModal={showUnitEditModal} unitId={currentUnitId ?? 0} />
            <EditSubjectModal getTrigger={getGrades} setShowModal={setShowSubjectEditModal} showModal={showSubjectEditModal} subjectId={currentSubjectId ?? 0} />
          </Card>
        </Col>
        <Col xl={16}>
          {showVideoView && <VideoView setCurrentVideoId={setCurrentVideoId} getTrigger={getGrades} subjectId={currentSubjectId} />}
          {showQuizCreate && <QuizCreate setCurrentQuizId={setCurrentQuizId} getTrigger={getGrades} subjectId={currentSubjectId} />}
          {showQuestionCreate && <QuestionCreate setCurrentQuestionId={setCurrentQuestionId} getTrigger={getGrades} subjectId={currentSubjectId} />}
          {showDocumentCreate && <DocumentCreate setCurrentDocumentId={setCurrentDocumentId} getTrigger={getGrades} subjectId={currentSubjectId} />}
          {showExplanationCreate && <ExplanationCreate setCurrentExplanationId={setCurrentExplanationId} getTrigger={getGrades} subjectId={currentSubjectId} />}
          {currentVideoId && !showVideoView && <VideoView subjectId={currentSubjectId} setCurrentVideoId={setCurrentVideoId} getTrigger={getGrades} id={currentVideoId} />}
          {currentQuizId && !showQuizCreate && <QuizEdit setCurrentQuizId={setCurrentQuizId} getTrigger={getGrades} quizId={currentQuizId} />}
          {currentQuestionId && !showQuestionCreate && <QuestionEdit setCurrentQuestionId={setCurrentQuestionId} getTrigger={getGrades} questionId={currentQuestionId} />}
          {currentDocumentId && !showDocumentCreate && <DocumentEdit setCurrentDocumentId={setCurrentDocumentId} getTrigger={getGrades} documentId={currentDocumentId} />}
          {currentExplanationId && !showExplanationCreate && <ExplanationEdit setCurrentExplanationId={setCurrentExplanationId} getTrigger={getGrades} explanationId={currentExplanationId} />}
        </Col>

      </Row >
    </>
  )
}

export default Subjects