import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './views/Dashboard';
import MainLayout from './MainLayout';
import AuthLayout from './AuthLayout';
import LoginPage from './views/LoginPage';
import './style/main.scss';
import Units from './views/Units';
import Lessons from './views/Lessons';
import Grades from './views/Grades';
import Announcements from './views/Announcements';
import Subjects from './views/Subjects';
import Profile from './views/Profile';
import Students from './views/Students';
import Applications from './views/Applications';
import LiveLessons from './views/LiveLessons';
import Packages from './views/Packages/Packages';
import Roles from './views/Roles';
import PackagesCreate from './views/Packages/PackagesCreate';
import PackagesEdit from './views/Packages/PackagesEdit';
import StudentDetail from './views/StudentDetail';
import Questions from './views/Questions';
import Assignments from './views/Assignments';
import Quiz from './views/Quiz/Quiz';
import QuizDetail from './views/Quiz/QuizDetail';
import QuizCreate from './views/Quiz/QuizCreate';
import ApplicationDetail from './views/ApplicationDetail';
import UserList from './components/Users/UserList';
import Curriculum from './views/Curriculum';
import CurriculumDetail from './views/CurriculumDetail';
import CourseList from './views/Course';
import CourseDetail from './views/CourseDetail';
import CourseCreate from './views/CourseCreate';
import AssessmentExamList from './views/AssessmentExamList';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route element={<MainLayout />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/units" element={<Units />} />
            <Route path="/lessons" element={<Lessons />} />
            <Route path="/grades" element={<Grades />} />
            <Route path="/users" element={<UserList />} />
            <Route path="/assessment-exam" element={<AssessmentExamList />} />
            <Route path="/announcements" element={<Announcements />} />
            <Route path="/curriculums/:id" element={<CurriculumDetail />} />
            <Route path="/curriculums" element={<Curriculum />} />
            <Route path="/subjects/:id" element={<Subjects />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/students" element={<Students />} />
            <Route path="/questions" element={<Questions />} />
            <Route path="/assignments" element={<Assignments />} />
            <Route path="/students/:id" element={<StudentDetail />} />
            <Route path="/applications" element={<Applications />} />
            <Route path="/applications/:id" element={<ApplicationDetail />} />
            <Route path="/live-lessons" element={<LiveLessons />} />
            <Route path="/packages" element={<Packages />} />
            <Route path="/course" element={<CourseList />} />
            <Route path="/course/:courseId" element={<CourseDetail />} />
            <Route path="/course/create" element={<CourseCreate />} />
            <Route path="/packages/create" element={<PackagesCreate />} />
            <Route path="/package/:id" element={<PackagesEdit />} />
            <Route path="/roles" element={<Roles />} />
            <Route path="/quiz" element={<Quiz />} />
            <Route path="/quiz/:id" element={<QuizDetail />} />
            <Route path="/quiz/create" element={<QuizCreate />} />
          </Route>
          <Route element={<AuthLayout />}>
            <Route path="/login" element={<LoginPage />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
