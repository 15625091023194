import { Card, Col, Row, Tooltip, Tree, Button, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CurriculumService from '../services/CurriculumService';
import { DownOutlined, LeftOutlined, RightOutlined, EditOutlined } from '@ant-design/icons';

import { ReactComponent as GradeIcon } from '../assets/Subject/grade-icon.svg';
import { ReactComponent as LessonIcon } from '../assets/Subject/lesson-icon.svg';
import { ReactComponent as UnitIcon } from '../assets/Subject/unit-icon.svg';
import { ReactComponent as SubjectIcon } from '../assets/Subject/subject-icon.svg';
import SubjectDetail from './SubjectDetail';
import LessonDetail from './LessonDetail';
import UnitDetail from './UnitDetail';
import GradeDetail from './GradeDetail';
import GradeList from './GradeList';

const CurriculumDetail = () => {
    const { id }: any = useParams();
    const [loading, setLoading] = useState(false);
    const [grades, setGrades] = useState([]);
    const [data, setData] = useState([]);
    const [curriculum, setCurriculum] = useState<any>({ name: '' });
    const [subject, setSubject] = useState<any>();
    const [currentNode, setCurrentNode] = useState<any>(null);
    const [leftColSpan, setLeftColSpan] = useState(8);
    const [rightColSpan, setRightColSpan] = useState(16);

    useEffect(() => {
        getCurriculum();
    }, []);

    const formatDataForTree = (data: any) => {
        return data
            .map((grade: any) => ({
                title: <Tooltip title="Sınıf"> {grade.name} </Tooltip>,
                key: `grade-${grade.id}`,
                icon: <GradeIcon />,
                data: { ...grade, breadcrumb: [grade.name] },
                type: 'grade',
                children: grade.lessons
                    .sort((a: any, b: any) => a.name.localeCompare(b.name)) // Sort lessons alphabetically
                    .map((lesson: any) => ({
                        title: <Tooltip title="Ders"> {lesson.name} </Tooltip>,
                        key: `grade-${grade.id}-lesson-${lesson.id}`,
                        icon: <LessonIcon />,
                        data: { ...lesson, breadcrumb: [grade.name, lesson.name], gradeId: grade.id },
                        gradeId: grade.id,
                        type: 'lesson',
                        children: lesson.units
                            .filter((unit: any) => unit.gradeId === grade.id)
                            .map((unit: any) => ({
                                title: <Tooltip title="Ünite"> {unit.name} </Tooltip>,
                                key: `grade-${grade.id}-lesson-${lesson.id}-unit-${unit.id}`,
                                icon: <UnitIcon />,
                                data: { ...unit, breadcrumb: [grade.name, lesson.name, unit.name] },
                                type: 'unit',
                                children: unit.subjects.map((subject: any) => ({
                                    title: (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <SubjectIcon className="wh-20" />
                                            <Tooltip title="Konu">
                                                <span style={{ marginLeft: 8 }}>{subject.title}</span>
                                            </Tooltip>
                                        </div>
                                    ),
                                    key: `subject-${subject.id}`,
                                    icon: false,
                                    data: { ...subject, lessonId: lesson.id, breadcrumb: [grade.name, lesson.name, unit.name, subject.title] },
                                    type: 'subject',
                                })),
                            })),
                    })),
            }));
    };


    const getCurriculum = () => {
        setLoading(true);
        CurriculumService.get(id)
            .then((response: any) => {
                setCurriculum(response.data);
                getGrades();
            })
            .catch((e: Error) => {
                setLoading(false);
                console.log(e);
            });
    };

    const getGrades = () => {
        CurriculumService.getGradesByCurriculumId(id)
            .then((response: any) => {
                let formattedData = formatDataForTree(response.data);
                setData(response.data);
                setGrades(formattedData);
                setLoading(false);
            })
            .catch((e: Error) => {
                console.log(e);
                setLoading(false);
            });
    };

    const onSelect = (selectedKeys: React.Key[], item: any) => {
        console.log('node', item);
        setCurrentNode(item);
        if (item.node.type === 'subject') {
            setSubject(item.node.data);
        }
    };

    const handleLeftButtonClick = () => {
        if (leftColSpan > 4) {
            setLeftColSpan(leftColSpan - 2);
            setRightColSpan(rightColSpan + 2);
        }
    };

    const handleRightButtonClick = () => {
        if (rightColSpan > 4) {
            setLeftColSpan(leftColSpan + 2);
            setRightColSpan(rightColSpan - 2);
        }
    };



    return (
        <Row gutter={[24, 24]}>
            <Col span={leftColSpan}>
                <Card
                    title={`Müfredat: ${curriculum?.name}`}
                    loading={loading}
                    extra={
                        <>
                            <Button icon={<EditOutlined />} onClick={()=>{setCurrentNode(null)}} style={{ marginRight: 8 }} />
                            <Button icon={<LeftOutlined />} onClick={handleLeftButtonClick} style={{ marginRight: 8 }} />
                            <Button icon={<RightOutlined />} onClick={handleRightButtonClick} />
                        </>
                    }>
                    <Tree
                        style={{ width: '100%' }}
                        showIcon
                        defaultExpandAll
                        onSelect={onSelect}
                        treeData={grades}
                        switcherIcon={<DownOutlined />}
                    />
                </Card>
            </Col>
            <Col span={rightColSpan}>
                <div style={{ position: "sticky", top: '20px' }}>
                    {currentNode?.node?.type === 'grade' && <GradeDetail grade={currentNode.node.data} getTrigger={() => { getGrades() }} />}
                    {currentNode?.node?.type === 'lesson' && <LessonDetail getTrigger={() => { getGrades() }} lesson={currentNode.node.data} />}
                    {currentNode?.node?.type === 'unit' && <UnitDetail getTrigger={() => { getGrades() }} unit={currentNode.node.data} />}
                    {currentNode?.node?.type === 'subject' && <SubjectDetail subject={subject} getTrigger={() => { getGrades() }} />}
                    {(currentNode=== null) && <GradeList grades={data} getTrigger={() => { getGrades() }} />}
                </div>
            </Col>
        </Row>
    );
};

export default CurriculumDetail;
