import { Button, Card, Col, Form, Input, Row, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import QuizService from '../../../services/QuizService';
import { openNotification } from '../../../utils';
import { useState } from 'react';
import SubjectService from '../../../services/SubjectService';

const { Item } = Form;

interface QuizViewProps {
  subjectId: any;
  getTrigger: any;
  setCurrentQuizId: any;
}

const QuizCreate: React.FC<QuizViewProps> = ({ subjectId, getTrigger, setCurrentQuizId }) => {
  const [form] = Form.useForm();
  const update = (values: any) => {

    let postObj = {
      "title": values.name,
      "description": values.description,
      "subjectId": subjectId, //Buraya Bak tekrar
    };

    QuizService.create(postObj)
      .then((response: any) => {
        openNotification('success', 'Başarılı', 'Quiz Başarıyla Oluşturuldu.')
        setCurrentQuizId(null)
        getTrigger()
      }).catch((e: Error) => {
        openNotification('error', 'Başarısız', 'Quiz oluşturulamadı.')
        console.log(e)
      });
  }

  return (
    <>
      <Card className='border-radius-md'>
        <Row justify={'space-between'} align={'middle'}>
          <Col><h3 className='fs-32'>Quiz Ekle</h3></Col>
          <Col><p className='fs-12'>Quiz Ayarları</p></Col>
        </Row>
        <Row justify={'center'}>
          <Col xl={24} xs={24}>
            <Form form={form} onFinish={update} layout="vertical">
              <Item
                name="name"
                label="Başlık"
                rules={[
                  {
                    required: true,
                    message: 'Quiz adı zorunlu',
                  }]}
              >
                <Input />
              </Item>
              <Item
                name="description"
                label="Açıklama"
              >
                <TextArea />
              </Item>
              <Item>
                <Row justify={'end'}>
                  <Button className='btn btn-hover-dark bg-saha-purple font-color-white fw-700 btn-text' htmlType="submit">
                    Kaydet
                  </Button>
                </Row>
              </Item>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default QuizCreate