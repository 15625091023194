import React, { useEffect, useState } from 'react';
import { arrayMove } from '@dnd-kit/sortable';
import type { DragEndEvent } from '@dnd-kit/core';
import SubjectService from '../../services/SubjectService';
import { Button, Popconfirm, type TableColumnsType } from 'antd';
import SortableTable, { DragHandle } from '../../components/Common/SortableTable';
import DocumentService from '../../services/DocumentService';
import { QuestionCircleOutlined } from '@ant-design/icons'
import { removeItem } from '../../services/RemoveService';
import { formatDate } from '../../utils';
import DocumentUpdate from './Update/DocumentUpdate';
import ShareDocumentModal from './Share/ShareDocumentModal';

interface DataType {
  id: number;
  title: string;
  sortOrder: number;
}

interface DocumentListProps {
  subjectId: number;
  refresh: number;
}

const DocumentList: React.FC<DocumentListProps> = ({ subjectId, refresh }) => {
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentNode, setCurrentNode] = useState<any>({ id: null, data: null, action: null, type: null });

  const columns: TableColumnsType<DataType> = [
    { key: 'sort', align: 'center', width: 80, render: () => <DragHandle /> },
    { title: '#', dataIndex: 'id', width: 80 },
    { title: 'Doküman', dataIndex: 'title' },
    {
      title: 'Kayıt Tarihi',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => (
        <>
          {formatDate(createdAt)}
        </>
      ),
    },
    {
      title: 'İşlem',
      dataIndex: 'status',
      key: 'status',
      render: (_: any, record: any) => (
        <>
          <ShareDocumentModal document={record} />
          <DocumentUpdate getTrigger={getData} setCurrentDocumentId={() => { }} documentId={record.id}></DocumentUpdate>
          <Popconfirm
            title="Doküman Sil"
            description="Doküman silmek istediğinize emin misiniz?"
            okText="Evet"
            cancelText="Hayır"
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            onConfirm={() => setCurrentNode({ id: record.id, data: null, action: 'delete', type: 'document' })}
          >
            <Button danger onClick={e => e.stopPropagation()}>Sil</Button>
          </Popconfirm>
        </>
      )
    },
  ];

  useEffect(() => {
    if (currentNode?.action === 'delete') {
      removeItem(currentNode).then(() => {
        getData();
      })
        .catch((error) => {
          console.error('Silme işlemi başarısız:', error);
        });
    }
  }, [currentNode])

  useEffect(() => {
    getData();
  }, [subjectId, refresh]);

  const getData = () => {
    setLoading(true);
    SubjectService.getDocumentsBySubjectId(subjectId)
      .then((response: any) => {
        setDataSource(response.data);
        setLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
        setDataSource([]);
        setLoading(false);
      });
  };

  const updateSortOrders = (data: any) => {
    setLoading(true);
    DocumentService.updateSortOrders(data)
      .then((response: any) => {
        getData();
      })
      .catch((e: Error) => {
        console.log(e);
        setLoading(false);
      });
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((prevState) => {
        const activeIndex = prevState.findIndex((record) => record.id === active?.id);
        const overIndex = prevState.findIndex((record) => record.id === over?.id);
        const newState = arrayMove(prevState, activeIndex, overIndex);

        const updatedSortOrder = newState.map((item, index) => ({
          id: item.id,
          sortOrder: index + 1
        }));

        updateSortOrders(updatedSortOrder);

        return newState;
      });
    }
  };

  return (
    <SortableTable<DataType>
      loading={loading}
      columns={columns}
      dataSource={dataSource}
      onDragEnd={onDragEnd}
      defaultSortField="sortOrder"
    />
  );
};

export default DocumentList;
