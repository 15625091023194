import { http } from "../http-common";
import { openNotification } from "../utils";
let permissions = localStorage.getItem('permissions')

function isAuthorized(requiredPermission: any) {
    return permissions?.includes(requiredPermission);
}

const getAll = () => {
    if (isAuthorized('studentquestion.list')) {
        return http.get<any>(`/student-questions`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const create = (data: any) => {
    if (isAuthorized('studentquestion.create')) {
        return http.post<any>(`/student-questions`, data);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const get = (id: any) => {
    if (isAuthorized('studentquestion.show')) {
        return http.get<any>(`/student-questions/${id}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const getNotAnswered = () => {
    if (isAuthorized('studentquestion.list')) {
        return http.get<any>(`/student-questions/null-answers`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const getAnswered = () => {
    if (isAuthorized('studentquestion.list')) {
        return http.get<any>(`/student-questions/non-null-answers`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const update = (id: number, data: any, file: any) => {
    const formData = new FormData();
    formData.append("responseImage", file);
    formData.append("question", data.question);
    formData.append("answer", data.answer);
    formData.append("lessonId", data.lessonId);
    formData.append("userId", data.userId);
    formData.append("studentId", data.studentId);

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    };
    // return http.post<any>(`/student-questions`, formData, config);

    if (isAuthorized('studentquestion.update')) {
        return http.patch<any>(`/student-questions/${id}`, formData, config);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const getFile = (fileName: any) => {
    if (isAuthorized('studentquestion.download')) {
        return http.get<any>(`/student-questions/download?file=${fileName}`, { responseType: 'arraybuffer' });

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const StudentQuestionService = {
    create,
    getAll,
    update,
    getFile,
    get,
    getNotAnswered,
    getAnswered
}

export default StudentQuestionService;