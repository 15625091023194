import {http} from "../http-common";
import { IUnitRequest, IUnitResponse } from "../types/Unit";
import { isAuthorized, openNotification } from "../utils";

const getAll = () => {
    if (isAuthorized('unit.list')) {
        return http.get<IUnitResponse>(`/unit`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const create = (data: IUnitRequest) => {
    if (isAuthorized('unit.create')) {
        return http.post<IUnitRequest>(`/unit`, data);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const get = (id: number) => {
    if (isAuthorized('unit.show')) {
        return http.get<IUnitResponse>(`/unit/${id}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const update = (id: number, data: IUnitRequest) => {
    if (isAuthorized('unit.update')) {
        return http.patch<IUnitRequest>(`/unit/${id}`, data);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const remove = (id: number) => {
    if (isAuthorized('unit.delete')) {
        return http.delete<IUnitRequest>(`/unit/${id}`);

    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const updateSortOrders = (data: any) => {
    if (isAuthorized('unit.update')) {
        return http.put<any>(`/unit/update-sort-orders`, data);
    } else {
        openNotification('error', 'Başarısız', 'Yetkiniz yok.');
        return Promise.resolve(null);
    }
};

const UnitService = {
    create,
    getAll,
    get,
    update,
    remove,
    updateSortOrders
}

export default UnitService;